<template>
  <div class="c-avatar">
    <el-avatar
      fit="fill"
      :size="size"
      :src="url"
    />
    <div v-if="isVip" class="level" :style="_vipSize" />
  </div>
</template>

<script>
export default {
  name: 'CAvatar',
  props: {
    url: String,
    isVip: Boolean,
    size: {
      type: Number,
      default: 40,
    },
    vipSize: {
      type: Number,
      default: 16,
    },
  },
  computed: {
    _vipSize() {
      return {
        width: this.vipSize + 'px',
        height: this.vipSize + 'px',
      }
    },
  },
}
</script>

<style scoped>
.c-avatar {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.level {
  position: absolute;
  bottom: 0;
  right:-6px;
  width:16px;
  height:16px;
  background:url(../../assets/img/user_level.png);
  background-size: cover;
}
</style>
