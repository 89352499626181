<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <head>
      <meta charset="utf-8">
      <title>{{ info.title }}</title>
      <meta name="keywords" :content="info.keyword">
      <meta name="description" :content="info.summary">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
    </head>

    <header
      id="header"
      :class="isVisible?'header-navigation slideDown':'header-navigation slideUp'"
    >
      <nav>
        <div v-show="!isWorktableMenu" class="logo">
          <el-image v-if="info.logoPhoto" :src="info.logoPhoto" class="logoPhoto" />
          <router-link to="/">
            <a v-if="info.name" href="javascript:void(0);">{{ info.name }}</a>
          </router-link>
        </div>

        <h2
          v-if="!isWorktableMenu"
          id="mnavh"
          :class="showHead?'open':''"
          @click="openHead"
        >
          <span class="navicon">
            <img v-if="showHead" src="../assets/img/close.png" width="20px">
            <img v-else src="../assets/img/list.png" width="20px">
          </span>
        </h2>
        <ul v-if="isWorktableMenu" id="to-home">
          <el-image v-if="info.logoPhoto" :src="info.logoPhoto" class="logoPhoto work-table-logo" />
          <span class="login-work-table work-table-logo">工作台</span>
          <router-link to="/">
            <span class="to-home-icon"><img src="../assets/navIcon/home.png" width="16px"></span>
            <li class="to-home-text">
              七里香首页
            </li>
          </router-link>
        </ul>
        <ul v-else id="starlist" :style="showHead?'display: block':''">
          <li v-for="webNavbar in webNavbarList" :key="webNavbar.uid" @click="clickWebNavbar(webNavbar)">
            <!--判断是否有下拉菜单-->
            <span v-if="webNavbar.url !=='/workTable'&& webNavbar.childWebNavbar && webNavbar.childWebNavbar.length > 0">
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  {{ webNavbar.name }}<i class="el-icon-arrow-down el-icon--right" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="childWebNavbar in webNavbar.childWebNavbar" :key="childWebNavbar.uid">
                    <router-link v-if="childWebNavbar.isJumpExternalUrl == 0" :to="childWebNavbar.url">
                      <a href="javascript:void(0);" @click="openHead">{{ childWebNavbar.name }}</a>
                    </router-link>
                    <a v-if="childWebNavbar.isJumpExternalUrl == 1" :href="childWebNavbar.url">{{ childWebNavbar.name }}</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>

            <!--没有有下拉菜单-->
            <span v-else>
              <router-link v-if="webNavbar.url !=='/workTable'&&webNavbar.isJumpExternalUrl == 0" :to="webNavbar.url">
                <a href="javascript:void(0);" :class="[(saveTitle == webNavbar.url || saveTitle == webNavbar.name) ? 'title' : '']" @click="openHead">{{ webNavbar.name }}</a>
              </router-link>
              <a
                v-if="webNavbar.isJumpExternalUrl == 1"
                :href="webNavbar.url"
                :class="[saveTitle == webNavbar.url || saveTitle == webNavbar.name ? 'title' : '']"
              >{{ webNavbar.name }}</a>
            </span>
          </li>
        </ul>
        <div class="header-right">
          <div
            v-show="!isWorktableMenu"
            class="searchbox"
            :class="showCreateBlog||showCreateQuestion?'':'searchboxDefault'"
            @click.stop="()=>{}"
          >
            <div id="search_bar" :class="(showSearch || keyword.length > 0)?'search_bar search_open':'search_bar'">
              <input
                ref="searchInput"
                v-model="keyword"
                class="input"
                placeholder="想搜点什么呢.."
                type="text"
                name="keyboard"
                autocomplete="off"
                @keyup.enter="search"
                @click="showHotSearch"
              >
              <p class="search_ico" @click="clickSearchIco">
                <i class="el-icon-search" style="font-size:18px" />
                <span />
              </p>
            </div>
            <HotSearch v-if="dialogHotSearchVisible" @close="closeHotSearch" @chooseitem="chooseitem" />
          </div>


          <el-dropdown v-show="!isWorktableMenu" class="create" @command="handleCommand">
            <span class="el-dropdown-link">
              <el-button
                v-if="showCreateBlog||showCreateQuestion"
                type="primary"
                size="small"
              >
                创作
              </el-button>
            </span>


            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="showCreateBlog" command="createBlog">
                创作文章
              </el-dropdown-item>
              <el-dropdown-item v-if="showCreateQuestion" command="createQuestion">
                发起问答
              </el-dropdown-item>
              <el-dropdown-item command="userMoment">
                发表动态
              </el-dropdown-item>
              <el-dropdown-item command="live">
                创建直播
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-show="webNavbarList.some(x=>x.url=='/workTable')&&!isWorktableMenu&&isMobile" class="workTable-btn">
            <router-link to="/workTable">
              <el-button

                type="primary"
                size="small"
                plain
              >
                工作台
              </el-button>
            </router-link>
          </div>


          <el-dropdown v-if="isLogin" :class="isLogin?'userInfoAvatar':'userInfoAvatar nologin'" @command="handleCommand">
            <span class="el-dropdown-link pointer">
              <i v-if="!isLogin" class="el-icon-user-solid" />
              <span v-if="!isLogin" class="noLoginText">登录</span>

              <Avatar
                v-if="isLogin"
                style="height:36px"
                :size="35"
                :is-vip="userInfo.userTag > 0"
                :url="userInfo.photoUrl||defaultAvatar"
              />
            </span>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-show="!isLogin" command="login">
                登录注册
              </el-dropdown-item>
              <el-dropdown-item v-show="isLogin" command="goUserCenter">
                我的主页
              </el-dropdown-item>
              <el-dropdown-item v-show="isLogin" command="goUserInfo">
                个人中心
              </el-dropdown-item>
              <el-dropdown-item v-show="isLogin&&!isMiniprogram" command="logout">
                退出登录
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-else class="userInfoAvatar nologin" @click.stop="userLogin">
            <i class="el-icon-user-solid" />
            <span class="pointer "><span class="noLoginText">登录/注册</span></span>
          </div>
        </div>
      </nav>
    </header>
    <LoginBox
      v-if="showLogin"
      :is-bind-back="isBindBack"
      :form-data="loginData"
      @closeLoginBox="closeLoginBox"
    />
    <LoginInfo
      v-if="showLoginInfo"
      :openid="openid"
      :identity="identity"
      :wechat-bind-mobile="wechatBindMobile"
      :is-miniprogram="isFromMiniprogram"
      :account-id="accountId"
      @closeLoginBox="closeLogin"
      @getToken="getToken"
    />
    <ChangeMobileBind
      ref="changeMobileBind"
      :mobile="wechatBindMobile"
      @close="closeChangeMobileBind"
    />

    <el-drawer
      :show-close="true"
      :visible.sync="drawer"
      :size="drawerSize"
      :with-header="false"
    >
      <el-tabs
        v-model="activeName"
        type="border-card"
        tab-position="left"
        class="personCenter"
        style="margin-top: 30px; height: 94%; overflow-y: scroll"
        @tab-click="handleClick"
      >
        <el-tab-pane label="基本信息" name="0">
          <span slot="label"><i class="el-icon-user-solid" /> 基本信息</span>
          <el-form
            ref="userInfo"
            label-position="left"
            :model="userInfo"
            label-width="100px"
            :rules="rules"
          >
            <el-form-item label="头像" :label-width="labelWidth">
              <div v-if="userInfo.photoUrl" class="imgBody">
                <i
                  v-show="icon"
                  class="el-icon-error inputClass"
                  @click="deletePhoto('user')"
                  @mouseover="icon = true"
                />
                <img :src="userInfo.photoUrl" @mouseover="icon = true" @mouseout="icon = false">
              </div>

              <div v-else class="uploadImgBody" @click="checkPhoto">
                <i class="el-icon-plus avatar-uploader-icon" />
              </div>
            </el-form-item>

            <el-form-item label="" :label-width="labelWidth" prop="accountId">
              <span style="word-wrap: break-word;word-break: normal;">用户ID：{{ userInfo.accountId }}</span>
            </el-form-item>
            <el-form-item label="昵称" :label-width="labelWidth" prop="nickName">
              <el-input v-model="userInfo.nickName" style="width: 100%" />
            </el-form-item>

            <el-form-item label="性别" :label-width="labelWidth">
              <el-radio
                v-for="gender in genderDictList"
                :key="gender.uid"
                v-model="userInfo.gender"
                :label="gender.dictValue"
                border
                size="medium"
              >
                {{ gender.dictLabel }}
              </el-radio>
            </el-form-item>

            <el-form-item label="身份" :label-width="labelWidth" prop="identity">
              <el-radio-group v-model="userInfo.identity" size="medium" class="rolegroup">
                <el-radio :label="1" border>
                  医生
                </el-radio>
                <el-radio :label="2" border>
                  推广人员
                </el-radio>
                <el-radio :label="3" border>
                  普通用户
                </el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="简介" :label-width="labelWidth" prop="summary">
              <el-input
                v-model="userInfo.summary"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 5}"
                placeholder="请输入内容"
                style="width: 100%"
              />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm('editUser')">
                保 存
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="账号密码" name="12">
          <span slot="label"><i class="el-icon-phone" /> 账号密码</span>
          <el-form
            ref="mobileInfo"
            label-position="left"
            :model="mobileInfo"
            label-width="90px"
            :rules="mobileRules"
          >
            <el-form-item label="头像">
              <div v-if="userInfo.photoUrl" class="imgBody">
                <img :src="userInfo.photoUrl" @mouseover="icon = true" @mouseout="icon = false">
              </div>
            </el-form-item>


            <el-form-item label="绑定手机号" prop="oldmobile">
              <span>{{ userInfo.mobile||'暂未绑定' }}</span><span v-if="userInfo.mobile" class="change-mobile" @click="changeMobile">更换手机号</span>
            </el-form-item>
            <el-form-item label="账号密码" prop="oldpassword">
              <span>{{ userInfo.mobile?'已设置':'暂未设置' }}</span><span v-if="userInfo.mobile" class="change-mobile" @click="changePassword">修改密码</span>
            </el-form-item>
            <el-form-item v-if="showMobile" label="手机号码" prop="mobile">
              <el-input v-model="mobileInfo.mobile" style="width: 100%" />
            </el-form-item>
            <el-form-item v-if="showValidCode" prop="validCode" label="验证码">
              <div>
                <el-input
                  v-model="mobileInfo.validCode"
                  placeholder="请输入验证码"
                  class="validCode"
                />

                <el-button type="primary" :disabled="(showMobile&&!mobileInfo.mobile)||getVerifyCodeBtnDisabled" @click.prevent="getVerifyCode">
                  {{ getVerifyCodeBtnText }}
                </el-button>
              </div>
            </el-form-item>

            <el-form-item
              v-if="showPassword"
              prop="password"
              label="密码"
              class="relative"
            >
              <el-input
                v-model="mobileInfo.password"
                placeholder="请输入"
                auto-complete="new-password"
                :type="personPassEye? 'password':'text'"
                @keyup.native="()=> {mobileInfo.password= mobileInfo.password.toString().replace(/(^\s*)|(\s*$)/g, '')}"
              >
                <el-button slot="append" class="absoluteIcon" @click.prevent="personPassEye=!personPassEye">
                  <svg-icon class="icon" :icon-name="personPassEye?'eye-close':'eye'" />
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item v-if="showPassword" prop="confirmPassword" label="确认密码">
              <el-input
                v-model="mobileInfo.confirmPassword"
                :type="personPass2Eye?'password':'text'"
                auto-complete="new-password"
                placeholder="请输入"
                @keyup.native="()=> {mobileInfo.confirmPassword= mobileInfo.confirmPassword.toString().replace(/(^\s*)|(\s*$)/g, '')}"
              >
                <el-button slot="append" class="absoluteIcon" @click.prevent="personPass2Eye=!personPass2Eye">
                  <svg-icon class="icon" :icon-name="personPass2Eye?'eye-close':'eye'" />
                </el-button>
              </el-input>
            </el-form-item>


            <el-form-item>
              <el-button v-if="showCancleBtn" @click="cancleMobile">
                取消
              </el-button>
              <el-button v-if="showSaveBtn" type="primary" @click="submitForm('mobile')">
                保 存
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="账号绑定" name="11">
          <span slot="label"><i class="el-icon-s-flag" /> 账号绑定</span>
          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto;">
            <el-table
              :data="accountList"
              style="width: 100%"
            >
              <el-table-column label="序号" width="50" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <el-table-column label="渠道" width="100" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.sources||'微信' }}</span>
                </template>
              </el-table-column>

              <el-table-column label="用户" width="150" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.nickName }}</span>
                </template>
              </el-table-column>

              <el-table-column label="绑定时间" width="160" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.bindTime }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>

        <el-tab-pane label="我的评论" name="1">
          <span slot="label"><i class="el-icon-message-solid" /> 我的评论</span>
          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto;">
            <el-timeline>
              <el-timeline-item
                v-for="comment in commentList"
                :key="comment.uid"
                :timestamp="timeAgo(comment.createTime)"
                placement="top"
              >
                <el-card>
                  <div class="commentList">
                    <span class="left p1">
                      <img v-if="comment.user" :src="comment.user.photoUrl ? comment.user.photoUrl:defaultAvatar">
                      <img v-else :src="defaultAvatar">
                    </span>

                    <span class="right p1">
                      <div class="rightTop">
                        <span class="userName">{{ comment.user&&comment.user.nickName }}</span>

                      </div>

                      <div v-highlight class="rightCenter ck-content" v-html="$xss(comment.content, options)" />
                    </span>
                  </div>
                </el-card>
              </el-timeline-item>

              <span v-if="isEnd && commentList.length == 0">
                <el-empty description="空空如也" image="" />
              </span>
            </el-timeline>

            <div class="isEnd">
              <div v-if="!isEnd&&!loading" class="loadContent" @click="loadMore('comment')">
                查看更多
              </div>
              <div v-if="!isEnd&&loading" class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-facebook">
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <span v-if="isEnd && commentList.length > 0 ">我也是有底线的~</span>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="我的回复" name="2">
          <span slot="label">
            <i class="el-icon-s-promotion" /> 我的回复
          </span>
          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto">
            <el-timeline>
              <el-timeline-item
                v-for="reply in replyList"
                :key="reply.uid"
                :timestamp="timeAgo(reply.createTime)"
                placement="top"
              >
                <el-card>
                  <div class="commentList">
                    <span class="left p1">
                      <img v-if="reply.user" :src="reply.user.photoUrl ? reply.user.photoUrl:defaultAvatar" @onerror="()=>{onerror=null;src=defaultAvatar}">
                      <img v-else :src="defaultAvatar">
                    </span>

                    <span class="right p1">

                      <div class="rightTop">

                        <span class="userName">{{ reply.user.nickName }}</span>
                      </div>

                      <div v-highlight class="rightCenter ck-content" v-html="$xss(reply.content, options)" />
                    </span>
                  </div>
                </el-card>
              </el-timeline-item>

              <span v-if="isEnd && replyList.length == 0">
                <el-empty description="空空如也" image="" />
              </span>
            </el-timeline>

            <div class="isEnd">
              <div v-if="!isEnd&&!loading" class="loadContent" @click="loadMore('reply')">
                查看更多
              </div>
              <div v-if="!isEnd&&loading" class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-facebook">
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <span v-if="isEnd && replyList.length > 0 ">我也是有底线的~</span>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane v-if="showCreateBlog" label="我的文章" name="3">
          <span slot="label"><i class="el-icon-s-order" /> 我的文章</span>

          <div class="filter-container" style="margin: 10px 0 10px 0;">
            <el-form :inline="true" style="margin-bottom: 8px;">
              <el-input
                v-model="queryParams.keyword"
                size="mini"
                clearable
                class="filter-item"
                style="width: 120px;"
                placeholder="请输入博客名"
                @input="getBlogList(true)"
              />

              <el-select
                v-model="queryParams.publishKeyword"
                size="mini"
                clearable
                placeholder="是否发布"
                style="width:100px"
                @change="getBlogList(true)"
              >
                <el-option
                  v-for="item in blogPublishDictList"
                  :key="item.uid"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                />
              </el-select>

              <el-select
                v-model="queryParams.originalKeyword"
                size="mini"
                clearable
                placeholder="是否原创"
                style="width:110px"
                @change="getBlogList(true)"
              >
                <el-option
                  v-for="item in blogOriginalDictList"
                  :key="item.uid"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                />
              </el-select>

              <el-select
                v-model="queryParams.auditStatusKeyword"
                size="mini"
                clearable
                placeholder="审核状态"
                style="width:110px"
                @change="getBlogList(true)"
              >
                <el-option
                  v-for="item in auditStatusDictList"
                  :key="item.uid"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                />
              </el-select>
            </el-form>
          </div>

          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto;">
            <el-timeline>
              <div
                v-for="item in userBlogList"
                :key="item.uid"
                class="myArticle"
              >
                <el-timeline-item :timestamp="item.createTime" placement="top">
                  <el-card>
                    <el-row :gutter="24">
                      <el-col :span="(item.photoList && item.photoList.length > 0)?12:0">
                        <span class="blogpic" @click="goToInfo(item)">
                          <a href="javascript:void(0);" title>
                            <img
                              v-if="item.photoList && item.photoList.length > 0 "
                              :key="item.photoList[0]"
                              v-lazy="item.photoList[0]"
                              alt
                            >
                          </a>
                        </span>
                      </el-col>

                      <el-col :span="(item.photoList && item.photoList.length > 0)?12:24">
                        <div style="height: 70px;">
                          <p class="blogtext" style="font-weight: bold; cursor: pointer;" @click="goToInfo(item)">
                            {{ item.title }}
                          </p>
                        </div>
                      </el-col>
                    </el-row>

                    <div class="bloginfo">
                      <ul>
                        <li class="author">
                          <span class="iconfont">&#xe60f;</span>
                          <span>{{ item.author }}</span>
                        </li>
                        <li v-if="item.blogSort">
                          <span class="iconfont">&#xe603;</span>
                          <span>{{ item.blogSort.sortName }}</span>
                        </li>
                        <li class="view">
                          <span class="iconfont">&#xe8c7;</span>
                          <span>{{ item.clickCount }}</span>
                        </li>
                        <li class="like">
                          <span class="iconfont icon-shoucang1" />
                          {{ item.collectCount }}
                        </li>
                      </ul>
                    </div>

                    <div style="margin-top: 5px; float: left">
                      <el-tag v-if="item.isPublish==1" type="success" style="font-size: 12px">
                        已上架
                      </el-tag>
                      <el-tag v-if="item.isPublish==0" type="info" style="font-size: 12px">
                        未发布
                      </el-tag>

                      <el-tag v-if="item.auditStatus==0" type="info" style="font-size: 12px">
                        待审批
                      </el-tag>
                      <el-tag
                        v-if="item.auditStatus==1"
                        content="预览"
                        type="danger"
                        style="font-size: 12px"
                      >
                        <el-tooltip :disabled="item.length <= 10" class="item" placement="top">
                          <div slot="content">
                            {{ item.rejectReason }}
                          </div>
                          <div>审核未通过</div>
                        </el-tooltip>
                      </el-tag>
                      <el-tag v-if="item.auditStatus==2" type="success" style="font-size: 12px">
                        审核已通过
                      </el-tag>
                    </div>

                    <div class="operation">
                      <el-row :gutter="24">
                        <el-tooltip
                          v-if="item.isPublish == '0' && item.auditStatus == '2'"
                          class="item"
                          effect="dark"
                          content="发布"
                          placement="top"
                        >
                          <el-button
                            circle
                            type="success"
                            size="small"
                            icon="el-icon-document-checked"
                            @click="handlePublish(item)"
                          />
                        </el-tooltip>

                        <el-tooltip
                          v-if="item.isPublish== '1' && item.auditStatus == '2'"
                          class="item"
                          effect="dark"
                          content="下架"
                          placement="top"
                        >
                          <el-button
                            circle
                            type="danger"
                            size="small"
                            icon="el-icon-document-delete"
                            @click="handlePublish(item)"
                          />
                        </el-tooltip>

                        <el-tooltip
                          v-if="(item.auditStatus == '0' && item.isPublish == '0') || (item.auditStatus == '1')"
                          class="item"
                          effect="dark"
                          content="提交审核"
                          placement="top"
                        >
                          <el-button
                            circle
                            type="success"
                            size="small"
                            icon="el-icon-user"
                            @click="handleAudit(item)"
                          />
                        </el-tooltip>

                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top"
                        >
                          <el-button
                            v-if="isMobile"
                            circle
                            type="primary"
                            size="small"
                            icon="el-icon-edit"
                            @click="createBlog(true, item)"
                          />
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="预览"
                          placement="top"
                        >
                          <el-button
                            circle
                            type="info"
                            size="small"
                            icon="el-icon-search"
                            @click="goToInfo(item)"
                          />
                        </el-tooltip>

                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="删除"
                          placement="top"
                        >
                          <el-button
                            circle
                            type="danger"
                            size="small"
                            icon="el-icon-delete"
                            @click="handleDeleteBlog(item)"
                          />
                        </el-tooltip>
                      </el-row>
                    </div>
                  </el-card>
                </el-timeline-item>
              </div>
              <span v-if="isEnd && userBlogList.length == 0">
                <el-empty description="空空如也，快去创作吧~" image="" />
              </span>
            </el-timeline>

            <div class="isEnd">
              <div v-if="!isEnd&&!loading" class="loadContent" @click="loadMore('blog')">
                查看更多
              </div>
              <div v-if="!isEnd&&loading" class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-facebook">
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <span v-if="isEnd && userBlogList.length > 0 ">我也是有底线的~</span>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane v-if="showCreateQuestion" label="我的问答" name="8">
          <span slot="label"><i class="el-icon-s-claim" /> 我的问答</span>

          <div class="filter-container" style="margin: 10px 0 10px 0;">
            <el-form :inline="true" style="margin-bottom: 8px;">
              <el-input
                v-model="queryParams.keyword"
                size="mini"
                clearable
                class="filter-item"
                style="width: 130px;"
                placeholder="请输入问答名称"
                @input="getUserQuestionList(true)"
              />

              <el-select
                v-model="queryParams.publishKeyword"
                size="mini"
                clearable
                placeholder="是否发布"
                style="width:100px"
                @change="getUserQuestionList(true)"
              >
                <el-option
                  v-for="item in blogPublishDictList"
                  :key="item.uid"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                />
              </el-select>
            </el-form>
          </div>

          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto;">
            <el-timeline>
              <div
                v-for="item in userQuestionList"
                :key="item.uid"
                class="myArticle"
              >
                <el-timeline-item :timestamp="item.createTime" placement="top">
                  <el-card>
                    <template v-if="item.isPublish==1">
                      <el-row :gutter="24">
                        <el-col :span="24">
                          <div style="height: 70px;">
                            <p class="blogtext" style="font-weight: bold; cursor: pointer;" @click="goToQuestionInfo(item)">
                              {{ item.title }}
                            </p>
                          </div>
                        </el-col>
                      </el-row>

                      <div class="bloginfo">
                        <ul>
                          <li class="author">
                            <span class="iconfont">&#xe60f;</span>
                            <span>{{ item.user.nickName }}</span>
                          </li>
                          <li v-if="item.blogSort" class="lmname">
                            <span class="iconfont">&#xe603;</span>
                            <span>{{ item.blogSort.sortName }}</span>
                          </li>
                          <li class="view">
                            <span class="iconfont">&#xe8c7;</span>
                            <span>{{ item.clickCount }}</span>
                          </li>
                          <li class="like">
                            <span class="iconfont icon-shoucang1" />
                            {{ item.collectCount }}
                          </li>
                        </ul>
                      </div>

                      <div style="margin-top: 5px; float: left">
                        <el-tag v-if="item.isPublish==1" type="success" style="font-size: 12px">
                          已上架
                        </el-tag>
                        <el-tag v-if="item.isPublish==0" type="info" style="font-size: 12px">
                          未发布
                        </el-tag>
                      </div>

                      <div class="operation">
                        <el-row :gutter="24">
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="编辑"
                            placement="top"
                          >
                            <el-button
                              v-if="isMobile"
                              circle
                              type="primary"
                              size="small"
                              icon="el-icon-edit"
                              @click="createQuestion(true, item)"
                            />
                          </el-tooltip>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="预览"
                            placement="top"
                          >
                            <el-button
                              circle
                              type="info"
                              size="small"
                              icon="el-icon-search"
                              @click="goToQuestionInfo(item)"
                            />
                          </el-tooltip>
                          <el-tooltip
                            class="item"
                            effect="dark"
                            content="删除"
                            placement="top"
                          >
                            <el-button
                              circle
                              type="danger"
                              size="small"
                              icon="el-icon-delete"
                              @click="handleDeleteQuestion(item)"
                            />
                          </el-tooltip>
                        </el-row>
                      </div>
                    </template>
                    <template v-else>
                      <div style="color:#bbb">
                        当前内容已下架，已无法查看
                      </div>
                    </template>
                  </el-card>
                </el-timeline-item>
              </div>

              <span v-if="isEnd && userQuestionList.length == 0">
                <el-empty description="空空如也，快去创作吧~" image="" />
              </span>
            </el-timeline>

            <div class="isEnd">
              <div v-if="!isEnd&&!loading" class="loadContent" @click="loadMore('question')">
                查看更多
              </div>
              <div v-if="!isEnd&&loading" class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-facebook">
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <span v-if="isEnd && userQuestionList.length > 0">我也是有底线的~</span>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="我的直播" name="14">
          <span slot="label"><i class="el-icon-s-order" /> 我的直播</span>
          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto;">
            <div
              v-for="(item,index) in meetList"
              :key="item.uid+index"
              class="myLive"
            >
              <el-card>
                <div class="myLiveTitle">
                  <div :class="`status ${item.liveStatus == 0?'noBegin':item.liveStatus == 1?'continue':'ending'}`">
                    <p>
                      {{ item.liveStatus == 0?'未开始':item.liveStatus == 1?'进行中':'已结束' }}
                    </p>
                  </div>
                  <div
                    class="livetext"
                    @click="goToLiveInfo(item)"
                  >
                    {{ item.title }}
                  </div>
                </div>

                <div
                  class="rightCenter ck-content "
                >
                  <p>{{ startTime(item) }} {{ endTime(item) }}</p>
                  <p :class="item.createFlag?'createStatus':'joinStatus'">
                    {{ item.createFlag?'由我发起':'由我参与' }}
                  </p>
                </div>
                <div v-if="item.createFlag" class="livebtn clearfix">
                  <el-button v-show="item.liveStatus == '2'" icon="el-icon-s-data" @click="seeStatistics(item)">
                    数据统计
                  </el-button>
                  <el-button icon="el-icon-edit-outline" class="Fright" @click="createLive(true, item)">
                    编辑
                  </el-button>
                  <el-button
                    v-if="item.liveStatus!=1"
                    class="el-icon-delete Fright"
                    type="danger"
                    plain
                    @click="deleteLive(item)"
                  />
                </div>
              </el-card>
            </div>

            <span v-if="isEnd && meetList.length == 0">
              <el-empty description="空空如也，快去创建直播或参加直播吧~" image="" />
            </span>


            <div class="isEnd">
              <div v-if="!isEnd&&!loading" class="loadContent" @click="loadMore('meet')">
                查看更多
              </div>
              <div v-if="!isEnd&&loading" class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-facebook">
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <span v-if="isEnd && meetList.length > 0">我也是有底线的~</span>
            </div>
          </div>
        </el-tab-pane>


        <el-tab-pane label="我的分享" name="13">
          <span slot="label"><i class="el-icon-s-order" /> 我的分享</span>
          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto;">
            <el-timeline>
              <div
                v-for="item in shareList"
                :key="item.uid"
                class="myArticle"
              >
                <el-timeline-item :timestamp="item.shareTime" placement="top">
                  <el-card>
                    <template v-if="judgeDiv('share',item)">
                      <el-row :gutter="24">
                        <el-col :span="24">
                          <div>
                            <p
                              v-if="item.resourceType == '1' && item.blog"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToInfo(item.blog,item.uid)"
                            >
                              {{ item.blog.title }}
                            </p>
                            <p
                              v-if="item.resourceType == '2' && item.question"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToQuestionInfo(item.question,item.uid)"
                            >
                              {{ item.question.title }}
                            </p>
                            <p
                              v-if="item.resourceType == '4' && item.userMoment"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToUserMoment(item.userMoment,item.uid)"
                            >
                              用户动态
                            </p>
                            <p
                              v-if="item.resourceType == '11' && item.live"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToLiveInfo(item.live,item.uid)"
                            >
                              {{ item.live.title }}
                            </p>
                            <p
                              v-if="item.resourceType == '12' && item.exam"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToAnswerInfo(item.exam,item.uid)"
                            >
                              {{ item.exam.name }}
                            </p>
                            <p
                              v-if="item.resourceType == '13' && item.task"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToTaskInfo(item.task,item.uid)"
                            >
                              {{ item.task.name }}
                            </p>
                          </div>
                        </el-col>
                      </el-row>
                      <div
                        v-if="item.resourceType == '1' && item.blog"
                        v-highlight
                        class="rightCenter ck-content over-hide"
                        style="color: #8F8F8F"
                        v-html="$xss(item.blog.summary, options)"
                      />
                      <div
                        v-if="item.resourceType == '2' && item.question"
                        v-highlight
                        class="rightCenter ck-content over-hide"
                        style=" color: #8F8F8F"
                        v-html="$xss(item.question.summary, options)"
                      />
                      <div
                        v-if="item.resourceType == '4' && item.userMoment"
                        v-highlight
                        class="rightCenter ck-content over-hide"
                        style=" color: #8F8F8F"
                        v-html="$xss(item.userMoment.content, options)"
                      />
                      <div
                        v-if="item.resourceType == '11' && item.live&&item.live.content"
                        v-highlight
                        class="rightCenter ck-content over-hide"
                        style=" color: #8F8F8F"
                        v-html="$xss(item.live.content, options)"
                      />
                      <div
                        v-if="item.resourceType == '13' && item.task&&item.task.description"
                        v-highlight
                        class="rightCenter ck-content over-hide"
                        style="color: #8F8F8F"
                        v-html="$xss(item.task.description, options)"
                      />


                      <div class="bloginfo">
                        <ul>
                          <li v-if="!['12','13'].includes(item.resourceType)" class="author pointer">
                            <el-avatar
                              v-if="item.user"
                              :class="item.user&&item.user.userTag > 0 ?'vip-color':''"
                              fit="fill"
                              size="medium"
                              :src="item.user.photoUrl"
                              @click.native="getUserCenter(item)"
                            />
                            <el-avatar
                              v-else
                              fit="fill"
                              size="small"
                              src="https://empty"
                            >
                              <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png">
                            </el-avatar>
                          </li>
                          <li class="lmname" style="margin-top: 10px; margin-left: -10px">
                            <span v-if="item.user" @click="getUserCenter(item)">{{ item.user.nickName }}</span>
                          </li>
                          <li v-if="!['6','12','13'].includes(item.resourceType)" class="view" style="margin-top: 10px; margin-left: -5px">
                            {{ (item.browseNum||0 ) | CountFormat }}
                            浏览
                          </li>
                          <li v-if="!['6','12','13'].includes(item.resourceType)" class="like" style="margin-top: 10px; margin-left: -5px">
                            {{ (item.commentNum||0) | CountFormat }}
                            评论
                          </li>
                          <li v-if="!['6','12','13'].includes(item.resourceType)" class="like" style="margin-top: 10px; margin-left: -5px">
                            {{ (item.praiseNum||0) | CountFormat }}
                            点赞
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template v-else>
                      <div style="color:#bbb">
                        当前内容已下架，已无法查看
                      </div>
                    </template>
                    <div class="control-wrap">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除"
                        placement="top"
                      >
                        <el-button
                          circle
                          type="danger"
                          size="small"
                          icon="el-icon-delete"
                          @click="cancelCensusShare(item)"
                        />
                      </el-tooltip>
                    </div>
                  </el-card>
                </el-timeline-item>
              </div>

              <span v-if="isEnd && shareList.length == 0">
                <el-empty description="空空如也，快去分享内容吧~" image="" />
              </span>
            </el-timeline>

            <div class="isEnd">
              <div v-if="!isEnd&&!loading" class="loadContent" @click="loadMore('share')">
                查看更多
              </div>
              <div v-if="!isEnd&&loading" class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-facebook">
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <span v-if="isEnd && shareList.length > 0">我也是有底线的~</span>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="我的收藏" name="10">
          <span slot="label"><i class="el-icon-s-order" /> 我的收藏</span>
          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto;">
            <el-timeline>
              <div
                v-for="item in collectList"
                :key="item.uid"
                class="myArticle"
              >
                <el-timeline-item :timestamp="item.createTime" placement="top">
                  <el-card>
                    <template v-if="judgeDiv('collect',item)">
                      <el-row :gutter="24">
                        <el-col :span="24">
                          <div>
                            <p
                              v-if="item.collectType == '1' && item.blog"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToInfo(item.blog)"
                            >
                              {{ item.blog.title }}
                            </p>
                            <p
                              v-if="item.collectType == '2' && item.question"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToQuestionInfo(item.question)"
                            >
                              {{ item.question.title }}
                            </p>
                            <p
                              v-if="item.collectType == '4' && item.userMoment"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToUserMoment(item.userMoment)"
                            >
                              用户动态
                            </p>
                            <p
                              v-if="item.collectType == '6' && item.problem"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToProblem(item.problem)"
                            >
                              {{ item.problem.title }}
                            </p>
                            <p
                              v-if="item.collectType == '3' && item.live"
                              class="blogtext"
                              style="font-weight: bold; cursor: pointer;"
                              @click="goToLiveInfo(item.live)"
                            >
                              {{ item.live.title }}
                            </p>
                          </div>
                        </el-col>
                      </el-row>

                      <div
                        v-if="item.collectType == '1' && item.blog"
                        v-highlight
                        class="rightCenter ck-content"
                        style="min-height: 50px; color: #8F8F8F"
                        v-html="$xss(item.blog.summary, options)"
                      />
                      <div
                        v-if="item.collectType == '2' && item.question"
                        v-highlight
                        class="rightCenter ck-content"
                        style="min-height: 50px; color: #8F8F8F"
                        v-html="$xss(item.question.summary, options)"
                      />
                      <div
                        v-if="item.collectType == '4' && item.userMoment"
                        v-highlight
                        class="rightCenter ck-content"
                        style="min-height: 50px; color: #8F8F8F"
                        v-html="$xss(item.userMoment.content, options)"
                      />
                      <div
                        v-if="item.collectType == '6' && item.problem"
                        v-highlight
                        class="rightCenter ck-content"
                        style="min-height: 50px; color: #8F8F8F"
                        v-html="$xss(item.problem.summary, options)"
                      />
                      <div
                        v-if="item.collectType == '3' && item.live&&item.live.content"
                        v-highlight
                        class="rightCenter ck-content"
                        style="min-height: 50px; color: #8F8F8F"
                        v-html="$xss(item.live.content, options)"
                      />

                      <div class="bloginfo">
                        <ul>
                          <li class="author pointer">
                            <el-avatar
                              v-if="item.user"
                              :class="item.user&&item.user.userTag > 0 ?'vip-color':''"
                              fit="fill"
                              size="medium"
                              :src="item.user.photoUrl"
                              @click.native="getUserCenter(item)"
                            />
                            <el-avatar
                              v-else
                              fit="fill"
                              size="small"
                              src="https://empty"
                            >
                              <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png">
                            </el-avatar>
                          </li>
                          <li class="lmname" style="margin-top: 10px; margin-left: -10px" @click="getUserCenter(item)">
                            <span>{{ item.user&& item.user.nickName }}</span>
                          </li>
                          <li v-if="item.collectType != '6'" class="view" style="margin-top: 10px; margin-left: -5px">
                            <span class="iconfont">&#xe8c7;</span>
                            <span v-if="item.collectType == '1' && item.blog">{{ item.blog.clickCount }}</span>
                            <span v-if="item.collectType == '2' && item.question ">{{ item.question.clickCount }}</span>
                            <span v-if="item.collectType == '4' && item.userMoment ">{{ item.userMoment.clickCount }}</span>
                            <span v-if="item.collectType == '3' && item.live ">{{ item.live.clickCount }}</span>
                          </li>
                          <li v-if="item.collectType != '6'" class="like" style="margin-top: 10px; margin-left: -5px">
                            <span class="iconfont icon-shoucang1" />
                            <span v-if="item.collectType == '1' && item.blog">{{ item.blog.collectCount }}</span>
                            <span v-if="item.collectType == '2' && item.question">{{ item.question.collectCount }}</span>
                            <span v-if="item.collectType == '4' && item.userMoment">{{ item.userMoment.collectCount }}</span>
                            <span v-if="item.collectType == '3' && item.live">{{ item.live.collectCount }}</span>
                          </li>

                          <li style="margin-top: 10px; margin-left: -5px">
                            <span v-if="item.collectType == '1' && item.blog">
                              <el-tag type="primary" style="font-size: 12px">博客</el-tag>
                              <el-tag v-if="item.blog.isPublish==1" type="success" style="font-size: 12px">已上架</el-tag>
                              <el-tag v-if="item.blog.isPublish==0" type="info" style="font-size: 12px">未发布</el-tag>
                            </span>
                            <span v-if="item.collectType == '2' && item.question">
                              <el-tag type="warning" style="font-size: 12px">问答</el-tag>
                              <el-tag v-if="item.question.isPublish==1" type="success" style="font-size: 12px">已上架</el-tag>
                              <el-tag v-if="item.question.isPublish==0" type="info" style="font-size: 12px">未发布</el-tag>
                            </span>
                            <span v-if="item.collectType == '4' && item.userMoment">
                              <el-tag type="warning" style="font-size: 12px">动态</el-tag>
                              <el-tag v-if="item.userMoment.isPublish==1" type="success" style="font-size: 12px">已上架</el-tag>
                              <el-tag v-if="item.userMoment.isPublish==0" type="info" style="font-size: 12px">未发布</el-tag>
                            </span>
                            <span v-if="item.collectType == '3' && item.live">
                              <el-tag type="warning" style="font-size: 12px">直播</el-tag>
                              <el-tag v-if="item.live.isPublish==1" type="success" style="font-size: 12px">已上架</el-tag>
                              <el-tag v-if="item.live.isPublish==0" type="info" style="font-size: 12px">未发布</el-tag>
                            </span>
                          </li>

                          <li style="margin-top: 5px; margin-left: -3px">
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="取消收藏"
                              placement="top"
                            >
                              <el-button
                                circle
                                type="danger"
                                size="small"
                                icon="el-icon-delete"
                                @click="cancelCollect(item.bizUid, item.collectType)"
                              />
                            </el-tooltip>
                          </li>
                        </ul>
                      </div>
                    </template>
                    <template v-else>
                      <div style="color:#bbb">
                        当前内容已下架，已无法查看
                      </div>
                    </template>
                  </el-card>
                </el-timeline-item>
              </div>

              <span v-if="isEnd && collectList.length == 0">
                <el-empty description="空空如也，快去收藏内容吧~" image="" />
              </span>
            </el-timeline>

            <div class="isEnd">
              <div v-if="!isEnd&&!loading" class="loadContent" @click="loadMore('collect')">
                查看更多
              </div>
              <div v-if="!isEnd&&loading" class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-facebook">
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <span v-if="isEnd && collectList.length > 0">我也是有底线的~</span>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="我的积分" name="9">
          <span slot="label"><i class="el-icon-coin" /> 我的积分</span>
          <div :style="{'height': minHeight - 65 + 'px'}" style="width: 100%; overflow:auto">
            <el-timeline>
              <el-timeline-item
                v-for="creditLog in creditsLogList"
                :key="creditLog.uid"
                :timestamp="timeAgo(creditLog.createTime)"
                placement="top"
              >
                <el-card>
                  <span>来源</span><el-tag type="primary">
                    {{ creditLog.actionName }}
                  </el-tag>
                  <el-tag v-if="creditLog.changeCredits >=0" type="success">
                    +{{ creditLog.changeCredits }}
                  </el-tag>
                  <el-tag v-else-if="creditLog.changeCredits < 0" type="danger">
                    {{ creditLog.changeCredits }}
                  </el-tag>
                  <span>积分</span>
                </el-card>
              </el-timeline-item>

              <span v-if="isEnd && creditsLogList.length == 0">
                <el-empty description="空空如也" image="" />
              </span>
            </el-timeline>

            <div class="isEnd">
              <div v-if="!isEnd&&!loading" class="loadContent" @click="loadMore('credits')">
                查看更多
              </div>
              <div v-if="!isEnd&&loading" class="lds-css ng-scope">
                <div style="width:100%;height:100%" class="lds-facebook">
                  <div />
                  <div />
                  <div />
                </div>
              </div>
              <span v-if="isEnd && creditsLogList.length > 0">我也是有底线的~</span>
            </div>
          </div>
        </el-tab-pane>

        <el-tab-pane label="我的反馈" name="5">
          <span slot="label"><i class="el-icon-phone" /> 我的反馈</span>

          <el-collapse v-model="activeNames">
            <el-collapse-item title="反馈须知" name="1">
              <div>如果您对本站有什么想法，可以在这里进行反馈</div>
              <div>或者加入我们的QQ群进行交流</div>
            </el-collapse-item>
          </el-collapse>

          <el-divider />

          <div style="width: 100%; height: 450px;overflow:auto">
            <el-timeline>
              <el-timeline-item
                v-for="feedbackItem in feedbackList"
                :key="feedbackItem.uid"
                :timestamp="timeAgo(feedbackItem.createTime)"
                placement="top"
              >
                <el-card class="feedbackCard">
                  <div class="item">
                    <span class="title">
                      标题:
                    </span>
                    <span class="content">
                      {{ feedbackItem.title }}
                    </span>
                  </div>

                  <div class="item">
                    <span class="title">
                      内容:
                    </span>
                    <span class="content">
                      {{ feedbackItem.content }}
                    </span>
                  </div>

                  <div class="item">
                    <span class="title">
                      反馈状态:
                    </span>
                    <span class="content">
                      <template v-for="item in feedbackDictList">
                        <el-tag
                          v-if="feedbackItem.feedbackStatus == item.dictValue"
                          :key="item.uid"
                          :type="item.listClass"
                        >{{ item.dictLabel }}</el-tag>
                      </template>
                    </span>
                  </div>

                  <div class="item">
                    <span class="title">
                      回复:
                    </span>
                    <span class="content">
                      {{ feedbackItem.reply }}
                    </span>
                  </div>
                </el-card>
              </el-timeline-item>

              <span v-if="isEnd && feedbackList.length == 0">
                <el-empty description="空空如也" image="" />
              </span>
            </el-timeline>
          </div>

          <el-divider />

          <el-form label-position="left" :model="userInfo" label-width="100px">
            <el-form-item label="标题" :label-width="labelWidth">
              <el-input v-model="feedback.title" style="width: 100%" />
            </el-form-item>

            <el-form-item label="内容" :label-width="labelWidth">
              <el-input
                v-model="feedback.content"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                placeholder="请输入反馈内容"
              />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm('feedback')">
                提 交
              </el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-drawer>

    <!--头像裁剪-->
    <AvatarCropper
      v-show="imagecropperShow"
      :key="imagecropperKey"
      :width="300"
      :height="300"
      :url="url"
      lang-type="zh"
      @close="close"
      @crop-upload-success="cropSuccess"
    />

    <div class="router-view-wrap" :style="{'min-height': minHeight + 'px'}">
      <router-view />
    </div>

    <footer v-show="!isWorktableMenu">
      Copyright 七里香大健康云平台 2022<span class="footer-line">|</span>苏ICP备18065530号-2<span class="footer-line">|</span><img class="footer-image" src="../assets/img/guohui.png" alt=""> 互联网药品信息服务资格证：(苏)-非经营性-2022-0125
    </footer>

    <!--创建博客-->
    <CreateBlog
      v-if="dialogFormVisible"
      :visible="dialogFormVisible"
      :is-edit="isEdit"
      :editor-model="userInfo.editorModel"
      :form-data="formData"
      @beforeClose="beforeClose"
    />

    <!--本地上传文章-->
    <UploadBlog v-if="dialogUploadBlogVisible" :visible="dialogUploadBlogVisible" @beforeClose="beforeClose" />

    <!--创建问答-->
    <CreateQuestion
      v-if="questionDialogFormVisible"
      :visible="questionDialogFormVisible"
      :editor-model="userInfo.editorModel"
      :is-edit="isEdit"
      :form-data="questionFormData"
      @beforeClose="questionBeforeClose"
    />

    <!--创建直播-->
    <CreateLive
      v-if="liveDialogFormVisible"
      :visible="liveDialogFormVisible"
      :editor-model="userInfo.editorModel"
      :is-edit="isEdit"
      :form-data="questionFormData"
      @beforeClose="liveBeforeClose"
    />


    <Statistics
      v-if="showStatistics"
      :visible="showStatistics"
      :info="StatisticsForm"
      @close="showStatistics=false"
    />

    <div>
      <div :class="isCdTopVisible?'el-backtop cd-is-visible':'el-backtop'" style="right: 100px; bottom: 150px;" @click="returnTop">
        <i class="el-icon-caret-top" />
      </div>
    </div>
  </div>
</template>

<script>
import CreateBlog from '../components/CreateBlog'
import CreateQuestion from '../components/CreateQuestion'
import CreateLive from '../components/CreateLive'
import UploadBlog from '../components/UploadBlog'
import HotSearch from '../components/HotSearch'
import Avatar from '@/components/Avatar'
import AvatarCropper from '@/components/AvatarCropper'
import { getWebConfig, getWebNavbar } from '../api/index'
import { delCookie, getCookie, setCookie } from '@/utils/cookieUtils'
import CodeCopy from '@/components/CodeCopy'
import Statistics from '../components//Live/Statistics'
import {
  authVerify,
  editUser,
  editMobile,
  editPwd,
  deleteUserAccessToken,
  getFeedbackList,
  addFeedback,
  getBindAccountList, getMsgCode, wechatRepeatBind,
} from '../api/user'
import { getCommentListByUser, getPraiseListByUser, getReplyListByUser } from '../api/comment'
import { getUserReceiveNoticeCount, readUserReceiveNoticeCount } from '../api/notice'
import LoginBox from '../components/LoginBox/index.vue'
import ChangeMobileBind from '../components/LoginBox/ChangeMobileBind.vue'
import LoginInfo from '../components/LoginBox/Login.vue'
import { getListByDictTypeList } from '@/api/sysDictData'
import { getMeQuestionList } from '@/api/question'
import { deleteBlog, publishBlog, editBlog } from '@/api/createBlog'
import { deleteQuestion } from '@/api/question'
import { deleteLiveApi } from '@/api/liveList'
import { mapMutations } from 'vuex'
import { timeAgo } from '../utils/webUtils'
import { getSearchModel } from '@/api/search'
import { getCreditsListByUser } from '../api/creditsLog'
import { deleteCollect, getUserCollectList, getUserShareList, getUserLiveList } from '../api/collect'
import { getLoginWechat, getMiniprogramWechat } from '../api/wechat'
import { getMeBlogList } from '../api/createBlog'
import { cancelCensusShare } from '../api/share'
import { nFormatter, deleteUrlParams } from '@/utils'
import defaultAvatar from '@/assets/img/default_avatar.png'
import { getStartTime, getEndTime } from '@/utils/common'
import encryption from '@/utils/encryption'
export default {
  name: 'Index',
  components: {
    LoginBox,
    LoginInfo,
    AvatarCropper,
    CreateBlog,
    CreateQuestion,
    UploadBlog,
    CreateLive,
    HotSearch,
    Avatar,
    Statistics,
    ChangeMobileBind,
  },
  filters: {
    // 将日期过滤为 hour:minutes
    CountFormat(num) {
      return nFormatter(num)
    },
  },

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value === this.mobileInfo.password) {
        callback()
      } else {
        callback(new Error('两次输入密码不一致!'))
      }
    }
    return {
      // xss白名单配置
      options: {
        whiteList: {
          a: ['href', 'title', 'target'],
          span: ['class'],
          h1: ['class'],
          h2: ['class'],
          h3: ['class'],
          h4: ['class'],
          pre: [],
          code: ['class'],
          p: ['class'],
        },
      },
      webConfig: {},
      bindInfo: {},
      wechatBindDialogVisible: false,
      minHeight: window.screen.height - 255,
      queryParams: {
        keyword: '',
        tagKeyword: '', //标签搜索
        sortKeyword: '', //分类搜索
        publishKeyword: '', // 发布 搜索
        originalKeyword: '', // 原创 搜索
        openCommentKeyword: '', // 开启评论
        auditStatusKeyword: '', // 审核状态
      }, // 搜索条件
      // 第三方账号绑定类别
      loginType: {
        password: true,
        gitee: true,
        github: true,
        qq: true,
        wechat: true,
      },
      total: 0, //总数量
      isEnd: false, //是否到底底部了
      loading: false, //是否正在加载
      pageSize: 10,
      currentPage: 0,
      dialogFormVisible: false, // 控制创建博客的弹出
      questionDialogFormVisible: false, // 控制发表问题的弹出
      dialogUploadBlogVisible: false, // 控制上传博客的弹出
      liveDialogFormVisible: false, // 控制发表问题的弹出
      searchModel: 0, //搜索模式 0:SQL搜索、1:ES搜索、2:Solr搜索
      activeNames: ['1', '2'], //激活的折叠面板
      activeName: '0', // 激活的标签
      blogOriginalDictList: [], //是否原创字典
      blogPublishDictList: [], //是否发布字典
      yesNoDictList: [], // 是否 字典列表
      genderDictList: [], //性别 字典列表
      feedbackDictList: [], // 反馈 字典列表
      editorModalDictList: [], // 文本编辑器字典列表
      commentReportDictList: [], // 评论举报字典
      auditStatusDictList: [], // 审批状态字典
      commentReportDictDefault: null, // 评论举报字典默认值
      imagecropperShow: false,
      imagecropperKey: 0,
      url: process.env.VUE_APP_PICTURE_API + '/file/cropperPicture',
      webSite: process.env.VUE_APP_VUE_MOGU_WEB,
      webNavbarList: [],
      drawer: false,
      info: {},
      saveTitle: '',
      keyword: '',
      showSearch: false, //控制搜索框的弹出
      showHead: false, //控制导航栏的弹出
      showUploadBlog: false, // 显示上传文章
      isCdTopVisible: false,
      isVisible: true, //控制web端导航的隐藏和显示
      isLogin: false,
      showLogin: false, //显示登录框
      isBindBack: false, //换绑返回
      loginData: {},
      showLoginInfo: false,
      identity: 3,
      showStatistics: false, //显示我的直播数据统计
      StatisticsForm: {},
      openid: '',
      wechatBindMobile: '',
      userInfo: { // 用户信息
      },
      feedback: {}, //反馈提交
      blogLink: {}, //友链申请
      icon: false, //控制删除图标的显示
      labelWidth: '80px',
      commentList: [], //我的评论
      replyList: [], //我的回复
      praiseList: [], //我的点赞
      feedbackList: [], //我的反馈
      userBlogList: [], // 用户博客列表
      userQuestionList: [], // 用户问答列表
      creditsLogList: [], //积分流水列表
      collectList: [], // 用户收藏列表
      openComment: '0', //是否开启评论
      defaultAvatar: defaultAvatar, // 默认头像
      drawerSize: '33%',
      userReceiveCommentCount: 0, // 用户收到的评论数
      userReceivePraiseCount: 0, // 用户收到的点赞数
      userReceiveSystemCount: 0, // 用户收到的系统通知数
      userReceiveWatchCount: 0, // 用户收到的关注数
      userReceiveMessageCount: 0, // 用户收到的消息数
      isEdit: false, // 是否编辑博客
      isMobile: window.innerWidth > 960,
      formData: {}, // 表单数据
      questionFormData: {}, // 问答数据
      problemFormData: {}, // 面经数据
      showCreateBlog: false, // 是否显示用户创作按钮
      showCreateQuestion: false, // 是否显示创建问答按钮
      showChat: false, // 显示私信按钮
      browserFlag: 1, // 浏览器标志【默认Chrome】
      accountList: [], // 第三方綁定账号列表
      darkModel: 0, //暗黑模式 是否开启
      dialogHotSearchVisible: false,
      mobileInfo: {
        mobile: '',
        validCode: '',
        password: '',
        confirmPassword: '',
      },
      getVerifyCodeBtnDisabled: false, //获取验证码按钮不可点击
      getVerifyCodeBtnText: '发送验证码',
      shareList: [],
      meetList: [],
      personPassEye: true,
      personPass2Eye: true,
      showMobile: false,
      showValidCode: false,
      showPassword: false,
      showCancleBtn: false,
      showSaveBtn: false,
      timeInterval: null,
      rules: {
        email: [
          { pattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/, message: '请输入正确的邮箱' },
        ],
        nickName: [
          { min: 0, max: 20, message: '长度在0到20个字符' },
        ],
        identity: [
          { required: true, message: '请选择身份', trigger: 'change' },
        ],
        summary: [
          { min: 0, max: 200, message: '长度在0到200个字符' },
        ],
      },
      mobileRules: {
        mobile: [
          { required: true, message: '手机号码不能为空', trigger: ['blur', 'change'] },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请填写正确的手机号码' },

        ],
        validCode: [
          { required: true, message: '请输入验证码', trigger: ['blur', 'change'] },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: ['blur', 'change'] },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: ['blur', 'change'] },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: ['blur', 'change'] },
          { validator: validatePass, trigger: ['blur', 'change'] },
        ],
      },
      linkRules: {
        title: [
          { required: true, message: '网站名称不能为空', trigger: 'blur' },
          { min: 1, max: 10, message: '长度在1到10个字符' },
        ],
        summary: [
          { required: true, message: '简介不能为空', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在1到50个字符' },
        ],
        url: [
          { required: true, message: '网站地址不能为空', trigger: 'blur' },
          { pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/, message: '请输入有效的URL' },
        ],
        email: [
          { pattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/, message: '请输入正确的邮箱' },
        ],
      },
      userInfoRules: {
        oldPwd: [
          { required: true, message: '旧密码不能为空', trigger: 'blur' },
          { min: 5, max: 20, message: '密码长度在5到20个字符' },
        ],
        newPwd: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { min: 5, max: 20, message: '密码长度在5到20个字符' },
        ],
        newPwd2: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { min: 5, max: 20, message: '密码长度在5到20个字符' },
        ],
      },
      isWorktableMenu: false, //当前菜单是工作台
      accountId: '', //七里香账户id
      isFromMiniprogram: false,
    }
  },
  computed: {
    isMiniprogram() {
      const ua = navigator.userAgent.toLowerCase()
      if (ua.indexOf('miniprogram') == -1) {
        //不小程序;
        return false
      } else {
        //是小程序;
        return true
      }
    },


  },
  watch: {

    $route: {
      handler: function(to) {
        if (to.query.state && to.query.code) {
          if (to.query.from == window.location.origin) {
            this.getLoginInfo(to.query.code, to.query.state, to.query.mobile)
          } else {
            window.location.replace(to.query.from + window.location.search)

          }
        } else if (to.path == '/' && to.query.miniprogram == 1) {
          this.showLoginInfoEvent(to.query)
        } else if (to.path == '/' && to.query.needLogin === 'true') {
          this.showLogin = true
        } else {
          this.getToken()

        }
        if (to.path.includes('/workTable')) {
          this.isWorktableMenu = true
        } else {
          this.isWorktableMenu = false
        }


        this.getCurrentPageTitle()
      },
      deep: true, // 深度观察监听
      immediate: true,
    },
    // 判断登录状态位是否改变（用于控制弹框）
    '$store.state.app.loginMessage': function() {
      if (this.isWeiXin()) {
        let fullPath = this.$route.fullPath
        let url = new URL(`https://www.qlxjk.com${fullPath}`)
        let urlSearchParams = url.searchParams
        urlSearchParams.append('from', process.env.VUE_APP_BASE_URL)
        let redirectUri = encodeURIComponent(url.href)
        location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf157b008b65f3a05&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo&state=publicAccount#wechat_redirect`
        return
      }
      this.showLogin = true
      this.loginData = {}
    },
    // 判断登录状态位是否改变（用于控制弹框）
    '$store.state.app.createQuestionMessage': function() {
      this.questionDialogFormVisible = true
    },
    // 判断 创建直播弹出框状态位是否改变(用于控制弹出框)
    '$store.state.app.createLiveMessage': function() {
      this.liveDialogFormVisible = true
    },
    // 判断 创建文章弹出框状态位是否改变(用于控制弹出框)
    '$store.state.app.createBlogMessage': function() {
      this.dialogFormVisible = true
    },
    isVisible(val) {
      if (!val) {
        this.closeHotSearch()
      }
    },
  },
  mounted() {
    const query = this.$route.query
    if (query.vconsole) {
      return new window.VConsole()
    }
    if (query.tabPane) {
      console.log(query.tabPane)
      this.drawer = true
      this.activeName = query.tabPane
    }
    let that = this
    let offset = 300
    let after = 20
    window.addEventListener('scroll', () => {
      let scrollTop = document.documentElement.scrollTop //当前的的位置
      if (scrollTop > offset) {
        that.isCdTopVisible = true
      } else {
        that.isCdTopVisible = false
      }
      if (scrollTop > after) {
        that.isVisible = false
      } else if (scrollTop < 500) {
        that.isVisible = true
      } else {
        that.isVisible = false
      }
      // after = scrollTop
    })

    // 屏幕自适应
    window.onresize = () => {
      return (() => {
        that.setSize()
      })()
    }

  },
  metaInfo() {
    return {
      title: this.info.title,
      link: [
        { rel: 'icon', href: this.info.logoPhoto },
      ],
      meta: [
      ],
    }
  },
  updated() {
    const query = this.$route.query
    if (query.tabPane) {
      console.log(query.tabPane)
      this.drawer = true
      this.activeName = query.tabPane
      this.$router.push({
        path: '/',
      })
    }
    this.update()
  },
  created() {
    // 字典查询
    this.getDictList()
    this.getToken()
    this.getKeyword()
    this.getCurrentPageTitle()
    this.getWebConfigInfo()
    this.getWebNavbarList()
    this.setSize()
    this.setUserReceiveCommentCount()
    // 获取浏览器类型
    this.getBrowser()
    // 获取搜索模式
    this.getBlogSearchModel()

    // 获取专题是否显示
    let darkModel = getCookie('darkModel')
    if (darkModel && darkModel == '1') {
      this.darkModel = 1
      window.document.documentElement.setAttribute('data-theme', 'dark')
    } else {
      this.darkModel = 0
      window.document.documentElement.setAttribute('data-theme', 'light')
    }
  },
  methods: {
    //拿到vuex中的写的方法
    ...mapMutations(['setUserInfo', 'setLoginState', 'setWebConfigData', 'setNoticeType', 'setCommentReportDict', 'setLoginMessage', 'selectedTask']),
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true //是微信浏览器
      } else {
        return false //其他设备
      }
    },
    //从小程序跳出现完善信息弹窗
    showLoginInfoEvent(info) {
      this.wechatBindMobile = info.mobile || ''
      this.accountId = info.accountId || ''
      this.identity = info.identity || ''
      this.isFromMiniprogram = true
      this.showLoginInfo = true
    },
    closeHotSearch() {
      this.dialogHotSearchVisible = false
    },
    showHotSearch: function() {
      this.dialogHotSearchVisible = true
    },
    chooseitem: function(data) {
      this.keyword = data
      this.search()
      this.closeHotSearch()
    },
    //
    getLoginInfo: function(code, state, inputMobile) {
      const params = {
        code, state, mobile: inputMobile,
      }
      if (inputMobile) {
        wechatRepeatBind(params).then(res => {
          if (res.code == this.$ECode.SUCCESS) {
          // 判断url中是否含有token
            let { wechatAuthFlag, token, unionId, identity, oldBindMobile } = res.data
            if (token) {
              this.openid = unionId
              this.identity = identity

              this.getToken(token, 1)
            } else if (wechatAuthFlag == 1) { //微信账号已绑定其他账号 给出 【微信换绑提示】提示
              this.wechatBindMobile = oldBindMobile || ''
              this.$refs.changeMobileBind.open({ unionId, inputMobile })

            }


          } else {
            this.$message({
              type: 'error',
              message: res.message,
            })
          }
        })
      } else {
        getLoginWechat(params).then(res => {
          let url = new URL(location.href)
          const urlSearchParam = url.searchParams
          if (res.code == this.$ECode.SUCCESS) {
            // 判断url中是否含有token
            let { token, unionId, identity, user } = res.data
            if (token) {
              this.getToken(token)
              deleteUrlParams(urlSearchParam)
              this.$router.replace(url.pathname + url.search)
            } else { ////跳转完善信息页面
              this.openid = unionId
              this.identity = identity
              this.wechatBindMobile = user.mobile || ''
              this.showLoginInfo = true
            }
          } else {
            deleteUrlParams(urlSearchParam)
            this.$router.replace(url.pathname)
            this.$message({
              type: 'error',
              message: res.message,
            })
          }
        })
      }
    },
    //直播时间显示
    startTime(item) {
      return getStartTime(item)
    },
    endTime(item) {
      return getEndTime(item)
    },
    // 搜索
    search: function() {
      if (this.keyword == '' || this.keyword.trim() == '') {
        this.$notify.error({
          title: '错误',
          message: '关键字不能为空',
          type: 'success',
          offset: 100,
        })
        return
      }
      if (this.$route.path == '/userCenter') {
        this.$router.push({
          path: this.$route.fullPath,
          query: { keyword: this.keyword, model: this.searchModel },
        })
      } else {
        this.$router.push({ path: '/list', query: { keyword: this.keyword, model: this.searchModel } })
      }
    },
    //获取对应markdown代码块标签
    update() {
      setTimeout(() => {
        document.querySelectorAll('pre').forEach(el => {
          if (el.classList.contains('code-copy-added')) {
            return
          }
          //   https://cn.vuejs.org/v2/api/index.html#Vue-extend
          /* 使用基础 Vue 构造器，创建一个“子类”。参数是一个包含组件选项的对象 */
          // eslint-disable-next-line no-undef
          let ComponentClass = Vue.extend(CodeCopy)
          let instance = new ComponentClass()
          instance.code = el.innerText
          instance.parent = el
          /* 手动挂载 */
          instance.$mount()
          el.classList.add('code-copy-added')
          el.appendChild(instance.$el)
        })
      }, 100)
    },

    setLoginTypeList: function() {
      // 获取登录方式列表
      let webConfigData = this.$store.state.app.webConfigData
      this.webConfig = webConfigData
      if (webConfigData.loginTypeList != undefined) {
        let loginTypeList = JSON.parse(webConfigData.loginTypeList)
        for (let a = 0; a < loginTypeList.length; a++) {
          switch (loginTypeList[a]) {
          case '1':
            this.loginType.password = false
            break
          case '2':
            this.loginType.gitee = false
            break
          case '3':
            this.loginType.github = false
            break
          case '4':
            this.loginType.qq = false
            break
          case '5':
            this.loginType.wechat = false
            break
          default: {
            ////console.log('登录方式设置有误！！')
          }
          }
        }
      }
    },
    clickWebNavbar: function(webNavbar) {
      if (this.info.title && webNavbar.name) {
        document.title = this.info.title + ' | ' + webNavbar.name
      }
    },
    getBlogSearchModel: function() {
      getSearchModel().then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          this.searchModel = response.data
        }
      })
    },
    setSize() {
      // 屏幕大于950px的时候，显示侧边栏
      let clientWidth = document.body.clientWidth
      if (clientWidth > 1360) {
        this.drawerSize = '33%'
        this.showSearch = true
      } else if (clientWidth < 1360 && clientWidth > 950) {
        this.drawerSize = '50%'
        this.showSearch = false
      } else if (clientWidth < 950 && clientWidth > 650) {
        this.drawerSize = '70%'
        this.showSearch = false
      } else {
        this.drawerSize = '95%'
        this.showSearch = false
      }
    },
    loadContent: function() {
      this.currentPage = this.currentPage + 1
    },
    cancelCollect(bizUid, collectType) {
      let isLogin = this.$store.state.user.isLogin
      if (!isLogin) {
        this.$notify.error({
          title: '警告',
          message: '登录后才可以收藏哦~',
          offset: 100,
        })
        // 未登录，自动弹出登录框
        this.setLoginMessage(Math.random())
        return
      }
      let params = {}
      params.bizUid = bizUid
      params.collectType = collectType
      deleteCollect(params).then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          this.$message.success(response.message)
          this.getCollectList(true)
        } else {
          this.$message.error(response.message)
        }
      })
    },
    // 跳转到个人中心页
    getUserCenter: function(blog) {
      ////console.log('跳转到用户中心', blog)
      // 判断是后台添加，还是前台投稿
      if (blog.articleSource == 0) {
        let routeData = this.$router.resolve({
          path: '/userCenter',
          query: { adminUid: blog.adminUid },
        })
        window.open(routeData.href, '_self')
      } else {
        let routeData = this.$router.resolve({
          path: '/userCenter',
          query: { userUid: blog.user.uid },
        })
        window.open(routeData.href, '_self')
      }
    },
    //跳转到文章详情
    goToInfo(blog, uid) {
      // if (state == 1) {
      let routeData = ''
      if (uid) {
        routeData = this.$router.resolve({
          path: '/info/' + blog.oid + '?shareUid=' + uid,
        })
      } else {
        routeData = this.$router.resolve({
          path: '/info/' + blog.oid,
        })
      }

      window.open(routeData.href, '_self')
      // } else {


    },
    //跳转到问答详情
    goToQuestionInfo(question, uid) {
      let routeData = ''
      if (uid) {
        routeData = this.$router.resolve({
          path: '/qInfo/' + question.oid + '?shareUid=' + uid,
        })
      } else {
        routeData = this.$router.resolve({
          path: '/qInfo/' + question.oid,
        })
      }


      window.open(routeData.href, '_self')
    },
    //跳转到直播详情
    goToLiveInfo(question, uid) {
      let routeData = ''
      if (uid) {
        routeData = this.$router.resolve({
          path: '/live/' + question.uid + '?shareUid=' + uid,
        })
      } else {
        routeData = this.$router.resolve({
          path: '/live/' + question.uid,
        })
      }


      window.open(routeData.href, '_self')
    },
    //跳转到答题详情
    goToAnswerInfo(question, uid) {
      let routeData = ''
      if (uid) {
        routeData = this.$router.resolve({
          path: '/answer/' + question.id + '?shareUid=' + uid,
        })
      } else {
        routeData = this.$router.resolve({
          path: '/answer/' + question.id,
        })
      }


      window.open(routeData.href, '_self')
    },
    //跳转到任务详情
    goToTaskInfo(task, uid) {
      let routeData = ''
      if (uid) {
        routeData = this.$router.resolve({
          path: '/task/' + task.id + '?shareUid=' + uid,
        })
      } else {
        routeData = this.$router.resolve({
          path: '/task/' + task.id,
        })
      }
      window.open(routeData.href, '_self')
    },

    //跳转到问答详情
    goToUserMoment(userMoment, uid) {
      let routeData = ''
      if (uid) {
        routeData = this.$router.resolve({
          path: '/mInfo/' + userMoment.uid + '?shareUid=' + uid,
        })
      } else {
        routeData = this.$router.resolve({
          path: '/mInfo/' + userMoment.uid,
        })
      }
      window.open(routeData.href, '_self')
    },
    //跳转到问答详情
    goToProblem(problem) {
      ////console.log('跳转', problem)
      let routeData = this.$router.resolve({
        path: '/cInfo/' + problem.oid,
      })
      window.open(routeData.href, '_self')
    },
    // 获取导航栏列表
    getWebNavbarList() {
      let params = {}
      params.isShow = 1
      getWebNavbar(params).then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          let webNavbarList = response.data
          let newWebNavbarList = []
          for (let a = 0; a < webNavbarList.length; a++) {
            if (webNavbarList[a].isShow == 1) {
              newWebNavbarList.push(webNavbarList[a])
            }
          }
          this.webNavbarList = newWebNavbarList
          setCookie('webNavbarList', JSON.stringify(webNavbarList), 1)
        }
      })
    },
    // 跳转到资源详情
    goSource: function(comment) {
      let source = comment.source
      switch (source) {
      case 'MESSAGE_BOARD': {
        let routeData = this.$router.resolve({
          path: '/messageBoard',
        })
        window.open(routeData.href, '_self')
        break
      }
      case 'BLOG_INFO': {
        let routeData = this.$router.resolve({
          path: '/info',
          query: { blogUid: comment.blogUid },
        })
        window.open(routeData.href, '_self')
        break
      }
      case 'ABOUT': {
        let routeData = this.$router.resolve({
          path: '/about',
        })
        window.open(routeData.href, '_self')
        break
      }
      default:break
      }
    },

    // 获取评论列表
    getCommentList: function(isClean) {
      if (isClean) {
        this.commentList = []
      }
      let params = {}
      params.pageSize = this.pageSize
      params.currentPage = this.currentPage
      getCommentListByUser(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let commentList = response.data.records
          this.commentList = this.commentList.concat(commentList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (commentList.length < this.pageSize) {
            this.isEnd = true
          }
        } else {
          this.isEnd = true
        }
        this.loading = false
      })
    },

    // 获取评论列表
    getCollectList: function(isClean) {
      if (isClean) {
        this.collectList = []
      }
      let params = {}
      params.pageSize = this.pageSize
      params.currentPage = isClean ? 1 : this.currentPage
      getUserCollectList(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let collectList = response.data.records
          this.collectList = this.collectList.concat(collectList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (collectList.length < this.pageSize) {
            this.isEnd = true
          } else {
            this.isEnd = false
          }
        } else {
          this.isEnd = true
        }
        this.loading = false
      })
    },
    // 获取直播列表
    getMeetList: function(isClean) {
      if (isClean) {
        this.meetList = []
      }
      let params = {}
      params.pageSize = this.pageSize
      params.currentPage = isClean ? 1 : this.currentPage
      getUserLiveList(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let meetList = response.data.records
          this.meetList = this.meetList.concat(meetList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (meetList.length < this.pageSize) {
            this.isEnd = true
          } else {
            this.isEnd = false
          }
        } else {
          this.isEnd = true
        }
        this.loading = false
      })
    },
    // 获取分享列表
    getShareList: function(isClean) {
      if (isClean) {
        this.shareList = []
      }
      let params = {}
      params.pageSize = this.pageSize
      params.currentPage = isClean ? 1 : this.currentPage
      getUserShareList(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let shareList = response.data.records
          this.shareList = this.shareList.concat(shareList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (shareList.length < this.pageSize) {
            this.isEnd = true
          }
        } else {
          this.isEnd = true
        }
        this.loading = false
      })
    },

    // 获取回复列表
    getCreditsList: function(isClean) {
      if (isClean) {
        this.creditsLogList = []
      }
      let params = {}
      params.pageSize = this.pageSize
      params.currentPage = this.currentPage
      this.loading = true
      getCreditsListByUser(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let creditsLogList = response.data.records
          ////console.log(creditsLogList)
          this.creditsLogList = this.creditsLogList.concat(creditsLogList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (creditsLogList.length < this.pageSize) {
            this.isEnd = true
          }
        } else {
          this.isEnd = true
        }
        this.loading = false
      })
    },
    // 获取回复列表
    getReplyList: function(isClean) {
      if (isClean) {
        this.replyList = []
      }
      let params = {}
      params.pageSize = this.pageSize
      params.currentPage = this.currentPage
      this.loading = true
      getReplyListByUser(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let replyList = response.data.records
          this.replyList = this.replyList.concat(replyList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (replyList.length < this.pageSize) {
            this.isEnd = true
          }
        } else {
          this.isEnd = true
        }
        this.loading = false
      })
    },

    // 获取反馈列表
    getFeedback: function() {
      let params = {}
      getFeedbackList(params).then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          this.feedbackList = response.data.records
        }
      })
    },

    // 获取点赞列表
    getPraiseList: function(isClean) {
      if (isClean) {
        this.praiseList = []
      }
      let params = {}
      params.pageSize = this.pageSize
      params.currentPage = this.currentPage
      getPraiseListByUser(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let praiseList = response.data.records
          this.praiseList = this.praiseList.concat(praiseList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (praiseList.length < this.pageSize) {
            this.isEnd = true
          }
        } else {
          this.isEnd = true
        }
        this.loading = false
      })
    },

    // 获取用户博客列表
    getBlogList: function(isClean) {
      if (isClean) {
        this.userBlogList = []
      }
      let params = {}
      params.auditStatus = this.queryParams.auditStatusKeyword
      params.isPublish = this.queryParams.publishKeyword
      params.isOriginal = this.queryParams.originalKeyword
      params.keyword = this.queryParams.keyword
      params.pageSize = this.pageSize
      params.currentPage = isClean ? 1 : this.currentPage
      getMeBlogList(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let userBlogList = response.data.records
          this.userBlogList = this.userBlogList.concat(userBlogList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (userBlogList.length < this.pageSize) {
            this.isEnd = true
          } else {
            this.isEnd = false
          }
        } else {
          this.isEnd = true
        }
        this.loading = false
      })
    },

    // 获取用户问题列表
    getUserQuestionList: function(isClean) {
      if (isClean) {
        this.userQuestionList = []
      }
      let params = {}
      params.isPublish = this.queryParams.publishKeyword
      params.keyword = this.queryParams.keyword
      params.pageSize = this.pageSize
      params.currentPage = isClean ? 1 : this.currentPage
      getMeQuestionList(params).then(response => {
        if (response.code == this.$ECode.SUCCESS && response.data.records.length > 0) {
          let userQuestionList = response.data.records
          this.userQuestionList = this.userQuestionList.concat(userQuestionList)
          this.total = response.data.total
          this.pageSize = response.data.size
          this.currentPage = response.data.current
          //全部加载完毕
          if (userQuestionList.length < this.pageSize) {
            this.isEnd = true
          } else {
            this.isEnd = false
          }
        } else {
          this.isEnd = true
        }
        this.loading = false

      })
    },

    // 删除博客
    handleDeleteBlog: function(row) {
      let that = this
      this.$confirm('此操作将把博客删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let params = {}
          params.uid = row.uid
          deleteBlog(params).then(response => {
            that.$commonUtil.message.success(response.message)
            that.getBlogList(true)
          })
        }).catch(() => {
          that.$commonUtil.message.info('已取消删除')
        })
    },
    handleDownload: function(object) {
      this.$commonUtil.htmlToMarkdownFile(object.title, object.content)
    },
    // 删除问答
    handleDeleteQuestion: function(row) {
      let that = this
      this.$confirm('此操作将把问答删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let params = {}
          params.uid = row.uid
          deleteQuestion(params).then(response => {
            that.$commonUtil.message.success(response.message)
            that.getUserQuestionList(true)
          })
        })
        .catch(() => {
          that.$commonUtil.message.info('已取消删除')
        })
    },
    //删除直播
    deleteLive: function(row) {
      let that = this
      this.$confirm('是否确定删除此次直播', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteLiveApi(row.uid).then(response => {
            that.$commonUtil.message.success(response.message)
            that.getMeetList(true)
          })
        })
        .catch(() => {
          that.$commonUtil.message.info('已取消删除')
        })
    },

    // 删除分享
    cancelCensusShare(row) {
      this.$confirm('此操作将把分享删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let params = {}
          params.shareUid = row.uid
          cancelCensusShare(params).then(response => {
            if (response.code == this.$ECode.SUCCESS) {
              this.$commonUtil.message.success('删除成功')
              this.getShareList(true)
              return
            }

            this.$commonUtil.message.error(response.message)

          })
        })
        .catch(() => {
          this.$commonUtil.message.info('已取消删除')
        })
    },
    userBindAccountList() {
      getBindAccountList({ uid: this.userInfo.uid }).then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          this.accountList = [response.data]
        }
      })
    },

    // 标签选择
    handleClick(tab) {
      this.currentPage = 1
      this.queryParams = {
        keyword: '',
        tagKeyword: '', //标签搜索
        sortKeyword: '', //分类搜索
        publishKeyword: '', // 发布 搜索
        originalKeyword: '', // 原创 搜索
        openCommentKeyword: '', // 开启评论
        auditStatusKeyword: '', // 审核状态
      } // 搜索条件
      this.isLoading = false
      this.isEnd = false
      this.userQuestionList = []
      this.userBlogList = []
      this.praiseList = []
      this.commentList = []
      this.replyList = []
      switch (tab.label) {
      case '账号绑定': {
        ////console.log('账号绑定')
        this.setLoginTypeList()
        this.userBindAccountList()
        break
      }
      case '我的文章': {
        this.getBlogList(true)
        break
      }
      case '我的问答': {
        this.getUserQuestionList(true)
        break
      }
      case '我的分享': {
        this.getShareList(true)
        break
      }
      case '我的直播': {
        this.getMeetList(true)
        break
      }
      case '我的收藏': {
        this.getCollectList(true)
        break
      }
      case '我的评论': {
        this.getCommentList(true)
        break
      }
      case '我的回复': {
        this.getReplyList(true)
        break
      }
      case '我的积分': {
        this.getCreditsList(true)
        break
      }
      case '我的点赞': {
        this.getPraiseList(true)
        break
      }
      case '我的反馈': {
        this.getFeedback(true)
        break
      }
      default:break
      }
    },

    // 跳转到资源详情
    loadMore: function(type) {
      this.currentPage = this.currentPage + 1
      switch (type) {
      case 'comment': {
        this.getCommentList(false)
        break }
      case 'praise': {
        this.getPraiseList(false)
        break }
      case 'reply': {
        this.getReplyList(false)
        break }
      case 'credits': {
        this.getCreditsList(false)
        break }
      case 'feedback': {
        // this.getFeedback()
        break }
      case 'blog': {
        this.getBlogList(false)
        break }
      case 'question': {
        this.getUserQuestionList(false)
        break }
      case 'collect': {
        this.getCollectList(false)
        break }
      case 'share': {
        this.getShareList(false)
        break }
      case 'meet': {
        this.getMeetList(false)
        break }

      default:break
      }
    },

    //弹出选择图片框
    checkPhoto() {
      this.imagecropperShow = true
    },
    /**
       * dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
       * @param dateTimeStamp
       * @returns {string}
       */
    timeAgo(dateTimeStamp) {
      return timeAgo(dateTimeStamp)
    },

    cropSuccess(resData) {
      ////console.log('裁剪成功', resData)
      this.imagecropperShow = false
      this.imagecropperKey = this.imagecropperKey + 1
      // 判断当前激活的页面
      if (this.activeName == '0') {
        // 激活个人中心页面
        this.userInfo.photoUrl = resData[0].url
        this.userInfo.avatar = resData[0].uid
      } else if (this.activeName == '5') {
        let photoList = []
        photoList.push(resData[0].url)
        this.blogLink.photoList = photoList
        this.blogLink.fileUid = resData[0].uid
      }
    },

    deletePhoto: function(type) {
      switch (type) {
      case 'user': {
        this.userInfo.photoUrl = null
        this.userInfo.avatar = ''
        this.icon = false
        break }
      default:break
      }
    },

    close() {
      this.imagecropperShow = false
    },
    //判断显示
    judgeDiv(type, item) {
      if (type == 'collect') {
        if (item.collectType == '1' && item.blog && item.blog.isPublish == 1) {
          return true
        } else if (item.collectType == '2' && item.question && item.question.isPublish == 1) {
          return true
        } else if (item.collectType == '4' && item.userMoment && item.userMoment.isPublish == 1) {
          return true
        } else if (item.collectType == '6' && item.problem && item.problem.isPublish == 1) {
          return true
        } else if (item.collectType == '3' && item.live && item.live.isPublish == 1) {
          return true
        } else {
          return false
        }

      } else if (type == 'share') {
        if (item.resourceType == '1' && item.blog && item.blog.isPublish == 1) {
          return true
        } else if (item.resourceType == '2' && item.question && item.question.isPublish == 1) {
          return true
        } else if (item.resourceType == '4' && item.userMoment && item.userMoment.isPublish == 1) {
          return true
        } else if (item.resourceType == '11' && item.live && item.live.isPublish == 1) {
          return true
        } else if (item.resourceType == '12' && item.exam) {
          return true
        } else if (item.resourceType == '13' && item.task) {
          return true
        } else {
          return false
        }

      } else {
        return false
      }
    },
    submitForm: function(type) {
      switch (type) {
      case 'editUser': {
        this.$refs.userInfo.validate((valid) => {
          if (valid) {
            editUser(this.userInfo).then(response => {
              if (response.code == this.$ECode.SUCCESS) {
                this.$message({
                  type: 'success',
                  message: response.data,
                })
              } else {
                this.$message({
                  type: 'error',
                  message: response.data,
                })
              }
            })
          }

        })
        break }
      case 'mobile': {
        this.$refs.mobileInfo.validate((valid) => {
          if (valid) {
            let url
            let params = JSON.parse(JSON.stringify(this.mobileInfo))
            if (this.showMobile) {
              url = editMobile
            } else {
              url = editPwd
              params.mobile = this.userInfo.mobile
            }
            if (params.password) {
              params.password = encryption(params.password)
            }
            if (params.confirmPassword) {
              params.confirmPassword = encryption(params.confirmPassword)
            }
            url(params).then(response => {
              if (response.code == this.$ECode.SUCCESS) {
                this.$message({
                  type: 'success',
                  message: '修改成功',
                })
                this.getVerifyCodeBtnDisabled = false
                this.getVerifyCodeBtnText = '发送验证码'
                this.timeInterval && clearInterval(this.timeInterval)
                this.drawer = false
                this.userLogout('mobile')

              } else {
                this.$message({
                  type: 'error',
                  message: response.message,
                })
                this.getVerifyCodeBtnDisabled = false
                this.getVerifyCodeBtnText = '发送验证码'
                this.timeInterval && clearInterval(this.timeInterval)
              }
            })
          }

        })
        break }


      case 'feedback': {
        let feedback = this.feedback
        if (feedback.title == undefined || feedback.title == '' || feedback.content == undefined || feedback.content == '') {
          this.$message({
            type: 'error',
            message: '必填项不能为空',
          })
          return
        }
        addFeedback(this.feedback).then(response => {
          if (response.code == this.$ECode.SUCCESS) {
            this.$message({
              type: 'success',
              message: response.data,
            })
            this.feedback = {}
            this.getFeedback()
          } else {
            this.$message({
              type: 'error',
              message: response.data,
            })
          }
        })
        break }


      default:break
      }
    },

    /**
   *获取验证码
   */

    getVerifyCode: function() {
      let flag = true
      if (this.showMobile) {
        this.$refs.mobileInfo.validateField('mobile', (errorMessage) => {
          if (errorMessage) { //当校验没通过时
            flag = false
          }
        })

      }
      if (!flag) {
        return
      }

      let params = {
        mobile: this.showMobile ? this.mobileInfo.mobile : this.userInfo.mobile,
        sendMobileType: this.showMobile ? 'UPDATE_MOBILE' : 'UPDATE_PWD',
      }
      getMsgCode(params).then(({ code, data }) => {
        if (code == this.$ECode.SUCCESS) {
          this.getVerifyCodeBtnDisabled = true

          let time = 59
          this.timeInterval = setInterval(() => {
            if (time > 0) {
              this.getVerifyCodeBtnText = time + 's后获取'
              time--
            } else {
              clearInterval(this.timeInterval)
              this.getVerifyCodeBtnDisabled = false
              this.getVerifyCodeBtnText = '发送验证码'

            }
          }, 1000)
        } else {

          this.$message({
            type: 'error',
            message: data,
          })
        }
      })


    },
    /**
       * 字典查询
       */
    getDictList: function() {
      let dictTypeList =  ['sys_yes_no', 'sys_user_sex', 'sys_feedback_status', 'sys_editor_modal', 'sys_content_report_type', 'sys_original_status', 'sys_publish_status', 'sys_audit_status', 'sys_normal_disable', 'sys_publish_status']
      getListByDictTypeList(dictTypeList).then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          let dictMap = response.data
          this.genderDictList = dictMap.sys_user_sex.list
          this.yesNoDictList = dictMap.sys_yes_no.list
          this.feedbackDictList = dictMap.sys_feedback_status.list
          this.editorModalDictList = dictMap.sys_editor_modal.list
          this.commentReportDictList = dictMap.sys_content_report_type.list
          this.blogOriginalDictList = dictMap.sys_original_status.list
          this.blogPublishDictList = dictMap.sys_publish_status.list
          this.auditStatusDictList = dictMap.sys_audit_status.list
          this.openDictList = dictMap.sys_normal_disable.list
          if (dictMap.sys_content_report_type.defaultValue) {
            this.commentReportDictDefault = dictMap.sys_content_report_type.defaultValue
          }
          let commentReportDict = {}
          commentReportDict.list = this.commentReportDictList
          commentReportDict.default = this.commentReportDictDefault
          this.setCommentReportDict(commentReportDict)
        }
      })
    },

    getToken: function(tokens, flag) {
      let token = ''
      if (tokens) {
        token = tokens
      } else {
        token = this.getUrllets()['token']
      }

      // 判断url中是否含有token
      if (token == undefined) {
        // 从cookie中获取token
        token = getCookie('token')
      } else {
        // 设置token 31天过期
        setCookie('token', token, 31)

      }

      if (token == undefined) {
        this.isLogin = false
        this.setLoginState(this.isLogin)

      } else {
        authVerify(token).then(response => {
          if (response && response.code == this.$ECode.SUCCESS) {
            this.isLogin = true
            const info = response.data || {}
            info.identity = info.identity || 3
            this.userInfo = info
            this.showMobile = !info.mobile
            this.showValidCode = !info.mobile
            this.showPassword = !info.mobile
            this.showCancleBtn = !info.mobile
            this.showSaveBtn = !info.mobile
            // 获取用户标识
            let userTag = this.userInfo.userTag
            if (userTag > 0) {
              this.showUploadBlog = true
            }
            this.setUserInfo(this.userInfo)
            if (tokens) {
              let url = new URL(location.href)
              let urlSearchParams = url.searchParams
              deleteUrlParams(urlSearchParams)
              flag && this.$router.replace(url.pathname + url.search)
            }


          } else {
            ////console.log('校验token失败')
            this.isLogin = false
            delCookie('token')
          }
          this.setLoginState(this.isLogin)
        })
      }
    },
    /**********文章 发布与下架  *************/
    handlePublish: function(row) {
      let that = this
      if (row.isPublish == 1) {
        this.$confirm('此操作将下架博客, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          let params = {}
          params.uid = row.uid
          params.isPublish = 0
          publishBlog(params).then(response => {
            that.$commonUtil.message.success(response.message)
            that.getBlogList(true)
          })
        }).catch(() => {
          that.$commonUtil.message.info('已取消下架')
        })
      }
      if (row.isPublish == 0) {
        this.$confirm('此操作将发布博客, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          let params = {}
          params.uid = row.uid
          params.isPublish = 1
          publishBlog(params).then(response => {
            that.$commonUtil.message.success(response.message)
            that.getBlogList(true)
          })
        }).catch(() => {
          that.$commonUtil.message.info('已取消发布')
        })
      }
    },
    handleAudit: function(row) {
      let params = row
      params.isPublish = 1
      editBlog(params).then(response => {
        this.$commonUtil.message.success(response.message)
        this.getBlogList(true)
      })
    },
    /********************** 创作相关 ********************/
    createBlog: function(isEdit, formData) {

      this.isEdit = isEdit
      if (isEdit) {
        this.formData = formData
      }
      if (!this.isLogin) {
        this.setLoginMessage(Math.random())
        return
      }
      /**
         * 修改 已审核过的文章  提示 将会使文章审核状态变更为未审核 进入重新审核流程
         */
      if (isEdit && formData.auditStatus == 2) {
        // this.$confirm('修改已审核通过的文章,博客将会被重新审核, 是否继续?', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // }).then(() => {
        this.dialogFormVisible = true
        // }).catch(() => {
        //   this.$commonUtil.message.info('已取消修改')
        // })
      } else if (isEdit && formData.auditStatus == 1) {
        this.$confirm('请按照审核提示修改文章！重新提交博客后，将会被重新审核，是否继续? ', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.dialogFormVisible = true
        }).catch(() => {
          this.$commonUtil.message.info('已取消修改')
        })
      } else {
        this.dialogFormVisible = true
      }
    },
    // 关闭模态框回调函数
    beforeClose: function() {
      this.dialogFormVisible = false
      this.dialogUploadBlogVisible = false
      this.selectedTask(null)
    },
    /********************** 创作相关结束 ********************/

    /********************** 创作相关 ********************/
    uploadBlog: function() {
      this.dialogUploadBlogVisible = true
    },
    /********************** 创作相关结束 ********************/

    /********************** 创作相关 ********************/
    createQuestion: function(isEdit, formData) {
      this.isEdit = isEdit
      if (isEdit) {
        this.questionFormData = formData
      }
      if (!this.isLogin) {
        this.setLoginMessage(Math.random())
        return
      }
      this.questionDialogFormVisible = true
    },
    createLive: function(isEdit, formData) {
      this.isEdit = isEdit
      if (isEdit) {
        this.questionFormData = formData
      }
      if (!this.isLogin) {
        this.setLoginMessage(Math.random())
        return
      }
      this.liveDialogFormVisible = true
    },
    // 关闭模态框回调函数
    questionBeforeClose: function() {
      this.questionDialogFormVisible = false
      this.selectedTask(null)
    },
    liveBeforeClose: function() {
      this.isEdit = false
      this.liveDialogFormVisible = false
      this.selectedTask(null)
    },

    setUserReceiveCommentCount: function() {
      getUserReceiveNoticeCount().then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          this.userReceiveCommentCount = response.data.userReceiveCommentCount
          this.userReceivePraiseCount = response.data.userReceivePraiseCount
          this.userReceiveSystemCount = response.data.userReceiveSystemCount
          this.userReceiveWatchCount = response.data.userReceiveWatchCount
          this.userReceiveMessageCount = response.data.userReceiveMessageCount
        }
      })
    },
    getKeyword: function() {
      let tempValue = decodeURI(this.getUrllets()['keyword'])
      if (
        tempValue === null
          || tempValue == undefined
          || tempValue == 'undefined'
      ) {
        return false
      } else {
        this.keyword = tempValue
      }
    },
    /**
       * 获取当前所在页面的标题
       * @returns {{}}
       */
    getCurrentPageTitle: function() {
      // 判断是否是通过参数传递的title
      let title = this.getUrllets()['title']
      ////console.log('获取url', title)
      if (title) {
        // 如果是通过参数传递，那么直接显示
        this.saveTitle = title
      } else {
        // 从url中获取title
        let test = window.location.href
        let start = 0
        let end = test.length
        for (let i = 0; i < test.length; i++) {
          if (test[i] == '/') {
            start = i
          }
          if (test[i] == '?' && i > start) {
            end = i
          }
        }
        let result = test.substring(start, end)
        this.saveTitle = result
      }
    },
    /**
       * 获取网站配置
        */
    getWebConfigInfo: function() {
      let webConfigData = this.$store.state.app.webConfigData
      if (webConfigData.createTime) {
        this.contact = webConfigData
        this.mailto = 'mailto:' + this.contact.email
        this.openComment = webConfigData.openComment
        document.title = this.info.title
        this.showCreateBlog = webConfigData.openCreateBlog == '1' ? true : false
        this.showCreateQuestion = webConfigData.openCreateQuestion == '1' ? true : false
        this.showChat = webConfigData.openChat == '1' ? true : false
      } else {
        getWebConfig().then(response => {
          if (response.code == this.$ECode.SUCCESS) {
            this.info = response.data
            // 存储在Vuex中
            this.setWebConfigData(response.data)
            this.openComment = this.info.openComment
            document.title = this.info.title
            this.showCreateBlog = this.info.openCreateBlog == '1' ? true : false
            this.showCreateQuestion = this.info.openCreateQuestion == '1' ? true : false
            this.showChat = this.info.openChat == '1' ? true : false
          }
        })
      }
    },
    /**
       * 截取URL中的参数
       * @returns {{}}
       */
    getUrllets: function() {
      let lets = {}
      let url = window.location.href
      url = decodeURI(url)
      url.replace(
        /[?&]+([^=&]+)=([^&#]*)/gi,
        (m, key, value) => {
          lets[key] = value
        },
      )
      return lets
    },
    clickSearchIco: function() {
      if (this.keyword != '') {
        this.search()
      }
      this.showSearch = !this.showSearch
      //获取焦点
      this.$refs.searchInput.focus()
      this.dialogHotSearchVisible = false
    },
    openHead: function() {
      this.showHead = !this.showHead
    },
    returnTop: function() {
      window.scrollTo(0, 0)
    },
    userLogin: function() {
      if (this.isMiniprogram) {
        return this.$message({
          type: 'warning',
          message: '请先返回小程序，重新跳转七里香页面进行登录',
        })
      }
      this.setLoginMessage(Math.random())
    },
    seeStatistics: function(item) {
      this.showStatistics = true
      this.StatisticsForm = item

    },
    userLogout: function() {
      deleteUserAccessToken(getCookie('token'))
      delCookie('token')
      let url = window.parent.location.href
      const pathname = window.parent.location.pathname
      let haveToken = url.indexOf('token=')
      let haveCode = url.indexOf('code=')
      if (haveCode == -1) {
        if (haveToken == -1) {
          if (pathname.includes('/workTable')) {
            window.location.href = window.parent.location.origin
          } else {
            window.location.reload()
          }
        } else {
          let haveOnyToken = url.indexOf('?token')
          let list
          if (haveOnyToken == -1) {
            list = url.split('&token')
          } else {
            list = url.split('?token')
          }
          this.isLogin = false
          window.location.href = list[0]
          let userInfo = {}
          this.setUserInfo(userInfo)
        }

      } else {
        // let list = url.split('?code')
        this.isLogin = false
        window.location.href = window.parent.location.origin
        let userInfo = {}
        this.setUserInfo(userInfo)
      }

    },

    // 点击头像触发的动作
    handleCommand(command) {
      switch (command) {
      case 'logout' : {
        this.userLogout()
        break }
      case 'login' : {
        this.userLogin()
        break }
      case 'goUserCenter' : {
        this.$router.push({ path: '/userCenter', query: { userUid: this.userInfo.uid } })
        break }
      case 'goUserInfo' : {
        // 打开抽屉
        this.drawer = true
        break }
      case 'createBlog' : {
        // 创作博客
        this.createBlog(false, null)
        break }
      case 'live' : {
        // 创建直播
        this.createLive(false, null)
        break }
      case 'createQuestion' : {
        this.createQuestion(false, null)
        break }
      case 'uploadBlog' : {
        this.uploadBlog()
        break }
      case 'userMoment': {
        this.$router.push({ path: '/moment' })
        break }
      case 'comment' : {
        if (!this.isLogin) {
          this.setLoginMessage(Math.random())
          return
        }
        // 判断用户是否未读的回复
        if (this.userReceiveCommentCount > 0) {
          // 设置已阅读
          let params = {}
          params.noticeType = '1'
          this.readNotice()
          this.userReceiveCommentCount = 0
        }
        this.setNoticeType('1')
        this.$router.push({ path: '/notice', query: { noticeType: '1' } })
        break }
      case 'watch' : {
        if (!this.isLogin) {
          this.setLoginMessage(Math.random())
          return
        }
        // 设置已阅读
        let params = {}
        params.noticeType = '2'
        if (this.userReceiveWatchCount > 0) {
          this.readNotice()
          this.userReceiveWatchCount = 0
        }
        this.setNoticeType('2')
        this.$router.push({ path: '/notice', query: { noticeType: '2' } })
        break }
      case 'praise' : {
        if (!this.isLogin) {
          this.setLoginMessage(Math.random())
          return
        }
        // 设置已阅读
        let params = {}
        params.noticeType = '3'
        if (this.userReceivePraiseCount > 0) {
          this.readNotice()
          this.userReceivePraiseCount = 0
        }
        this.setNoticeType('3')
        this.$router.push({ path: '/notice', query: { noticeType: '3' } })
        break }
      case 'system' : {
        if (!this.isLogin) {
          this.setLoginMessage(Math.random())
          return
        }
        // 设置已阅读
        let params = {}
        params.noticeType = '4'
        if (this.userReceiveSystemCount > 0) {
          this.readNotice()
          this.userReceiveSystemCount = 0
        }
        this.setNoticeType('4')
        this.$router.push({ path: '/notice', query: { noticeType: '4' } })
        break }

      default:break
      }
    },
    readNotice() {
      readUserReceiveNoticeCount().then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          // 阅读成功
          this.userReceiveSystemCount = 0
        }
      })
    },
    closeLoginBox: function() {
      this.showLogin = false
      this.isBindBack = false
      this.isFromMiniprogram = false
      if (window.location.search) {
        let url = new URL(location.href)
        const urlSearchParam = url.searchParams
        deleteUrlParams(urlSearchParam)
        this.$router.replace(url.pathname + url.search)
      }

    },
    closeLogin: function() {
      this.showLoginInfo = false
    },
    // 获取浏览器类型, 用于兼容处理
    getBrowser() {
      let sUsrAg = navigator.userAgent
      if (sUsrAg.indexOf('Firefox') > -1) {
        this.browserFlag = 2
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
      } else if (sUsrAg.indexOf('Chrome') > -1) {
        this.browserFlag = 1
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
      }
    },
    //更换手机号
    changeMobile() {

      this.showMobile = true
      this.showValidCode = true
      this.showPassword = false
      this.showCancleBtn = true
      this.showSaveBtn = true
      this.getVerifyCodeBtnDisabled = false
      this.getVerifyCodeBtnText = '发送验证码'
      this.timeInterval && clearInterval(this.timeInterval)
      this.mobileInfo = {}
      this.$nextTick(() => {
        this.$refs.mobileInfo.clearValidate()
      })

    },
    //修改密码
    changePassword() {
      this.getVerifyCodeBtnDisabled = false
      this.getVerifyCodeBtnText = '发送验证码'
      this.timeInterval && clearInterval(this.timeInterval)
      this.showMobile = false
      this.showValidCode = true
      this.showPassword = true
      this.showCancleBtn = true
      this.showSaveBtn = true
      this.mobileInfo = {}
      this.$nextTick(() => {
        this.$refs.mobileInfo.clearValidate()
      })

    },
    //取消按钮
    cancleMobile() {
      this.showMobile = false
      this.showValidCode = false
      this.showPassword = false
      this.showCancleBtn = false
      this.showSaveBtn = false
      this.getVerifyCodeBtnDisabled = false
      this.getVerifyCodeBtnText = '发送验证码'
      this.timeInterval && clearInterval(this.timeInterval)
      this.mobileInfo = {}
      this.$nextTick(() => {
        this.$refs.mobileInfo.clearValidate()
      })
    },
    //不换绑
    closeChangeMobileBind() {
      this.showLogin = true
      this.isBindBack = true

      let url = new URL(location.href)
      let urlSearchParams = url.searchParams
      this.loginData = {
        mobile: urlSearchParams.get('mobile'),
        preStatusIsPassword: urlSearchParams.get('preStatusIsPassword'),

      }
      deleteUrlParams(urlSearchParams)
      this.$router.replace(url.pathname + url.search)

    },
  },
}
</script>

<style>
  @import "../assets/css/emoji.css";
  .emoji-panel-btn:hover {
    opacity: 0.8;
  }
  .emoji-item-common {
    background: url("../assets/img/emoji_sprite.png");
    display: inline-block;
  }
  /* .emoji-item-common:hover {
    cursor: pointer;
  } */
  .emoji-size-small {

    zoom: 0.3;
  }

</style>

<style lang="less" scoped>
  .header-navigation{
    box-shadow: 0px 2px 0 0 #ededee;
  }
  .logoPhoto{
    width:114px;
    ::v-deep{
      img{
        width: 100%!important;
      }

    }


  }
  .login-work-table{
    width: 61px;
    height: 16px;
    opacity: 1;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 400;
    font-family: "PingFang SC";
    text-align: left;
    padding-left: 10px;
    border-left: 2px solid #7c7c7c;
    display: inline-block;
    overflow: hidden;
    line-height: 20px;
    margin-top: 22px;
  }
  .to-home-icon{
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: rgba(242,242,242,1);
    vertical-align: middle;
    margin-left:10px;
    img{
      width: 16;
      margin: 5px auto;
    }
  }
  .to-home-text{
    display: inline-block !important;
    float: none !important;
    padding-left: 8px !important;
    coloR:#333
  }
  #to-home{
    position: fixed;
    left:20px;
    max-width: 1500px;
    overflow: hidden;
  }

  .header-right{
    position: absolute;
    right: 60px;
    top: 0;
    display: flex;
    justify-content: flex-end;
    align-items: start;
  }
.over-hide{
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
  .pointer{
    cursor: pointer;
  }
  .createStatus {
    position: relative;
    text-align: right;
    &:before {
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #CCF3DD;
      content: '';
      right: 70px;
      top: 7px;
    }
  }
  .joinStatus {
    position: relative;
    text-align: right;
    &:before {
    // top: 5px;
    // left: -15px;
      position: absolute;
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #FBE8D5;
      content: '';
      right: 70px;
      top: 7px;
    }
  }
  .displayfex{
    display: flex;
    justify-content: space-between;
  }

  .validCode{
    width:calc(100% - 117px)
  }

  .lmname{
    cursor: pointer;
  }
  .isEnd {
    float: left;
    width: 100%;
    height: 80px;
    text-align: center;
  }
  .loadContent {
    border-radius: 25px;
    width: 120px;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    margin: 0 auto;
    color: aliceblue;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.8);
  }

  .createBlog {
    position: absolute;
    top: 16px;
    right: -20px;
    width:72px;
  }

  .el-tag {
    height: 25px;
    line-height: 25px;
    margin-left: 6px;
  }
  #starlist li .title {
    color: #007fff;
  }

  .searchbox {
    display: flex;
    position: unset;
    flex-direction: column;
    margin-right: 46px;
  }

  .create {
    width: 68px;
    margin-right: 2px;
    button{
      width: 68px;
    }
  }
.workTable-btn{
   width: 68px;
    margin-right: 5px;
    margin-left: 10px;
}
  .notice {
    width: 35px;
    height: 35px;
    position: absolute;
    font-size: 24px;
    margin-top: 2px;
    right: 155px;
    cursor: pointer;
  }
 .noLoginText{
    vertical-align: top;
    line-height: 58px;
    padding-left: 4px;

 }
  .userInfoAvatar {
    width: 78px;
    .el-icon-user-solid{
      font-size: 24px;
      color:#BFBFBF;
      vertical-align: super;

    }
    ::v-deep .c-avatar{
      vertical-align: middle;

    }
  }
  .nologin{
     width: 78px;
  }


  .userInfoAvatar img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }


  .el-icon-message-solid:hover {
    color: #00ccff;
  }


  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    margin: 0, 0, 0, 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .imgBody {
    width: 100px;
    height: 100px;
    border: solid 2px #ffffff;
    float: left;
    position: relative;
  }
  .imgBody img {
    width: 100px;
    height: 100px;
  }
  .uploadImgBody {
    margin-left: 5px;
    width: 100px;
    height: 100px;
    border: dashed 1px #c0c0c0;
    float: left;
    position: relative;
  }
  .uploadImgBody :hover {
    border: dashed 1px #00ccff;
  }
  .inputClass {
    position: absolute;
  }

  .commentList {
    width: 100%;
    margin: 0 auto;
  }
  .commentList .p1 {
    float: left;
  }
  .commentList .left {
    display: inline-block;
    width: 10%;
    height: 100%;
  }
  .commentList .left img {
    margin: 0 auto;
    width: 100%;
    border-radius: 50%;
  }
  .commentList .right {
    display: inline-block;
    width: 85%;
    margin-left: 5px;
  }
  .commentList .rightTop {
    height: 30px;
  }
  .commentList .rightTop .userName {
    color: #303133;
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .commentList .rightTop .timeAgo {
    color: #909399;
    margin-left: 10px;
    font-size: 15px;
  }
  .commentList .rightCenter {
    margin-left: 20px;
    line-height: 30px;
    min-height: 60px;
    word-break: break-all;

  }


  .feedbackCard .item .title {
    display: inline-block;
    width: 70px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  .feedbackCard .item .content {
    display: inline-block;
    width: 240px;
    margin-bottom: 5px;
  }
  .myLive{
    margin-bottom: 20px;
    margin-right: 20px;
    display: block;

    .ck-content{
      color: #8F8F8F;
      margin: 16px 0;
    }
  }
  .myLiveTitle{
    margin:0 -20px;
    display: flex;
    align-items: center;
    .status{
      width: 56px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: #666;
      text-align: center;
      background: #F5F5F5;
      border: 1px solid #ECECEC;
      border-radius: 0 100px 100px 0;
      margin-right: 10px;
      flex: none;
    }
    .continue{
      color: #00C257;
      border: 1px solid #DBF6E6;
      background: #F5FDF8;
    }
    .noBegin{
      color: #EC7700;
      border: 1px solid #FCECDC;
      background: #FEFAF5;
    }
    .livetext{
      overflow: hidden;
      font-weight: bold;
      cursor: pointer;
      p{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

  }
  .livebtn{
    ::v-deep  .el-button{
      padding: 10px;
    }
    .Fright{
      float: right;
    }
  }

  .myArticle .blogpic{
    float: left;
    max-height: 170px;
    margin-right: 20px;
    display: block;
    overflow: hidden;
  }
  .myArticle .blogpic img{
    width: 100%;
    max-height: 70px;
    -webkit-transition: all 0.6s ease;
    transition: all 0.6s ease;
    margin-bottom: 10px;
  }
  .myArticle .bloginfo {
    margin-top: 5px;
  }
  .myArticle .operation {
    float: right;
    margin-top: 3px;
    margin-left: 20px;
    // height: 40px;
  }
  .search_ico2 { width: 60px; height: 60px; display: block; position: absolute; right: 0; top: 15px; padding: 0; margin: 0; line-height: 60px; cursor: pointer; }
  .personCenter{
    ::v-deep .el-tabs__item.is-left {
      text-align: left !important;
  }
  .blogtext{
    max-height: 42px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis
  }

  .filter-container {
    ::v-deep .el-select .el-input {
      position: relative!important;
    }

  }
}
  .userInfoAvatar .el-icon-user-solid{
    display: none;
  }
  .rolegroup{
    ::v-deep .el-radio{

      margin: 0 10px 10px 0!important;
    }
  }
.absoluteIcon{
  ::v-deep.svg-icon{
    width: 1.2em!important;
    height: 1.2em!important;
  }
}
.change-mobile{
  padding-left: 20px;
  cursor: pointer;
  color: #007fff;
}

  @media only screen and (min-width: 1346px) and (max-width: 1432px) {

    .header-right {
      right: 110px;
    }
    .userInfoAvatar .el-icon-user-solid{
      display: none;
    }
  }

  @media only screen and (min-width: 1135px) and (max-width: 1345px) {

    .notice {
      right: 400px;
    }
    .header-right {
      right: 281px;
    }
    .logo{
      text-align: left;
      padding-left: 51px;
    }
    .logo img{
      width: 25px;
      height: 25px;
    }

    .search_open{
      width: 203px;
    }
    .search_open.search_bar .input{
      width: 200px;
    }
    .userInfoAvatar .el-icon-user-solid{
      display: none;
    }
  }

   @media only screen and (min-width: 751px) and (max-width: 1134px) {
    .searchbox {
      position: unset;
      margin: 0;
    }
    .create,.workTable-btn {
      display: none;
    }

    .header-right  {
      right: 10px;
    }
    .noLoginText{
      display: none;

    }
    .logo{
      text-align: left;
      padding-left: 51px;
    }
    .logo img{
      width: 25px;
      height: 25px;
    }
    .logo >a{
      display: none;
    }
    .search_open{
      width: 203px;
    }
    .search_open.search_bar .input{
      width: 200px;
    }
    .userInfoAvatar .el-icon-user-solid{
      display: inline;
    }
  }

  @media only screen and (max-width: 750px) {
    .searchbox {
      position: unset;
      margin: 0;
    }
    #mnavh {
      z-index: 20;
    }
    #starlist{
      position: fixed;
      z-index: 15;
    }
     #to-home{
      position: fixed;
      left: 17px;
      top: 0;
      .work-table-logo{
        display: none;
      }
    }
    .workTable-btn{
      display: none;
    }
    .create {
      display: none;
    }
    .notice {
      right: 55px;
      top: -2px;
    }
    .header-right {
      right: 10px;
    }
    .noLoginText{
      display: none;

    }
    .logo{
      text-align: left;
      padding-left: 51px;
      display: none;
    }
    .logo img{
      width: 25px;
      height: 25px;
    }
    .logo >a{
      display: none;
    }
    .search_open{
      width: 203px;
    }
    .search_open.search_bar .input{
      width: 200px;
    }
    .cd-is-visible{
      right: 20px !important;
    }
    .validCode{
      width:100%;
      margin-bottom: 10px;
    }
    .bloginfo li{
      margin-left: 0!important;
    }
    .filter-container {
      ::v-deep .el-select {
        margin-top: 10px;
      }
    }
    .userInfoAvatar .el-icon-user-solid{
      display: inline;
      line-height: 60px;
    }
  }

  .control-wrap {
    display: flex;
    justify-content: flex-end;
  }

</style>

<style lang="scss">

.codeContent {
  max-width: 400px;
  margin: 0 auto;
  padding-top: 25vh;
}
.code-copy-added {
  background-color: #282c34;
  color: white;
  padding: 2px 2px;
  margin: 10px 0;
  text-align: left;
  border-radius: 3px;
  position: relative;
}
.code-copy-added:hover .copy-btn {
  opacity: 1;
}

</style>
