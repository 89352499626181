<template>
  <div>
    <!-- 添加或修改对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      :before-close="beforeClose"
      width="1000px"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-row>
          <el-col :span="16">
            <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
              <el-input v-model="form.title" auto-complete="off" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="6.5">
            <el-form-item label="标签" :label-width="formLabelWidth" prop="tagValue">
              <el-select
                v-model="form.tagValue"
                multiple
                :multiple-limit="2"
                size="small"
                placeholder="请选择"
                style="width:210px"
                filterable
              >
                <el-option
                  v-for="item in tagData"
                  :key="item.uid"
                  :label="item.name"
                  :value="item.uid"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6.5">
            <el-form-item label="任务" :label-width="formLabelWidth" prop="taskId">
              <el-select
                v-model="form.taskId"
                size="small"
                style="width:250px"
                filterable
                clearable
                remote
                placeholder="请输入关键词"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option
                  v-for="item in taskListData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="内容" :label-width="formLabelWidth" prop="content">
          <CKEditor
            ref="editor"
            :content="form.content"
            :height="300"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CKEditor from '../CKEditor'
import { getListByDictTypeList } from '@/api/sysDictData'
import { getQuestionTagList, addQuestion, editQuestion, getQuestionTemplateList } from '@/api/question'
import { getTaskList, getDetailPageTaskList } from '@/utils/taskUtils'
export default {
  components: {
    CKEditor,
  },
  props: { visible: Boolean, isEdit: Boolean, formData: Object },
  data() {
    return {
      multipleSelection: [], //多选，用于批量删除
      tagOptions: [], //标签候选框
      loading: false, //搜索框加载状态
      uploadLoading: null, //文件上传loading
      CKEditorData: null,
      tableData: [], //问答数据
      tagData: [], //标签数据
      templateData: [],
      templateValue: '',
      currentPage: 1,
      pageSize: 10,
      total: 0, //总数量
      title: '增加问答',
      dialogFormVisible: this.visible, //控制弹出框
      subjectVisible: false, // 是否显示专题
      formLabelWidth: '120px',
      lineLabelWidth: '120px', //一行的间隔数
      maxLineLabelWidth: '100px',
      photoVisible: false, //控制图片选择器的显示
      isFirstPhotoVisible: true, // 图片选择器是否首次显示【用于懒加载】
      photoList: [],
      fileIds: '',
      isSubmitOp: false,
      icon: false, //控制删除图标的显示
      interval: null, //定义触发器
      isChange: false, // 表单内容是否改变
      changeCount: 0, // 改变计数器
      blogPublishDictList: [], //是否字典
      questionStatusDictList: [], // 问答状态字典
      openDictList: [], // 是否启动字典
      blogPublishDefault: null, //问答发布默认值
      openDefault: null, // 是否开启评论默认值
      questionStatusDefault: null, // 问答状态默认值
      fileList: [],
      localUploadVisible: false,
      systemConfig: {}, // 系统配置
      orderByDescColumn: '', // 降序字段
      orderByAscColumn: '', // 升序字段
      form: {
        uid: null,
        title: null,
        // summary: null,
        content: null,
        questionTagUid: null,
        fileUid: null,
        isPublish: null,
        author: null, //作者
        clickCount: 0,
        tagValue: [], //保存选中标签id(编辑时)
        taskId: 0,
      },
      taskListData: [], //任务列表
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' },
          { min: 2, max: 50, message: '长度在3到25个字符' },
        ],
        isPublish: [
          { required: true, message: '发布字段不能为空', trigger: 'blur' },
          { pattern: /^[0-9]\d*$/, message: '发布字段只能为自然数' },
        ],
        tagValue: [
          { required: true, type: 'array', message: '问答标签不能为空', trigger: 'blur' },
        ],
        // questionTemplateUid: [
        //   { required: true, message: '问答模板不能为空', trigger: 'blur' },
        // ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'change' },
        ],
        outsideLink: [
          { required: true, message: '外链地址不能为空', trigger: 'blur' },
          { pattern: /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/, message: '请输入有效的URL' },
        ],
      },
    }
  },
  watch: {
    visible: function() {
      this.dialogFormVisible = this.visible
    },
  },
  created() {
    this.questionTagList()
    this.questionTemplateList()
  },
  mounted() {
    this.taskListData = []
    this.getDictList()
  },
  methods: {
    /**
     * 字典查询
     */
    getDictList: function() {
      let that = this
      var dictTypeList =  ['sys_publish_status', 'sys_normal_disable', 'sys_question_status']
      getListByDictTypeList(dictTypeList).then(async response => {
        if (response.code == this.$ECode.SUCCESS) {
          var dictMap = response.data
          this.blogPublishDictList = dictMap.sys_publish_status.list
          this.openDictList = dictMap.sys_normal_disable.list
          this.questionStatusDictList = dictMap.sys_question_status.list

          if (dictMap.sys_publish_status.defaultValue) {
            this.blogPublishDefault = dictMap.sys_publish_status.defaultValue
          }
          if (dictMap.sys_normal_disable.defaultValue) {
            this.openDefault = dictMap.sys_normal_disable.defaultValue
          }
          if (dictMap.sys_question_status.defaultValue) {
            this.questionStatusDefault = dictMap.sys_question_status.defaultValue
          }
        }

        if (this.isEdit) {
          this.form = { ...this.formData, tagValue: [] }
          const taskItem = await getDetailPageTaskList(this.formData.uid)
          if (taskItem) {
            this.taskListData = [taskItem]
            this.form = { ...this.form, taskId: taskItem.id }
          }
          setTimeout(() => {
            that.$refs.editor.setData(that.form.content) //设置富文本内容
          }, 100)
          that.form.tagValue = []
          if (this.form.questionTagList) {
            let json = this.form.questionTagList
            for (var i = 0, l = json.length; i < l; i++) {
              if (json[i] !== null) {
                that.form.tagValue.push(json[i]['uid'])
              }
            }
          }
        } else {
          this.form = this.getFormObject()
          if (this.$store.state.app.selectedTask) {
            this.form = { ...this.form, taskId: this.$store.state.app.selectedTask.id }
            this.taskListData = [{ id: this.$store.state.app.selectedTask.id, name: this.$store.state.app.selectedTask.name }]
          }
        }
      })

    },
    getFormObject: function() {
      var formObject = {
        uid: null,
        title: null,
        // summary: null,
        content: null,
        tagUid: null,
        fileUid: null,
        isPublish: this.blogPublishDefault, //是否发布
        // questionTemplateUid: '', //问答模板UID
        type: this.blogTypeDefault, // 文章类型
        author: null, //作者
        openComment: this.openDefault, // 是否启动
        taskId: null,
      }
      return formObject
    },
    submitForm: function() {
      this.form.content = this.$refs.editor.getData() //获取CKEditor中的内容
      this.form.questionTagUid = this.form.tagValue ? this.form.tagValue.join(',') : ''
      this.isSubmitOp = true
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return
        }

        if (this.isEdit) {
          editQuestion(this.form).then(response => {
            if (response.code == this.$ECode.SUCCESS) {
              this.$commonUtil.message.success(response.message)
              // this.$emit("beforeClose", "");
              setTimeout(() => {
                location.reload()
              }, 500)
            } else {
              this.$commonUtil.message.error(response.message)
            }
          })

        } else {
          addQuestion(this.form).then(response => {
            if (response.code == this.$ECode.SUCCESS) {
              this.$commonUtil.message.success(response.message)
              // this.$emit("beforeClose", "");
              setTimeout(() => {
                location.reload()
              }, 500)
            } else {
              this.$commonUtil.message.error(response.message)
            }
          })
        }

      })
    },
    questionTemplateList: function() {
      let params = {}
      params.pageSize = 500
      params.currentPage = 1
      getQuestionTemplateList(params).then(response => {
        this.templateData = response.data.records
      })
    },
    templateChange: function(templateUid) {
      let that = this
      let templateData = this.templateData
      for (let a = 0; a < templateData.length; a++) {
        if (templateData[a].uid == templateUid) {
          this.$nextTick(() => {
            //DOM现在更新了
            that.form.content = templateData[a].content
            that.$refs.editor.setData(that.form.content) //设置富文本内容
          })
          break
        }
      }
    },
    questionTagList: function() {
      let tagParams = {}
      tagParams.pageSize = 500
      tagParams.currentPage = 1
      getQuestionTagList(tagParams).then(response => {
        this.tagData = response.data.records
        this.tagOptions = response.data.records
      })
    },
    async remoteMethod(v) {
      if (v) {
        if (this.isEdit) {
          this.taskListData = await getTaskList(v, 3, this.formData.createTime)
        } else {
          this.taskListData = await getTaskList(v, 3)
        }
      }

    },
    beforeUnload: function(e) {
      if (this.isSubmitOp) {
        return
      }
      let event = e || window.event
      // 兼容IE8和Firefox 4之前的版本
      if (event) {
        event.returnValue = '关闭提示'
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return '关闭提示'
    },
    cancel: function() {
      this.$emit('beforeClose', '')
    },
    // 关闭时的回调
    beforeClose(done) {
      //取消时，开始状态
      this.$emit('beforeClose', '')
      done()
    },

  },
}
</script>

<style scoped>
.el-form {
  overflow: hidden;
}
</style>
