<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getJsApiConfig, getSharePicAndDesc } from '@/api/share';
export default {
  name: 'App',
  data() {
    return {
      shareInfo: {},
      wxShareUrlMap: {
        Info: 1, QInfo: 2, MomentInfo: 4, Live: 11,
      },
    };
  },
  watch: {
    $route: {
      async handler(to) {
        if (Object.prototype.hasOwnProperty.call(this.wxShareUrlMap, to.name)) {
          await this.getSharePicAndDesc();
          await this.getJsApiConfig();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getSharePicAndDesc() {
      const routeName = this.$route.name;
      const resourceUid = Object.values(this.$route.params)[0];
      const resourceType = this.wxShareUrlMap[routeName];
      getSharePicAndDesc({ resourceType, resourceUid }).then((res) => {
        this.shareInfo = res.data;
      });
    },
    getJsApiConfig() {
      getJsApiConfig({ url: window.location.href }).then((res) => {
        const config = res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: config.appId, // 必填，公众号的唯一标识
          timestamp: config.timestamp, // 必填，生成签名的时间戳
          nonceStr: config.noncestr, // 必填，生成签名的随机串
          signature: config.signature, // 必填，签名，见附录1
          jsApiList: [
            'updateAppMessageShareData', 'updateTimelineShareData',
          ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });


        wx.ready(() => {
          const shareConfig = this.shareInfo; // 自己定义的分享信息
          const title = shareConfig.title || '';
          const imgUrl = `${location.origin}/static/images/share.png`;
          const desc = `来源: 七里香大健康云\n${shareConfig.description}` || '';
          const link = window.location.href;

          // 分享朋友
          wx.updateAppMessageShareData({
            title, // 分享标题
            desc, // 分享描述
            link, // 分享链接
            imgUrl, // 分享图标
            success() {
            // 用户确认分享后执行的回调函数
              console.log('分享朋友 success');
            },
          });

          // 分享朋友圈
          wx.updateTimelineShareData({
            title,
            link, // 分享链接
            imgUrl, // 分享图标
            success() {
            // 用户确认分享后执行的回调函数
              console.log('分享朋友圈 success');
            },
          });
        });

        wx.error((err) => {
          console.log('----wx error----: ', err);
        });
      });
    },
  },
};
</script>
