<template>
  <div>
    <div v-if="showLogin == true" class="box loginBox">
      <div class="loginWrap">
        <div class="loginright">
          <div class="title">
            <span class="t1">
              请完善账户信息
            </span>
          </div>


          <el-form
            ref="loginForm"
            :label-position="labelPosition"
            :rules="loginRules"
            :model="loginForm"
            class="formClass"
            label-width="0"
          >
            <div v-if="showPasswordLogin" class="passwordLogin">
              <el-form-item prop="mobile" label="">
                <el-input
                  v-model="loginForm.mobile"
                  :disabled="!!wechatBindMobile"
                  placeholder="请输入手机号"
                  @input="inputBlur"
                />
              </el-form-item>
              <el-form-item prop="validCode" label="">
                <div class="validCodeWrap">
                  <el-input
                    v-model="loginForm.validCode"
                    placeholder="请输入验证码"
                    class="validCodeInput"
                  />

                  <el-button
                    type="primary"
                    class="CodeBtn"
                    :disabled="getVerifyCodeBtnDisabled"
                    @click.prevent="getVerifyCode"
                  >
                    {{ getVerifyCodeBtnText }}
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item prop="password" label="" class="relative">
                <el-input
                  v-model="loginForm.password"
                  placeholder="请设置至少6位数密码"
                  auto-complete="new-password"
                  :type="passwordEye? 'password':'text'"
                  @keyup.native="()=> {loginForm.password= loginForm.password.toString().replace(/(^\s*)|(\s*$)/g, '')}"
                >
                  <el-button slot="append" class="absoluteIcon" @click.prevent="passwordEye=!passwordEye">
                    <svg-icon class="icon" :icon-name="passwordEye?'eye-close':'eye'" />
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item prop="confirmPassword" label="">
                <el-input
                  v-model="loginForm.confirmPassword"
                  :type="passwordsEye?'password':'text'"
                  auto-complete="new-password"
                  placeholder="请再次输入密码"
                  @keyup.native="()=> {loginForm.confirmPassword= loginForm.confirmPassword.toString().replace(/(^\s*)|(\s*$)/g, '')}"
                >
                  <el-button slot="append" class="absoluteIcon" @click.prevent="passwordsEye=!passwordsEye">
                    <svg-icon class="icon" :icon-name="passwordsEye?'eye-close':'eye'" />
                  </el-button>
                </el-input>
              </el-form-item>
              <el-form-item prop="identity" label="">
                <el-radio-group v-model="loginForm.identity" size="medium" class="rolegroup">
                  <el-radio :label="1" border>
                    医生
                  </el-radio>
                  <el-radio :label="2" border>
                    推广人员
                  </el-radio>
                  <el-radio :label="3" border>
                    普通用户
                  </el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="" style="margin-bottom:0">
                <el-checkbox v-model="loginForm.checked" />
                我已阅读并接受七里香
                <el-link type="primary" target="_blank" @click="seeAgreement">
                  《用户协议》
                </el-link>
                <el-link type="primary" target="_blank" @click="seePrivacy">
                  《隐私政策》
                </el-link>
              </el-form-item>
              <el-row class="btn">
                <el-button
                  class="loginBtn"
                  type="primary"
                  :disabled="!(loginForm.validCode &&loginForm.mobile&&loginForm.checked&&loginForm.confirmPassword&&loginForm.password)||submitBtnDisabledFlag"
                  @click="startLogin"
                >
                  确定
                </el-button>
              </el-row>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="mask" />
    <Agree ref="agree" />
    <Privacy ref="privacy" />
  </div>
</template>

<script>
import { bindMobile, getMsgCode, getUserByMobile, getCompleteLogin } from '@/api/user'
import { mapMutations } from 'vuex'
import loginLeftImg from '@/assets/img/login.png'
import Agree from '../Agreement/Agree'
import Privacy from '../Agreement/Privacy'
import encryption from '@/utils/encryption'
import { Loading } from 'element-ui'
export default {
  name: 'LoginInfo',
  components: { Agree, Privacy },
  props: { openid: String, identity: Number, wechatBindMobile: String, isMiniprogram: Boolean, accountId: String },
  data() {

    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value === this.loginForm.password) {
        callback()
      } else {
        callback(new Error('两次输入密码不一致!'))
      }
    }

    return {
      loading: null,

      option: {
        fullscreen: true,
        lock: true,
      },
      webConfig: {},
      timer: null,
      vueMoguWebUrl: process.env.VUE_APP_VUE_MOGU_WEB,
      showPasswordLogin: true, // 是否显示账号密码登录
      wechatOrCode: '', // 微信公众号登录二维码
      ticket: '', // 用户的票券
      wechatLoginKey: '', // 验证码
      interval: null,
      // 显示登录页面
      showLogin: true,
      isLogin: false,
      table: false,
      dialog: false,
      labelPosition: 'right',
      loginForm: {
        userName: '',
        validCode: '',
        checked: false,
        identity: 3,
        password: '',
        confirmPassword: '',
      },
      // 登录类别
      loginType: {
        password: true,
        gitee: true,
        github: true,
        qq: true,
        wechat: true,
      },
      passwordEye: true,
      passwordsEye: true,
      getVerifyCodeBtnDisabled: true, //获取验证码按钮不可点击
      getVerifyCodeBtnText: '发送验证码',
      loginLeftImg: loginLeftImg,
      loginRules: {
        mobile: [
          { required: true, message: '手机号码不能为空', trigger: ['blur', 'change'] },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请填写正确的手机号' },

        ],
        validCode: [
          { required: true, message: '请输入验证码', trigger: ['blur', 'change'] },
        ],
        identity: [
          { required: true, message: '请选择身份', trigger: 'change' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: ['blur', 'change'] },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: ['blur', 'change'] },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: ['blur', 'change'] },
          { validator: validatePass2, trigger: ['blur', 'change'] },
        ],
      },
      submitBtnDisabledFlag: true, //提交按钮置灰标志

    }
  },
  mounted() {
    this.loginForm = {
      userName: '',
      mobile: this.wechatBindMobile || '',
      validCode: '',
      checked: false,
      identity: this.identity?Number(this.identity):3,
      password: '',
      confirmPassword: '',
    }
    this.$nextTick(() => {
      //DOM现在更新了
      if (this.wechatBindMobile) {
        this.getVerifyCodeBtnDisabled = false
        this.submitBtnDisabledFlag = false
      }
    })


  },
  methods: {
    ...mapMutations(['setUserInfo', 'setLoginState']),

    startLogin: function() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          let params = {}
          params.mobile = this.loginForm.mobile
          params.validCode = this.loginForm.validCode
          params.identity = this.loginForm.identity
          params.unionId = this.openid
          params.confirmPassword = encryption(this.loginForm.confirmPassword)
          params.password = encryption(this.loginForm.password)
          let urlHttp = bindMobile
          if (this.isMiniprogram) { //小程序完善信息
            urlHttp = getCompleteLogin
            delete params.unionId
            params.accountId = this.accountId
          }
          urlHttp(params).then(response => {
            if (response.code == this.$ECode.SUCCESS) {
              this.closeLogin()
              if (response.data && response.data.token) {
                this.getVerifyCodeBtnDisabled = false
                this.getVerifyCodeBtnText = '发送验证码'
                this.timer && clearInterval(this.timer)
                this.$emit('getToken', response.data.token)
              }

              this.$message({
                type: 'success',
                message: '信息完善成功！',
              })
            } else {
              this.getVerifyCodeBtnDisabled = false
              this.getVerifyCodeBtnText = '发送验证码'
              this.timer && clearInterval(this.timer)
              this.$message({
                type: 'error',
                message: response.message,
              })
            }
          })
        }
      })
    },
    //协议
    seeAgreement: function() {
      this.$refs.agree.open()
    },
    seePrivacy: function() {
      this.$refs.privacy.open()
    },
    getVerifyCode: function() {
      this.$refs.loginForm.validateField('mobile', (errorMessage) => {

        if (!errorMessage) {
          //当校验通过时，这里面写逻辑代码
          this.getVerifyCodeBtnDisabled = true
          let params = {
            mobile: this.loginForm.mobile,
            sendMobileType: 'LOGIN',
          }
          getMsgCode(params).then(({ code, message }) => {
            if (code == this.$ECode.SUCCESS) {


              let time = 59
              if (this.timer) {
                clearInterval(this.timer)
              }
              this.timer = setInterval(() => {
                if (time > 0) {
                  this.getVerifyCodeBtnText = time + 's后获取'
                  time--
                } else {
                  clearInterval(this.timer)
                  this.getVerifyCodeBtnDisabled = false
                  this.getVerifyCodeBtnText = '发送验证码'

                }
              }, 1000)
            } else {
              this.getVerifyCodeBtnDisabled = false
              this.$message({
                type: 'error',
                message: message,
              })
            }
          })


        }

      })


    },
    //进行【手机号是否存在】的校验
    inputBlur() {
      this.$refs.loginForm.validateField('mobile', (error) => {
        this.getVerifyCodeBtnDisabled = true
        this.submitBtnDisabledFlag = true
        if (!error) {
          //当校验通过时，这里面写逻辑代码
          let param = {
            mobile: this.loginForm.mobile,
          }
          this.loading = Loading.service({
            lock: true,
            text: '查询中……',
          })
          if (this.timer) {
            clearInterval(this.timer)
            this.getVerifyCodeBtnText = '发送验证码'
          }
          getUserByMobile(param).then(res => {
            if (this.loading) {
              this.loading.close()
            }
            if (res.code == this.$ECode.SUCCESS) {
              const data = res.data
              if (data) {
                if (data.unionId && (data.unionId != this.openid)) {
                  let text = '手机号已存在，请使用动态码登录'
                  if (this.isMiniprogram) {
                    text = '手机号已存在，请更换手机号'
                  }
                  this.$message({
                    type: 'error',
                    message: text,
                  })

                } else {
                  this.getVerifyCodeBtnDisabled = false
                  this.submitBtnDisabledFlag = false
                }

              } else {
                this.getVerifyCodeBtnDisabled = false
                this.submitBtnDisabledFlag = false
              }


            } else {
              this.$message({
                type: 'error',
                message: res.message,
              })

            }
          })


        }

      })

    },


    // 获取验证码
    // 刷新微信二维码


    closeLogin: function() {
      if (this.timer) {
        clearInterval(this.timer)
      }
      this.$emit('closeLoginBox', '')
    },
  },
}
</script>


<style>
.box {

  width: 460px;
  height: 560px;
  background: white;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 101; /* 要比遮罩层大 */
  border-radius: 6px
}

.registerBox {
  height: 660px;
}

.box .title {
  height: 48px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  line-height: 48px;
}
.box .title .t2 {
  font-size: 16px;
  /* float: right; */
  margin-right: 6px;
  margin-top: -6px;
  cursor: pointer;
}
.formClass{
  padding:0 20px
}

.box .el-divider--horizontal {
  margin: 12px 0;
}

.box .el-form-item__label {
  margin-left: 10px;
  font-size: 16px;
}


.box .btn {
  text-align: center;
}

.box .loginBtn {
  width: 100%;
}

.box .registerBtn {
  width: 40%;
}

.elRow {
  margin-top: 15px;
  text-align: center;
}

.loginTip {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #bababa;
}

.remarksBox {
  position: fixed;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -50px;
  border: 1px solid red;
  width: 200px;
  height: 100px;
  text-align: center;
  z-index: 101; /* 要比遮罩层大 */
}

/* 遮罩层 */
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

</style>
<style lang="less" scoped>
  .loginWrap{
    display:flex;
    height:100%;
    width:100%;
    .loginLeft {
      height:100%;
      width: 320px;
      border-radius: 6px 0 0 6px;
      background-size:100% 100%;
      background-repeat:no-repeat;
      h2{
        color: #fff;
        padding: 70px 0 7px 16px;
      }
      p{
        padding-left: 16px;
        font-size: 14px;
        color: #fff;
      }
    }
    .loginright {
      height:100%;
      flex: 1;
      ::v-deep .formClass{
        margin:0 30px;
      }
      ::v-deep .el-form-item{
        margin-bottom: 20px;
      }
    }
  }
  .box .title {
    position: relative;
      text-align: center;
      line-height: 48px;
      padding-top: 48px;
      padding-bottom: 25px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      font-size: 24px;
      color: rgba(0,0,0,0.85);
    .t2{
      position: absolute;
      right: -30px;
      top: -30px;
      font-size: 24px;
      color: #fff;

    }
  }
  .validCodeWrap{
    display: flex;
    justify-content: space-between;

  .validCodeInput{
    min-width:0;
    flex:1;
  }
  .CodeBtn{
      width:120px;
      margin-left: 5px;
    }

  }
  .rolegroup{
    display: flex;
    justify-content: space-around;
    ::v-deep .el-radio{
      display: block;
      margin-right: 0;
    }
  }
.skipInfo{
  text-align: center;
  margin-top: 10px;
}
.relative{
  position: relative;
}
.absoluteIcon{
  ::v-deep.svg-icon{
    width: 1.2em!important;
    height: 1.2em!important;
  }

}
@media only screen and (min-width: 300px) and (max-width: 767px) {
    .box {
      width:84%;
      height: 666px;

      .loginLeft{
        display: none;
      }
      .loginright {
        height:100%;
        flex: 1;
        .title{
          margin-top:0 ;
        }
        ::v-deep .formClass{
          margin:0 10px;
        }
      }
    }
    .rolegroup{
      display: block;
      ::v-deep .el-radio{
        margin-left: 0 !important;
        margin-top: 5px;

      }
    }
  }

</style>


