import request from '@/utils/request'

// 任务内容-创建文章列表
export function getCreateArticleList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/createTask/articleList',
    method: 'post',
    data: params,
  })
}
//任务内容-创建直播列表
export function getCreateliveList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/createTask/liveList',
    method: 'post',
    data: params,
  })
}

//任务内容-创建动态列表
export function getCreateMomentList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/createTask/momentList',
    method: 'post',
    data: params,
  })
}

//任务内容-创建问答列表
export function getCreateQuestionList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/createTask/questionList',
    method: 'post',
    data: params,
  })
}


//任务内容-分享文章列表
export function getShareArticleList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/shareTask/articleList',
    method: 'post',
    data: params,
  })
}


//任务内容-分享直播列表
export function getShareLiveList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/shareTask/liveList',
    method: 'post',
    data: params,
  })
}

//任务内容-分享动态列表
export function getShareMomentList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/shareTask/momentList',
    method: 'post',
    data: params,
  })
}

//任务内容-参与答题列表
export function getJoinExamList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/joinTask/examList',
    method: 'post',
    data: params,
  })
}

//任务内容-参与直播列表
export function getJoinLiveList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/joinTask/liveList',
    method: 'post',
    data: params,
  })
}


//任务内容-参与问答列表
export function getJoinQuestionList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/joinTask/questionList',
    method: 'post',
    data: params,
  })
}

//其他任务列表
export function getOtherTaskList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/otherTask',
    method: 'post',
    data: params,
  })
}


//任务下拉列表
export function getSelectTaskList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/selectable/list',
    method: 'post',
    data: params,
  })
}


//前台任务列表
export function getTaskList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/list',
    method: 'post',
    data: params,
  })
}

//任务详情
export function getTaskDetail(id) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/detail',
    method: 'get',
    params: { id },
  })
}

//文章、问答、直播资源关联任务列表查询
export function getDetailTaskList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/task/list/resourceId',
    method: 'get',
    params,
  })
}


