export const SET_COMMENT_LIST = 'setCommentList'
export const SET_MOMENT_LIST = 'setMomentList'
export const SET_USER_TAG = 'setUserTag'
export const SET_USER_INFO = 'setUserInfo'
export const SET_LOGIN_MESSAGE = 'setLoginMessage'
export const GET_USER_INFO = 'getUserInfo'
export const SET_LOGIN_STATE = 'setLoginState'
export const SET_NOTICE_TYPE = 'setNoticeType'
export const SET_SUBJECT_SHOW = 'setSubjectShow'
export const SET_COMMENT_REPORT_DICT = 'setCommentReportDict'
export const SET_CREATE_QUESTION_MESSAGE = 'setCreateQuestionMessage'
export const SET_CREATE_MEETING_MESSAGE = 'setCreateLiveMessage'
export const SET_CREATE_BLOG_MESSAGE = 'setCreateBlogMessage'
export const SET_SELECTEDTASK = 'selectedTask'
export const SET_CREATE_PROBLEM_MESSAGE = 'setCreateProblemMessage'
export const SET_MOMENT_TOPIC_LIST = 'setMomentTopicList'
export const GET_LOGIN_STATE = 'getLoginState'
export const SET_WEB_CONFIG_DATA = 'setWebConfigData'
export const SET_SIGN_IN_MESSAGE = 'setSignInMessage'

export const INCREMENT = 'increment'
export const DECREMENT = 'decrement'
