/**
 * Created by jiachenpan on 16/11/18.
 */

export function parseTime(time, cFormat) {
  let newTime = time
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof newTime === 'object') {
    date = newTime
  } else {
    if (('' + newTime).length === 10) newTime = parseInt(newTime) * 1000
    date = new Date(newTime)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

export function formatTime(time, option) {
  let newTime = +time * 1000
  const d = new Date(newTime)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(newTime, option)
  } else {
    return (
      d.getMonth()
      + 1
      + '月'
      + d.getDate()
      + '日'
      + d.getHours()
      + '时'
      + d.getMinutes()
      + '分'
    )
  }
}


export function nFormatter(num, digits = 2) {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'K' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'G' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}


/**

   * @desc 函数防抖
   * @param func 目标函数
   * @param wait 延迟执行毫秒数
   * @param immediate true - 立即执行， false - 延迟执行
   */

export function debounce(func, wait, immediate) {
  let timeout, result
  let debounced = function() {
    //改变执行函数内部this的指向，让this指向container
    let context = this
    //参数
    let args = arguments
    if (timeout) clearTimeout(timeout)
    if (immediate) {
      let callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, wait)
      if (callNow) {
        result = func.apply(context, args)
      }
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args)
      }, wait)
    }
    return result
  }
  //取消防抖
  debounced.cancel = () => {
    clearTimeout(timeout)
    //防止内存泄露
    timeout = null
  }
  return debounced

}


/**
 * 需要删除多余的URl参数  参数多来自登录跳转
 * @param {*} urlSearchParams 路由参数定义
 */
export function deleteUrlParams(urlSearchParams) {
  urlSearchParams.delete('from')
  urlSearchParams.delete('code')
  urlSearchParams.delete('state')
  urlSearchParams.delete('mobile')
  urlSearchParams.delete('preStatusIsPassword')
  urlSearchParams.delete('needLogin')
  urlSearchParams.delete('miniprogram')
  urlSearchParams.delete('accountId')
  urlSearchParams.delete('identity')
}
