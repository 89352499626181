/*
 * 加密方法抽出
 * 引入使用 encryption
 * 入参数 value
 * 返回加密后的 value
 */
import * as CryptoJS from 'crypto-js/crypto-js';
const DESKey = 'WDNRTXBRCBFXJBFZ';
/*DES加密*/
export default (value) => {
  const keyHex = CryptoJS.enc.Utf8.parse(DESKey);
  //模式为ECB padding为Pkcs7
  const encrypted = CryptoJS.DES.encrypt(value, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  //加密出来是一个16进制的字符串
  return encrypted.ciphertext.toString();
}
