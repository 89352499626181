export default {
  // 更新直播间ID
  'SET_ROOM_ID'(state, value) {
    state.roomId = value
  },
  // 更新直播间昵称
  'UPDATE_ROOM_NAME'(state, value) {
    state.roomName = value
  },
  // 更新用户信息
  'UPDATE_USER_INFO'(state, data) {
    state.userInfo = data
  },
  // 设置sdkAppId信息
  'SET_APP_INFO'(state, data) {
    state.appInfo = data
  },
  // 更新直播间阶段
  'UPDATE_LIVE_STAGE'(state, value) {
    state.liveStage = value
  },
  // 更新是否在屏幕分享中
  'UPDATE_IS_SCREEN_SHARING'(state, value) {
    state.isScreenSharing = value
  },
  // 更新是否在屏幕分享中
  'UPDATE_IS_SCREEN_CHANGE'(state, value) {
    state.isScreenChange = value
  },
  // 更新使用的摄像头设备
  'UPDATE_ACTIVE_CAMERA'(state, device) {
    state.activeCamera = device
  },
  // 更新使用的麦克风设备
  'UPDATE_ACTIVE_MICROPHONE'(state, device) {
    state.activeMicrophone = device
  },
  // 更新使用的扬声器设备
  'UPDATE_ACTIVE_SPEAKER'(state, device) {
    state.activeSpeaker = device
  },
  // 更新是否设置本地流镜像
  'UPDATE_SET_MIRROR'(state, value) {
    state.isSetMirror = value
  },
  // 更新是否设置本地流镜像
  'UPDATE_OPEN_BEAUTY'(state, value) {
    state.isOpenBeauty = value
  },
  // 更新视频参数
  'UPDATE_VIDEO_PROFILE'(state, data) {
    state.videoProfile = data
  },
  // 更新美颜参数
  'UPDATE_BEAUTY_PARAM'(state, data) {
    state.beautyParam = data
  },
  // 更新是否录制直播
  'UPDATE_RECORD_LIVE'(state, value) {
    state.isRecordLive = value
  },
  // 更新视频mute状态
  'UPDATE_VIDEO_STATE'(state, value) {
    state.isVideoMuted = value
  },
  // 更新音频mute状态
  'UPDATE_AUDIO_STATE'(state, value) {
    state.isAudioMuted = value
  },
  // 更新音量大小
  'UPDATE_AUDIO_LEVEL'(state, value) {
    state.audioLevel = value
  },
  // 更新上行网络等级
  'UPDATE_UPLINK_NETWORK_LEVEL'(state, value) {
    state.uplinkQualityLevel = value
  },
  // 更新直播间人员列表
  'UPDATE_MEMBER_LIST'(state, value) {
    state.memberInfo.list = value
  },
  // 更新直播间人员总数
  'UPDATE_MEMBER_TOTAL'(state, value) {
    state.memberInfo.total = value
  },
  // 更新直播间人员分页页数
  'UPDATE_MEMBER_CURRENT_PAGE'(state, value) {
    state.memberInfo.currentPage = value
  },
}
