import request from '@/utils/request'

export function shareInit(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/share/init',
    method: 'post',
    data: params,
  })
}

export function shareGenerate(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/share/generate',
    method: 'post',
    data: params,
  })
}

// 获取js sdk授权配置
export function getJsApiConfig(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/share/getJsApiConfig',
    method: 'GET',
    params,
  })
}

// 获取分享图片和描述
export function getSharePicAndDesc(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/share/getSharePicAndDesc',
    method: 'GET',
    params,
  })
}

export function cancelCensusShare(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/share/cancelCensusShare',
    method: 'post',
    params,
  })
}


