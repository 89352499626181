import axios from 'axios'
import router from '@/router/index'
import { getCookie, delCookie } from '@/utils/cookieUtils'
import { Message } from 'element-ui'
import store from '@/store'
import { deleteUserAccessToken } from '@/api/user'
// 创建axios实例
const service = axios.create({
  baseURL: '', // api 的 base_url
  timeout: 20000, // 请求超时时间 10秒
})

service.defaults.headers.common['Authorization'] = getCookie('token')

// request拦截器
service.interceptors.request.use(
  config => {
    if (getCookie('token') != undefined || getCookie('token') !== null) {
      config.headers.Authorization = getCookie('token') // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    // Do something with request error
    console.log('请求拦截', error) // for debug
    Promise.reject(error)
  },
)

// response 拦截器
service.interceptors.response.use(
  response => {
    // return response.data
    const res = response.data
    if (res.code === 'success' || res.code === 'error' || res.code === '00002') {
      return res
    } else if (res.code == 400) {
      router.replace('/404')
      return res
    } else if (res.code == 401) {
      const { name, path } = router.app._route
      if (name !== 'meeting' && path.includes('/workTable')) { //工作台且不是会议详情页面
        router.replace('/')
      }
      const token = getCookie('token')
      if (token) {
        deleteUserAccessToken(token)
      }
      delCookie('token')
      store.commit('setLoginState', false)
      store.commit('setUserInfo', {})
      store.commit('setLoginMessage', Math.random())
      // 用户未登录
      // eslint-disable-next-line new-cap
      Message({
        message: res.data || res.message,
        type: 'error',
        duration: 2 * 1000,
      })
      //
      // delCookie("token")
      // location.reload()
      return res
    } else if (res.code === 500 || res.code == 502 || res.code == 503) {
      router.replace('500')
      return Promise.reject('error')
    } else {
      return Promise.reject('error')
    }
  },
  error => {
    const errorResponse = error.response || {}
    const errorData = errorResponse.data || {}
    if (errorData.status === 401) {
      Message.error('登录已失效，请重新登录')
      delCookie('token')
      setTimeout(() => {
        // const { name, path } = router.app._route
        // if (name === 'meeting' || !path.includes('/workTable')) {
        //   location.reload()
        // } else { //工作台且不是会议详情页面
        //   router.replace('/')
        // }
        store.commit('setLoginMessage', Math.random())
      }, 500)
    } else if (errorData.status > 500 && errorData.status <= 504) {
      // 出现网络超时
      router.push('/500')
      return Promise.reject(error)
    } else if (error.config.message !== false) {
      // eslint-disable-next-line no-undef
      Message.error(errorData.message)
      return Promise.reject(errorData)
    }
  },
)

export default service
