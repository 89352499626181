<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="agreementWrap">
    <el-dialog
      title="隐私政策"
      :visible.sync="dialog1Visible"
      width="800px"
    >
      <div v-if="dialog1Visible">
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            七里香非常
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            注重保护用户（
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            “
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ”
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）的个人信息及隐私，并希望通过本概要向您简洁介绍我们如何收集、使用和保护您的个人信息。如您需要了解完整的《七里香隐私政策
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            》，可查阅概要后的隐私政策正文。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            我们如何收集和使用您的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            为协助您注册、登录七里香账号，提供您开启的七里香全面
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的服务，您需要向我们提供
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            账号名称、手机号码、电子邮箱、第三方平台账号信息（第三方平台授权登录，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            如微信登录
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            等）
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在您使用七里香的过程中，我们还会记录您的使用行为信息（例如您访问过的页面或者点击过的链接），并
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            结合您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的部分设备信息，用以
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            评估您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            操作行为的安全风险，保障七里香的稳定运行，并向您展示您可能感兴趣的信息。该等信息通常不能直接
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            识别您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的个人身份。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            我们如何共享您的信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在我们向您提供服务时，我们会采取严格的安全措施，由我们所完成的服务中我们不会与七里香以外的任何公司、组织和个人共享您的个人信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协议和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或个人信息保护协议，要求他们按照国家相关法律法规规范等的要求，我们的说明，本隐私政策以及其他任何有关个人信息保密和安全保护等措施来处理个人信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您如何管理您的信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您有权管理您的个人信息，包括访问、更正和删除您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的个人信息，改变您授权同意的范围或撤回授权，以及注销您的账号。您可以通过七里香中提供的功能设置进行上述操作。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            如果您无法通过上述方式进行管理，您可以通过我们以下提供的联系方式联系我们，我们将在
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个工作日内回复您的要求。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            我们如何做好未成年人保护
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们非常重视未满
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            18
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            周岁的未成年人（特别是未满
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            14
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            周岁的儿童）的个人信息的保护
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            由于我们的产品和服务主要面向成人，如您为未成年人的，请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            如何联系我们
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、如果您认为您的个人信息安全有被侵害或泄露风险的，您可以通过以下方式进行投诉、举报：
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            可以在网站【联系我们】进行联系。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:normal">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:normal">
            、
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            为保障我们高效处理您的问题并及时向您反馈，根据具体问题的要求可能会需要您提交身份证明、
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            有效联系方式和书面请求及相关证据，我们会在
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            验证您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的身份处理您的请求。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            一般情况下，我们将在
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            个
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            工作日内完成处理。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt; text-align:center">
          <span style="color:#333333; font-family:微软雅黑; font-size:13.5pt; font-weight:bold">
            「
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:13.5pt; font-weight:bold">
            七里香
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:13.5pt; font-weight:bold">
            」隐私政策
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            欢迎您使用七里香平台（
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            “
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ”
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）的产品和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务。我们非常重视和保护您的隐私信息和个人信息保护。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            【七里香平台】是指由
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            【
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            江苏人加信息科技有限公司
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            】
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            享有所有权和运营权的【七里香大健康
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            网站（
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            www.qlxjk.com
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）】平台服务的统称。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            本隐私政策适用于您通过任何方式使用七里香平台提供的全部产品和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务（本隐私政策中统称为七里香平台服务）而提供或留存的信息，我们希望通过本隐私政策向您说明在您使用七里香平台服务时，我们是如何收集、使用、保存、共享和转让这些信息，以及我们将为您提供访问、更新、删除、保护以及注销这些信息的方式。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            请您在继续使用七里香产品
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务前务必认真仔细阅读并确认充分理解本隐私政策全部规则和要点，并
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            确认您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            已经充分理解本隐私政策所写明的内容。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            如您不同意本政策内容，将可能导致我们的产品或服务无法进行，或无法达到我们拟达到的效果，您应立即停止访问
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            使用我们的产品或服务。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您继续使用我们的产品或服务的行为，都表示您充分理解和同意本《七里香
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            隐私政策》的全部内容。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            请您注意，本隐私政策不适用于您通过我们的产品或服务而接入的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            其他第三
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            方产品
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            服务
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            (“
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            其他第三方
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            ”
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            ，包括您的交易相对方、任何第三方网站以及第三
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            方服务
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            提供者等
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            )
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            ，第三
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            方服务
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            受其自有隐私政策约束，你在选择使用第三
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            方服务
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            前应充分了解第三
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            方服务
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的产品功能及隐私保护政策。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            如您对本隐私政策或相关事宜有任何疑问，可通过本隐私政策第八章节【如何联系我们】中提供
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            方式与我们取得联系。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            本隐私政策帮助您了解以下内容：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            一、我们如何收集和使用您的个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            二、我们如何使用
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            和其他追踪技术；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            三、我们如何委托处理、共享、转让、公开披露您的个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            四、我们如何存储保存和保护您的个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            五、您的权利；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            六、我们如何处理未成年人的个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            七、本政策更新及通知；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            八、如何联系我们；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            九、争议解决；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            十、定义和名词解释。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            一、我们如何收集和使用您的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            前言：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、我们会遵循合法、正当、最小必要、公开透明的原则，收集和使用您在使用我们的产品和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务过程中主动提供或因使用我们的产品和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务而产生的个人信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、当您使用我们的产品时，您的系统权限将处于默认关闭状态。我们仅在您触发相关业务功能时，向您申请实现该等业务功能所必需的权限（我们将在本隐私保护指引中对具体申请调用的权限以粗体斜体标注以提示您注意）。我们在申请权限时将向您同步告知权限申请目的。您可以随时撤回授权任
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            一
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            系统权限。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、需要特别指出的是，即使经过您的授权，可以
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            访问您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的相应权限，我们仅会在您的授权范围内进行访问；且如
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您拒绝
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或撤回授予系统权限，仅会使您无法使用相应功能，但并不影响
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您正常
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            使用产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务的其他功能。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、我们收集的您的个人信息类型会因产品
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            服务的内容不同而有所区别，具体以产品
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            服务实际提供为准
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，这意味着随着产品和服务的迭代、拓展、升级等，我们可能会经常推出新的业务功能或重新划分业务功能，并可能需要收集新的个人信息或变更个人信息使用目的或方式。如果我们新增涉及个人信息收集的功能或产品
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            服务，或变更个人信息的使用目的或方式，根据具体情况，我们会通过更新本隐私保护指引、页面提示、弹窗、网站公告、站内信等方式另行向您说明该信息收集的目的、内容、使用方式和范围，并为您提供自主选择同意的方式，且在征得您同意后收集和使用。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            6
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将不含可识别到具体个人身份的信息。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            请您了解并同意，在此情况下我们有权
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            使用已经去标识化的信息；
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            7
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、我们会对我们的产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            以展示我们的产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务的整体使用趋势。但这些统计信息将进行匿名化处理，不包含您的任何身份识别信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            8
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、对于您选择在我们平台发布非匿名信息，如发布帖子和评论等，视为您同意在平台向其他用户公开展示您的个人信息以及
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您发布
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的信息，否则您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            应选择不发布
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            发布非匿名信息如发布帖子和评论等，视为您同意在平台向其他用户公开展示您的个人信息以及
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您发布
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            通常情况下，我们会在以下场景中收集您的个人信息或申请相关权限：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （一）注册、登录
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、为接受七里香全面
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的服务，您应首先注册一个用户账号，为完成创建账号，您需至少提供如下信息：您准备使用的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            账号名、您本人的手机号码（用于身份验证）
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ，我们将通过发送短信验证码的方式来
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            验证您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的身份是否有效。您的账号名默认为您的昵称，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            如您不注册账号，通过第三方平台（
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            微信登录
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            ）授权登录的，我们仍然需要绑定您的手机号码，通过手机号码来
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            验证您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的身份是否有效。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            注册成功后，您可选择完善相关的网络身份识别信息（包括头像、昵称、个人简介等相关信息），
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            如您未完善
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ，也不会影响您使用产品的基础功能。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、如
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您拒绝
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            提供手机号码进行身份验证，将导致注册不成功，您可以退出注册页面后进入七里香
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            平台，仅浏览平台内的相关信息内容，但不可进行任何其它的具体操作或使用平台提供的核心服务等（如下第（二）节我们为您提供的产品和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务中的相关内容）。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （二）向您提供产品和服务
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、信息展示以及信息搜索浏览功能
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）七里香平台提供丰富的医疗健康相关的信息，这些信息包含文章、视频、用户发布的帖子以及帖子中的评论等。为此，在您进行信息浏览时，我们可能会收集您在使用该等服务时的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            设备信息，包括设备名称、设备型号、操作系统版本和应用程序版本、语言设置、分辨率、电信运营商信息、浏览器类型来为您提供信息内容展示的最优方式，我们也会为了不断改进和优化上述功能而使用您的上述信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            (2)
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            同时在您进行浏览时，还可根据个人需求和喜好选择搜索服务，通过搜索关键词精准找寻内容。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            为了提供该等个性化搜索喜好与内容的精准搜索并优化搜索服务以及依法留存网络服务日志，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们将可能收集您的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            日志信息
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            作为网络日志保存，可能包括
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您输入的搜索关键词信息和点击的链接、
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您发布
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的评论、咨询的相关问题、对我们服务的反馈与评价、
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您上传的文字资料、图片、视频等信息以及您的
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            IP
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            地址、您访问服务的日期、时间、时长。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            请您注意
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ：单独的设备信息、浏览器类型信息或单独的搜索关键词信息无法单独
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            识别您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的设备信息、浏览器类型信息或搜索关键词信息与您的其他信息相互结合使用并可以
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            识别您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的身份时，在结合使用期间，我们会将该等信息作为您的个人信息，按照本隐私政策对其进行处理与保护。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、发帖、评论、问答等信息公开发布功能
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            七里香平台为用户提供如下信息发布功能：发帖，评论，评价、发布和上传视频，参与讨论话题、直播及其他信息发布功能公开发布信息。我们可能因此
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            需要收集您的昵称、头像、账号、发布内容（包括文字、图片、视频等）
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。同时我们可能会根据您的账户类型及网络日志信息
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            判断您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            是否可享受对应功能权限。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            请注意，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您公开
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            (
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            如您在评价时选择上传包含个人信息的图片
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            )
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。若
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您公开
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            发布的信息中涉及他人个人信息的，您需在发布前征得他人的同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、客户服务与售后
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            当您向七里香平台发起咨询、提起投诉、申诉、提供建议或进行评价或发起售后服务时，为了方便与您联系或帮助您解决问题，我们可能需要您提供
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            姓名、手机号码、电子邮件或其他联系方式
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            等我们为您解决问题所必要的个人信息和基本信息。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            如
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您拒绝
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            提供上述信息，我们可能无法向您及时反馈投诉、申诉或咨询结果。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、个性化推送功能
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们会基于收集的信息，对您的偏好、习惯、位置
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            作特征
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            分析和用户画像，以便为您提供更适合的定制化服务，例如向您展现或推荐相关程度更高（而非普遍推送）的搜索结果、信息流或者广告
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            推广信息结果。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            为此，我们需要收集的信息包括您的设备信息、浏览器型号、日志信息、浏览记录
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、广告服务
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们可能使用您的设备标识符如
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            IMEI
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            IDFA
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            OAID
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            等，广告数据如曝光、点击数据等，设备信息如系统语言、屏幕高、宽、屏幕方向等信息，在七里香平台中向您提供您可能感兴趣的广告，评估、改善我们的广告投放和其他促销及推广活动、商业分析的效果。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            6
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、对于
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            上述您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的个人信息，大部分是由您主动向我们提供的，也有我们在您使用我们的产品或服务时通过
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookies
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            SDK
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            及类似技术获取的，还有部分是从第三方间接获取的。我们会在下文中，向您展示说明。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （三）我们通过间接方式收集到的您的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            当您选择
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            通过微信第三
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            方平台登录使用我们的产品和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务时，我们可能从第三方处获取您授权共享的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            账号信息（头像、昵称）。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们可能会从第三方间接收集（如共享等）您的个人信息，但这只发生在：我们确认该等第三方已经就将相关个人信息分享给已获得您的同意的第三方，或者第三方依法被许可或被要求向我们披露您的个人信息。我们会要求第三方对个人信息来源的合法性和合
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            规
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            性
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            作出
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任。我们会了解第三方已获得的个人信息处理的同意范围，包括使用目的，个人信息主体是否同意转让、共享、公开披露、删除等内容。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            这些从第三方获取的个人信息将用于为您提供服务，以及用于保证我们所掌握的您的相关记录的准确性。如果我们对这些个人信息的处理活动超出第三方已获得的同意范围，在获取个人信息的合理期限内或处理个人信息前，我们会直接或通过提供信息的第三方征得您的同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，均会事先获得您的同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （四）我们自动收集的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            当您通过网站入口使用我们的服务时，我们可能自动从您的设备处收集您的
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            IP
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            地址浏览器型号、粗略位置信息（例如国家或者城市信息）以及其他的设备技术信息，我们也会收集一些您使用我们服务的日志信息，例如访问过的页面或者点击过的链接。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            收集该等信息主要为了保障服务的安全运行，也可以使我们更好地了解您，比如您来自哪里以及我们的服务有哪些吸引您的内容。我们将该等信息用于内部分析，以及为您提升服务质量和服务相关性（如向您推荐您可能会感兴趣的信息和内容）。我们同时也需要留存相应的日志来记录我们网站、移动客户端等服务入口的运行状态，以符合法律法规的规定。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （五）无需征得您同意的情形
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            根据相关法律的规定，我们在以下情况下收集和使用您的个人信息无需取得您的同意：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）为履行法定职责或者法定义务所必需；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理您的个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）依照《个人信息保护法》规定在合理范围内处理您自行公开或者其他已经合法公开的个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            6
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）法律、行政法规规定的其他情形。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （六）个人敏感信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们收集和使用您的敏感个人信息的，将
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            以弹窗等
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            形式取得您的单独同意。在向平台在线提供任何属于敏感信息的个人信息前，请
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您清楚
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            考虑这种提供是恰当的并且同意您的个人敏感信息可按本政策所述的目的和方式进行处理。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们会在得到您的同意后收集和使用您的敏感信息以实现与平台在线业务相关的功能，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            并允许您对这些敏感信息的收集与使用做出不同意的选择，但是拒绝使用这些信息会影响您使用相关功能。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            如我们停止运营七里香产品
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。涉及未满
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            14
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            周岁的儿童个人信息的，我们会并将停止运营的通知及时告知儿童监护人。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （七）如果您对我们收集和使用您的个人信息的法律依据有任何疑问或需要提供进一步的信息，请通过第八章节「如何联系我们」提供的联系方式与我们联系。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （八）当我们要将信息用于本政策未载明的其他用途时，会事先征求您的同意，当我们要将基于特定目的收集而来的信息用于其他目的是，会事先征求您的同意。请你知悉，我们向您提供的产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务，但不影响您使用现有产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            二、我们如何使用
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            和其他追踪技术
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （一）
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            Cookie
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、为确保网站正常运转，我们会在您的计算机或移动设备上存储名为
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的小数据文件。
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            通常包含标识符、站点名称以及一些号码和字符。借助于
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ，我们能够
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            存储您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的偏好或商品等数据，并用以判断注册用户是否已经登录，提升服务
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            产品质量及优化用户体验。通过得到的
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookies
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            信息，为您提供个性化服务。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、我们不会将
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。您可以清除计算机上或手机中保存的所有
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ，大部分网络浏览器都设有阻止或禁用
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的功能，您可对浏览器进行配置；但如果您这么做，则需要在每一次访问我们的平台时亲自更改用户设置。阻止或禁用
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            功能后，可能影响您使用或不能充分使用七里香提供
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的全部产品和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （二）网站信标和像素标签
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            除
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            Cookie
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件或短信中可能含有链接至我们网站内容的点击
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            URL
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好并改善客户服务。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            三、我们如何委托处理、共享、转让与公开披露您的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （一）委托处理
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            平台的部分功能可能由外部服务商提供，例如，我们可能会聘请专业数据处理服务机构来为我们的平台提供数据分析方面的技术支持。对我们委托处理个人信息的公司、组织和个人，我们会与其签署协议，约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对受托人的个人信息处理活动进行监督。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （二）共享
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            我们仅会出于合法、正当、必要且本隐私保护指引所明确的目的与第三方分享您的个人信息，并且只会分享必要的个人信息。如果第三方改变或超越
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您原同意
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            范围处理您的个人信息，则我们会要求第三方再次征得您的同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            对我们与之共享个人信息的公司、组织和个人，我们会要求其严格遵守我们关于数据隐私保护的措施和要求，包括但不限于根据数据保护协议、承诺书及相关数据处理政策进行个人信息的处理，避免识别出个人身份，保障隐私安全。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、我们不会与七里香品牌
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            旗下附属公司以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）只有共享您的信息，才能实现我们的产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务的核心功能或提供您需要的服务（此种情形我们将在情形发生时专门提示您）。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）与我们的附属公司共享：您的个人信息可能会与七里香旗下的所有主体公司、附属公司、关联公司（以下统称七里香
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            附属公司
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            -
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            见第十章【定义和名词解释】
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。附属公司如要改变个人信息的处理目的，将再次征求您的授权同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            应您需求
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            为您处理您与他人的纠纷或争议；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            6
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）基于学术研究而使用；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            7
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）基于符合法律法规的社会公共利益而使用。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、与授权合作伙伴共享
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在我们向您提供服务时，我们会采取严格的安全措施，由我们所完成的服务中我们不会与七里香以外的任何公司、组织和个人共享您的个人信息。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            但请您知悉，我们提供的服务有部分功能是无法单独完成的，因此我们可能会与我们的关联公司以及其他合作商等第三方共享或委托其处理您的部分个人信息
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ，以保障和优化我们为您提供的服务
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们与第三方共享的服务类型一般包括：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）厂商推送类：我们会委托有资质的供应商为我们提供推送技术服务、统计分析服务，我们可能会将您的设备平台、设备厂商、设备品牌、设备识别码等设备信息，应用列表信息、网络信息以及位置相关信息提供给供应商，用于为您提供推送技术服务、统计分析服务。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）商品或技术服务类。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、代表我们发出短信的通讯服务供应商、数据处理等。我们共享这些信息的目的是可以实现我们产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务的核心功能。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）分析服务类。
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在征得您的许可，我们可能将不能
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            识别您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的个人身份信息的统计或匿名信息共享给提供分析服务的合作伙伴。对于分析数据的伙伴，为了更好的分析七里香平台用户的使用情况，我们可能向其提供七里香用户
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的数量、地区分布、活跃情况等数据，但我们仅会向这些合作伙伴提供不能识别个人身份的统计或匿名信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）统计类。有时我们会代表其他企业向使用我们产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务的用户群提供促销推广的服务。我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            “
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            委托方
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ”
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            它不会
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            识别您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个人。比如我们可以告知该委托方有多少人看了他们的推广信息，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照法律法规规范等的要求、我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            3
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            .
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
					&#xa0;
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护七里香、您或其他七里香用户
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ,
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的用户信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （二）转让
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、在获取明确同意的情况下转让
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            :
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            获得您的明确同意后，我们会向其他方
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            转让您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （三）公开披露
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们仅会在以下情况下，公开披露您的个人信息
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            :
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、获得
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您明确
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            同意后
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、基于法律的披露
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            :
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （四）共享、转让、公开披露个人信息时事先征得授权同意的例外
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            根据《个人信息保护法》的规定，以下情形下，我们共享、转让、公开披露您的个人信息，无需事先取得您的同意：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）为订立、履行您作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            人力资源管理所必需；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）为履行法定职责或者法定义务所必需；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）依照《个人信息保护法》的规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            法律、行政法规规定的其他情形。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            四、我们如何保存和保护您的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （一）个人信息的保存
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、保存期限：请您注意，在您在使用我们的产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，我们将在您使用我们的产品和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或服务期间持续授权我们使用。在您申请注销账号并时，我们将停止使用并删除上述信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、保存地域：上述信息将存储于中华人民共和国境内。如需跨境传输，我们将会在符合国家对于信息出境的相关法律规定情况下，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            再单独征
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            得您的授权同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （二）个人信息的保护
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、安全措施
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们会使用加密技术确保数据的保密性；我们会使用受信赖的保护机制防止数据遭到恶意攻击。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们会部署访问控制机制，确保只有授权人员才可访问个人信息；我们会与
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            接触您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被追究相关责任。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、安全提醒
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他用户的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）您在使用七里香平台服务时请勿将自认为隐私的信息发表、上传至七里香平台，也不可将该等信息通过七里香平台的服务器传播给他人，若因您该等行为引起隐私泄露，由您自行承担责任。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）您请勿在使用七里香平台服务时公开透露自己的各类财产账号、银行卡、信用卡、第三方支付账号及对应密码等重要资料，否则由此带来的损失由您自行承担责任。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）七里香平台一旦发现假冒、仿冒、盗用他人名义进行平台认证的，七里香平台有权立即删除用户信息
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            并有权在用户提供充分证据签禁止其使用平台服务。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、安全事件通知
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们会制定相应的网络安全事件应急预案，及时处置系统漏洞、计算机病毒、网络攻击、网络侵入等安全风险，在发生危害网络安全的事件时，我们会立即启动应急预案，采取相应的补救措施；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）如您发现自己的个人信息泄密，尤其是您的账号及密码发生泄露，请您立即通过七里香平台提供的以及本隐私政策第八章节提供【如何联系我们】中提供的联系方式联络我们，以便我们采取相应措施。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            五、您的权利
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            我们保障您对自己的个人信息行使以下权利：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （一）
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            访问和
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            更正您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、除法律法规规定外，您有权随时访问和
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            更正您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的个人信息，具体包括：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）您可通过电脑访问
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            www.qlxjk.com
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            登录【
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个人中心
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            】，访问或者
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            修改您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            用户名绑定的手机号码和
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或邮箱信息、第三方绑定信息等；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您可通过电脑访问
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            www.qlxjk.com
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            登录【
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个人中心
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            】，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            访问或者
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            修改您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的昵称、城市、个人简介
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            等信息
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、如果您需要
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            查阅您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在使用七里香平台过程中产生的其他个人信息，
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            在合理要求下并经验证核实您的身份后
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ，我们会向您提供。若您无法通过上述方式访问或者
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            更正您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的个人信息，也可以通过本协议第八章节的【如何联系我们】等方式
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            提交
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            建议反馈。我们会在
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个工作日内回复处理您的查询请求。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （二）
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            删除您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、如果您需要
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            删除您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在使用七里香网站过程中产生的其他个人信息，在合理要求并经验证核实您的身份后，我们会向您提供。您可以通过本隐私政策第八章节的【如何联系我们】
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            等
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            方式向我们进行反馈。我们会在
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个工作日内回复处理您的删除请求。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、在以下情形中，您可以向我们提出删除个人信息的请求：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）如果我们处理个人信息的行为违反法律法规；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）如果我们收集、使用您的个人信息，却未征得您的同意；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）如果我们处理个人信息的行为违反了与您的约定；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）如果您注销了个人在线
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            帐号
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）如果我们终止服务及运营。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、若我们决定
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            响应您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （三）改变您授权同意的范围或撤回您的授权
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            对于您个人信息的收集和使用，您可以在七里香
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            APP
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            上随时通过以下方式给予或收回或撤回您的授权同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）您可通过
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            PC
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            端【个人
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            中心
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            】中，来进行消息通知（私信设置）设置。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您也可以通过注销账号的形式撤回我们对您个人信息收集的全部授权或通过第八章节的【如何联系我们】进行相应的授权变更。当您撤回同意或授权后，我们不再继续为您提供撤回同意或授权后所对应的服务，也无法处理
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您相应
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个人信息，但您撤回同意或授权的决定，不会影响此前基于您的授权而使用开展的个人信息的处理。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
					&#xa0;
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
					&#xa0;
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （四）注销您的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            帐号
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您可以在
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            PC
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            端【联系我们】中提供的
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            联系方式
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            ，发送注销申请，我们会在
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            个工作日内处理您的注销申请。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您注销账号后，我们将停止为您提供产品与
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            /
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            或服务，并依据您的要求，除法律法规另有规定外，我们将
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            删除您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的个人信息
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、如果您不想接受我们给您发送的推送信息，您随时可通过以下方式取消：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）您可根据收到的推送短信内容，根据短信中的提示随时回复
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            “T”
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            或
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            “TD”
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （以短信中的方式为准）来取消我们给您发送的手机推送短信。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）您可以
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            您的设备中的【设置】
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            来开启或关闭七里香平台的通知推送。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）您可以通过第八章节的【如何联系我们】中提供的方式，向我们申请关闭推送。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （五）获取个人信息副本
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您有权获取您的个人信息副本，您可以通过第八章节的【如何联系我们】与我们联系，我们将在
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            工作日内对您的请求进行处理。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （六）约束信息系统自动决策
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的救济方式。您可以通过网站【联系我们】与我们联系，我们将在
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            不
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            侵害七里香平台商业秘密或其他用户权益、社会公共利益的前提下，在
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            工作日内对您的请求进行处理。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （七）
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            响应您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的上述请求
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、为保障安全，我们可能会先要求您验证自己的身份，然后再处理您的请求。您可能需要提供书面请求，或以其他方式证明您的身份。对于您的请求，我们原则上将于
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个工作日内做出答复。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、对于
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您合理
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            （八）在以下情形中，按照法律法规要求，我们将无法
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            响应您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的请求：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、与国家安全、国防安全直接相关的；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、与公共安全、公共卫生、重大公共利益直接相关的；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、与犯罪侦查、起诉、审判和判决执行等直接相关的；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、有充分证据表明
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您存在
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            主观恶意或滥用权利的；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            响应您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            的请求将导致您或其他个人、组织的合法权益受到严重损害的；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            6
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、涉及商业秘密的。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            六、我们如何处理未成年人的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            以下条款请未成年人用户（特别是不满十四周岁的儿童用户）在监护人的陪同下仔细阅读，并由监护人在充分理解后作出是否接受或拒绝本政策的决定：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、我们非常重视对未成年人个人信息的保护。根据相关法律法规的规定，若您是
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            18
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            周岁以下的未成年人（特别是不满十四周岁的儿童），在使用七里香服务
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            前，应事先取得您的家长或法定监护人的书面同意。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、若您是未成年人的监护人，当您对您所监护的未成年人使用我们的服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。我们将根据国家相关法律法规及本政策的规定保护未成年人用户信息的保密性及安全性。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、尽管当地法律和习俗对儿童的定义不同，但我们将不满
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            14
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            周岁的任何人均视为儿童。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            七、
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            本政策更新及通知
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、我们的隐私政策可能变更。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、未经
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            您明确
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            同意，我们不会
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            削减您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            按照本隐私政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、对于重大变更，我们可能还会提供更为显著的通知
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            (
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            包括对于某些服务，我们会通过电
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            子邮件、站内信、短信
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            等方式发送通知，说明隐私政策的具体变更内容
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            )
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、本政策
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            重大变更
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            包括但不限于：
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）个人信息共享、转让或公开披露的主要对象发生变化；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）您参与个人信息处理方面的权利及其行使方式发生重大变化；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            6
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ）个人信息安全影响评估报告表明存在高风险时。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、若您不同意修改后的隐私政策，您有权并应立即停止使用七里香服务。如果您继续使用七里香平台的服务，则视为您接受七里香对本隐私政策相关条款所做的修改。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            八、如何联系我们
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：可以在网站【联系我们】
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            中
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            进行联系。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            、如果您认为您的个人信息安全有被侵害或泄露的风险的，您可以通过以下方式进行投诉、举报：
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            可以在网站【联系我们】
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            与我们联系
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ，我们将在
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            个工作日内进行受理并处理。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、为保障我们高效处理您的问题并及时向您反馈，需要您提交身份证明、有效联系方式和书面请求及相关证据，我们会在
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            验证您
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            的身份处理您的请求。一般情况下，我们将在
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            15
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            个
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            工作日内回复处理。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            九、
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold" />
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            争议解决
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            因本政策以及我们处理您个人信息事宜引起的任何争议，您可
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            随联系七里香要求
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            给出回复，如果您对我们的回复不满意的，认为我们的个人信息处理行为严重损害了您的合法权益的，您还可以通过向本隐私政策服务提供商
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            【
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            江苏人加信息科技有限公司
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            】所在地【
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            苏
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            州】
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            有管辖权的人民法院提起诉讼来寻求解决方案。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            十、定义和名词解释
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            1
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、七里香：医疗领域
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            信息展示网站
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ，互联网医疗行业品牌机构。以
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            www.qlxjk.com
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            （七里香网站）为主要网站或平台向医疗领域的参与者相关者提供各类医学医疗相关服务的品牌机构。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            2
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、七里香
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            附属公司
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ：七里香的经营者
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            江苏人加信息科技
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            有限公司系七里香品牌
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            旗下主体公司，与七里香旗下其它主体公司之间存在关联关系，这些存在关联关系的七里香旗下主体公司的单称或合称为【七里香附属公司】。
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            “
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            关联关系
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ”
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            是指对于任何主体（包括个人、公司、合伙企业、组织或其他任何实体）而言，即其直接或间接控制的主体，或直接或间接控制其的主体，或直接或间接与其受同一主体控制的主体。前述
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            “
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            控制
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ”
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            指，通过持有表决权、合约或其他方式，直接或间接地拥有对相关主体的管理和决策
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            作出
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            指示或责成他人
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            作出
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            指示的权力或事实上构成实际控制的其他关系。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            3
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、个人信息
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ：个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            4
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、个人敏感信息
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ：个人敏感信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            5
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、去标识化：
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            去
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            标识化指个人
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            6
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            、
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt; font-weight:bold">
            IP
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            地址
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            ：每台上网的设备都会指定一个编号，称为互联网协议
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt" />
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            ( IP )
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            地址。这些编号通常都是根据地理区域指定的。
          </span>
          <span style="color:#333333; font-family:'Segoe UI'; font-size:10.5pt">
            IP
          </span>
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt">
            地址通常可用于识别设备连接至互联网时所在的位置。
          </span>
        </p>
        <p style="background-color:#ffffff; margin:5pt 0pt">
          <span style="color:#333333; font-family:微软雅黑; font-size:10.5pt; font-weight:bold">
            感谢您对七里香平台以及七里香产品 和/或服务的信任和使用！
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:等线; font-size:10.5pt">
					&#xa0;
          </span>
        </p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog1Visible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  data() {
    return {
      dialog1Visible: false,
    }
  },
  methods: {
    open() {
      this.dialog1Visible = true
    },
  },

}
</script>

<style scoped lang="scss">
.container{
  background:#fff;
  min-height: 600px;
  padding: 20px;
}
@media only screen and (max-width: 1134px) {
.agreementWrap{
  ::v-deep .el-dialog{
    width: 98% !important;
  }
}
}
</style>
