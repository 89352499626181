<template>
  <el-dialog
    title="微信换绑提示"
    :visible.sync="bindMobileVisible"
    :show-close="false"
    :width="isMobile?'90%':'30%'"
  >
    <p>此微信已绑定手机号{{ mobile|noCenterMobile }}，是否进行换绑操作?</p>
    <p class="text-tip">
      换绑后微信将解绑原有账号，绑定到当前账号
    </p>
    <span slot="footer" class="dialog-footer">
      <el-button @click="noChangeMobile">不换绑</el-button>
      <el-button type="primary" @click="chagneMobile">换绑到当前账号</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { wechatChangeBindMobile } from '@/api/user'
import { deleteUrlParams } from '@/utils'
import { Loading } from 'element-ui'
export default {
  name: 'Share',
  filters: {
    noCenterMobile(val) { //手机号脱敏法
      const text = val ? val.replace(/^(.{3})(?:\d+)(.{4})$/, '$1****$2') : ''
      return text
    },

  },
  props: { mobile: String },
  data() {
    return {
      loading: null,
      bindMobileVisible: false,
      unionId: '',
      inputMobile: '',
      isMobile: window.innerWidth <= 960,


    }
  },
  mounted() {
  },


  methods: {
    //打开
    open(val) {
      this.bindMobileVisible = true
      this.unionId = val.unionId
      this.inputMobile = val.inputMobile
    },
    handleClose() {
      this.bindMobileVisible = false
    },
    //不换绑
    noChangeMobile() {
      this.bindMobileVisible = false
      this.$message({
        type: 'warning',
        message: '请你更换微信进行扫码！',
      })
      this.$emit('close')
    },
    //  换绑到当前账号
    chagneMobile() {
      const { unionId, inputMobile } = this
      const params = { mobile: inputMobile, unionId }
      this.loading = Loading.service({
        lock: true,
        text: '提交中……',
      })
      wechatChangeBindMobile(params).then(response => {
        if (response.code == this.$ECode.SUCCESS) {
          this.bindMobileVisible = false
          let url = new URL(location.href)
          let urlSearchParams = url.searchParams
          deleteUrlParams(urlSearchParams)
          urlSearchParams.append('token', response.data.token)
          this.$router.replace(url.pathname + url.search)
        } else {
          this.$message({
            type: 'error',
            message: response.message,
          })
        }
        if (this.loading) {
          this.loading.close()
        }
      })
    },
  },

}
</script>
<style>
.text-tip{
  color: #e6a23c;
  padding-top: 8px;
}
@media only screen and (max-width: 750px) {

}
</style>


