import request from '@/utils/request'
// 处理游客信息
function handleVisitorUserInfo(records) {
  return records.map(item => {
    if (!item.user) {
      item.user = {
        nickName: item.visitorName,
        photoUrl: item.visitorImage,
        userIpPossession: item.visitorIpPossession,
        isVisitor: true,
      }
    }
    if (item.replyList && item.replyList.length) {
      item.replyList = handleVisitorUserInfo(item.replyList)
    }
    return item
  })
}
export function getCommentList(params) {
  return new Promise((resolve, reject) => {
    request({
      url: process.env.VUE_APP_WEB_API + '/web/comment/getList',
      method: 'post',
      data: params,
    }).then(res => {
      res.data.records = handleVisitorUserInfo(res.data.records)
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}


export function getCommentListByUser(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/web/comment/getCommentListByUser',
    method: 'post',
    data: params,
  })
}

export function getReplyListByUser(params) {

  return new Promise((resolve, reject) => {
    request({
      url: process.env.VUE_APP_WEB_API + '/web/comment/getReplyListByUser',
      method: 'post',
      data: params,
    }).then(res => {
      res.data.records = handleVisitorUserInfo(res.data.records)
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

export function getPraiseListByUser(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/web/comment/getPraiseListByUser',
    method: 'post',
    data: params,
  })
}

export function addComment(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/web/comment/add',
    method: 'post',
    data: params,
  })
}

export function deleteComment(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/web/comment/delete',
    method: 'post',
    data: params,
  })
}

export function reportComment(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/web/comment/report',
    method: 'post',
    data: params,
  })
}
