<template>
  <el-dialog
    title="直播统计数据"
    :visible.sync="statisticsVisible"
    :width="isMobile?'95%':'50%'"
    close-on-click-modal
    @close="close"
  >
    <div>
      <p class="livetext">
        {{ info.title }}
      </p>
      <p>{{ info.content }}</p>
      <div class="bgclass">
        <div class="statisticInfo">
          <label class="statisticsLabel">直播时间：</label><p>{{ startTime(info) }} {{ endTime(info) }}</p>
        </div>
        <div class="statisticInfo">
          <label class="statisticsLabel">直播时长：</label><p>{{ info.meetingDuration||0 }}min</p>
        </div>
        <div class="statisticInfo">
          <label class="statisticsLabel">观看人数：</label><p>{{ info.meetingJoinNum||0 }}人</p>
        </div>
        <div class="statisticInfo">
          <label class="statisticsLabel">互动消息：</label><p>{{ info.interactiveMessageNum ||0 }}条</p>
        </div>
        <div class="statisticInfo">
          <label class="statisticsLabel">直播链接：</label><p>{{ VUE_MOGU_WEB + '/live/' + info.uid }}</p>
        </div>
        <div class="statisticInfo">
          <label class="statisticsLabel">直播签到表：</label>
          <el-image
            v-if="info.liveCheckInImg"
            fit="cover"
            class="imglist"
            :src="info.liveCheckInImg"
            :preview-src-list="[info.liveCheckInImg]"
          />
          <div v-else>
            暂无
          </div>
        </div>
        <div class="statisticInfo">
          <label class="statisticsLabel">直播截图：</label>
          <div v-if="info.screenshot&&info.screenshotList.length">
            <el-image
              v-for="(image, index) in (info.screenshotList)"
              :key="'photoList'+index"
              fit="cover"
              class="imglist"
              :src="image"
              :preview-src-list="info.screenshotList"
            />
          </div>
          <p v-else>
            暂无
          </p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getStartTime, getEndTime } from '@/utils/common'
export default {
  name: 'Statistics',

  props: { visible: Boolean, info: Object },
  data() {
    return {
      isLoading: true,
      isMobile: window.innerWidth <= 960,
      vditor: null,
      VUE_MOGU_WEB: process.env.VUE_APP_VUE_MOGU_WEB,
      statisticsVisible: this.visible, //控制弹出框,
    }
  },
  watch: {
    visible: function() {
      this.statisticsVisible = this.visible
    },
  },

  created() {

  },
  mounted() {

  },
  methods: {

    close() {
      this.$emit('close')
    },
    //直播时间显示
    //会议时间显示
    startTime(item) {
      return getStartTime(item)
    },
    endTime(item) {
      return getEndTime(item)
    },

  },
}
</script>

<style lang="less" scoped>
.livetext{
  font-weight: bold;
  color: #303133;
  margin-bottom: 10px;
}
.bgclass{
  padding:20px;
  background: #F5F5F5;
  border-radius: 4px;
  margin-top: 14px;
}
.statisticInfo{
  display: flex;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #333;
  align-items: top;
  label{
      width: 86px;
      color: #666;
      flex: none;
      text-align: right;
  }
  p{
    max-width: calc(100% - 80px);
    word-break: break-all;
    white-space: pre-line;
  }
}
.imglist{
  width: 140px;
  height: 112px;
    cursor:zoom-in;
    margin-right: 5px;
}

</style>


