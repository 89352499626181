import { getUrlParam } from '@/utils/utils'
import dayjs from 'dayjs'

const IS_DEVELOPMENT_ENV = process.env.VUE_APP_NODE_ENV === 'development'
const IS_PRODUCTION_ENV = process.env.VUE_APP_NODE_ENV === 'production'

/**
 * 页面跳转
 * @param {string} pathname 路径跳转
 * @param {object} queryObj 路径跳转携带参数
 */
export function goToPage(pathname, queryObj = {}) {
  let pathSting = ''
  if (IS_DEVELOPMENT_ENV) {
    pathSting = `/${pathname}`
  } else if (IS_PRODUCTION_ENV) {
    const path = location.pathname
    const prefix = path.slice(0, path.lastIndexOf('/'))
    pathSting = `${prefix}/${pathname}.html`
  }
  const queryString = Object.keys(queryObj)
    .reduce((prev, key) => [prev, `${key}=${queryObj[key]}`].join('&'), '')
    .slice(1)
  location.replace(`${location.origin}${pathSting}${queryString ? `?${queryString}` : ''}`)
}

/**
 * 获取语言
 * @returns language
 */
export function getLanguage() {
  let language = localStorage.getItem('trtc-tuiPlayer-language') || getUrlParam('lang') || navigator.language || 'zh'
  language = language.replace(/_/, '-').toLowerCase()

  if (language === 'zh-cn' || language === 'zh') {
    language = 'zh'
  } else if (language === 'en' || language === 'en-us' || language === 'en-GB') {
    language = 'en'
  }
  return language
}
/**
 * 获取开始时间
 * @param {*} item
 * @returns
 */


export function getStartTime(item) {
  const { liveStatus, expectStartTime, startTime } = item
  let start = dayjs(expectStartTime).format('YYYY-MM-DD HH:mm')
  if (liveStatus && startTime) {
    start = dayjs(startTime).format('YYYY-MM-DD HH:mm')
  }

  const dayArray = start.split(' ')
  const day = dayArray[0] || ''
  const time = dayArray[1] || ''


  const weekArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
  const index = dayjs(start).day()
  const dayWeek = start ? weekArr[index] : ''
  return day + ' ' + dayWeek + ' ' + time

}
/**
 * 获取结束时间
 * @param {*} item
 * @returns
 */

export function getEndTime(item) {
  const { liveStatus, expectStartTime, startTime } = item
  let start = dayjs(expectStartTime).format('YYYY-MM-DD HH:mm')
  if (liveStatus && startTime) {
    start = dayjs(startTime).format('YYYY-MM-DD HH:mm')
  }
  const startDay = dayjs(start).format('YYYY-MM-DD')
  const { endTime = '' } = item
  let end = ''
  if (endTime) {
    end = dayjs(endTime).format('HH:mm')
  }
  const endDay = end ? dayjs(endTime).format('YYYY-MM-DD') : ''
  let nextdayFlag

  if (endDay) {
    const nextDay = dayjs(startDay).add(1, 'day').format('YYYY-MM-DD') //在当前的基础上加1天
    if (startDay === endDay) {
      nextdayFlag = 0//当天
    } else if (nextDay == endDay) {
      nextdayFlag = 1//次日
    } else {
      nextdayFlag = 2//其他
    }

  }

  return end ? `至 ${nextdayFlag == 1 ? ' 次日' : ''}${nextdayFlag == 2 ? endDay + ' ' + end : end} ` : end

}
