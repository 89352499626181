<template>
  <div>
    <!-- 添加或修改对话框 -->
    <el-dialog
      :title="isEdit?'编辑直播':'创建直播'"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :before-close="beforeClose"
      :width="isMobile?'95%':'50%'"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        class="liveForm"
        style="padding-right:50px"
      >
        <el-row>
          <el-col :span="16">
            <el-form-item label="直播标题" :label-width="formLabelWidth" prop="title">
              <el-input
                v-model="form.title"
                auto-complete="off"
                maxlength="30"
                show-word-limit
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-col :span="16">
          <el-form-item label="关联任务" :label-width="formLabelWidth" prop="taskId">
            <el-select
              v-model="form.taskId"
              style="display:block"
              filterable
              clearable
              remote
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="loading"
            >
              <el-option
                v-for="item in taskListData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="直播类型" :label-width="formLabelWidth" prop="liveType">
            <el-radio-group v-model="form.liveType" size="small">
              <el-radio :label="0" :disabled="!!form.liveStatus">
                实时直播
              </el-radio>
              <el-radio :label="1" :disabled="!!form.liveStatus">
                播放录播
              </el-radio>
            </el-radio-group>
            <el-tooltip
              class="item"
              effect="dark"
              content="播放录播是指提前上传录制好的视频文件，到达直播时间后会自动播放，播放完成后自动结束，不支持用户手动操作的直播形式；在自动播放期间，直播发起者和参加者都只能观看"
              placement="right"
            >
              <i class="el-icon-question" />
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="是否公开" :label-width="formLabelWidth" prop="openView">
            <el-radio-group v-model="form.openView" size="small">
              <el-radio :label="0">
                公开
              </el-radio>
              <el-radio :label="1">
                不公开
              </el-radio>
            </el-radio-group>
            <el-tooltip
              class="item"
              effect="dark"
              content="不公开直播是指直播不会在直播列表中展示，用户只能通过分享的链接观看直播"
              placement="right"
            >
              <i class="el-icon-question" />
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="参加条件" :label-width="formLabelWidth" prop="joinCondition">
            <el-checkbox
              v-model="form.joinCondition"
              :true-label="1"
              :false-label="0"
              :disabled="!!form.liveStatus"
            >
              参加人员必须登录后观看
            </el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="开始时间"
            :label-width="formLabelWidth"
            prop="dayStart"
            style="display:inline-block"
          >
            <el-date-picker
              v-model="form.dayStart"
              type="date"
              placeholder="选择日期"
              :editable="false"
              :format="'yyyy-MM-dd'+' '+ dayWeek"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              :disabled="!!form.liveStatus"
              @change="dateChange"
            />

            -
          </el-form-item>
          <el-form-item
            label=""
            label-width="0"
            prop="timeStart"
            style="display:inline-block"
          >
            <el-time-select
              v-model="form.timeStart"
              style="width:120px"
              :disabled="!!form.liveStatus"
              :picker-options="pickerTImeOptions"
              placeholder="选择时间"
            />
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="直播描述" :label-width="formLabelWidth" prop="content">
            <el-input
              v-model="form.content"
              type="textarea"
              :rows="4"
              show-word-limit
              maxlength="200"
              placeholder="请输入内容"
            />
          </el-form-item>
        </el-col>
        <el-col v-if="form.liveType" :span="24">
          <el-form-item label="录播文件" :label-width="formLabelWidth" prop="videotapeList">
            <el-upload
              ref="uploadPicture"
              class="upload-demo"
              :action="uploadVideoHost"
              :on-change="handleChange"
              accept=".flv"
              :data="otherData"
              :auto-upload="false"
              list-type="text"
              :on-remove="handleRemove"
              :on-exceed="handleExceed"
              :limit="1"
              :disabled="!!form.liveStatus"
              :file-list="form.videotapeList"
            >
              <el-button
                v-if="!form.videotapeList.length"
                size="small"
                type="primary"
                :disabled="!!form.liveStatus"
              >
                上传文件
              </el-button>
              <span slot="tip" class="el-upload__tip">
                仅支持flv格式的文件，视频时长请尽量小于60分钟
              </span>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="封面图片" :label-width="formLabelWidth" prop="videoPic">
            <el-upload
              ref="uploadVideoPicUrl"
              :class="form.videoPic.length?'hideBtnImg':'showBtnImg'"
              :on-change="VideoPicChange"
              :on-remove="VideoPicRemove"
              name="filedatas"
              accept="image/*"
              :data="otherData"
              :action="uploadPictureHost"
              :auto-upload="false"
              list-type="picture-card"
              :limit="1"
              :file-list="form.videoPic"
            >
              <i class="el-icon-plus" />
            </el-upload>
            <span>上传直播封面</span>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { getCookie } from '@/utils/cookieUtils'
import { Loading } from 'element-ui'
import axios from 'axios'
import dayjs from 'dayjs'
import { addLive, addvideo, editLive } from '@/api/liveList'
import { getTaskList, getDetailPageTaskList } from '@/utils/taskUtils'
export default {
  components: {
  },
  props: { visible: Boolean, isEdit: Boolean, formData: Object },
  data() {
    return {
      loading: false, //搜索框加载状态
      uploadLoading: null, //文件上传loading
      dialogFormVisible: this.visible, //控制弹出框
      formLabelWidth: '120px',
      photoList: [],
      uploadBtnDisabled: false,
      videoIsEdited: false,
      picIsEdited: false,
      uploadPictureHost: process.env.VUE_APP_PICTURE_API + '/file/pictures',
      uploadVideoHost: process.env.VUE_APP_PICTURE_API + '/live/ossUpload',
      isMobile: window.innerWidth <= 960,
      video: {},
      dayWeek: '',
      pickerOptions: {
        disabledDate(time) {
          let curDate = (new Date()).getTime()
          let one = 30 * 24 * 3600 * 1000
          let oneMonths = curDate + one
          return time.getTime() < (Date.now() - 8.64e7) || time.getTime() > oneMonths
        },

      },
      otherData: {
        source: 'picture',
        userUid: this.$store.state.user.userInfo.uid,
        projectName: 'blog',
        sortName: 'moment',
        token: getCookie('token'),
      },
      form: {
        title: '',
        // summary: null,
        liveType: 0,
        openView: 0,
        joinCondition: 0,
        dayStart: '',
        timeStart: '',
        content: '',
        videotapeList: [],
        videoPic: [],
        videotapePath: '',
        videotapeName: '',
        expectStartTime: '',
        fileUid: '',
        taskId: 0,
      },
      changeFileList: [],
      videoPicFileList: [],
      taskListData: [], //任务列表
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' },
        ],
        liveType: [
          { required: true, message: '请选择直播类型', trigger: 'blur' },
          { pattern: /^[0-9]\d*$/, message: '请选择直播类型' },
        ],
        openView: [
          { required: true, message: '请选择是否公开', trigger: 'blur' },
          { pattern: /^[0-9]\d*$/, message: '请选择是否公开' },
        ],
        dayStart: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        timeStart: [
          { required: true, message: '请选择开始时间', trigger: 'change' },
        ],
        videotapeList: [
          { required: true, type: 'array', message: '请选择录播文件', trigger: 'change' },
        ],

      },
    }
  },
  computed: {
    pickerTImeOptions() {
      const date = new Date()
      const today = dayjs().startOf('day').format('YYYY-MM-DD')
      let pickerTImeOption = {}
      if (today == this.form.dayStart) {
        const hours = date.getHours()
        let minutes = date.getMinutes()
        if (minutes < 10) {
          minutes = '0' + minutes

        }

        pickerTImeOption = {
          start: hours + ':' + (minutes),
          step: '00:01',
          end: '23:59',
        }
      } else {
        pickerTImeOption = {
          start: '00:00',
          step: '00:01',
          end: '23:59',
        }
      }

      return pickerTImeOption
    },

  },
  watch: {
    visible: function() {
      this.dialogFormVisible = this.visible
    },
  },

  mounted() {
    this.taskListData = []
    this.getDictList()
  },
  methods: {

    //日期格式化

    getNowTime() {
      const date = new Date()
      const hours = date.getHours()
      const minutes = date.getMinutes()
      const tomorrow = dayjs().startOf('day').add(1, 'day').format('YYYY-MM-DD')
      const today = dayjs().startOf('day').format('YYYY-MM-DD')
      let day = today

      let selectMinute = '00'
      let selectHour = hours
      if (hours == 23 && minutes > 29) {
        selectMinute = '00'
        day = tomorrow

      } else if (minutes < 30) {
        selectMinute = '30'
      } else {
        selectHour = selectHour + 1
      }
      const weekArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      const index = dayjs(day).day()
      this.dayWeek = weekArr[index]

      return { dayStart: day, timeStart: selectHour + ':' + selectMinute }

    },
    //上传成功
    uploadSuccess: async function(val, file) {
      const paramse = { //阿里的接口参数 格式固定
        name: file.name,
        key: val.dir + file.name,
        policy: val.policy,
        OSSAccessKeyId: val.accessid,
        // eslint-disable-next-line camelcase
        success_action_status: '200',
        signature: val.signature,
      }
      let formData = new FormData()
      Object.keys(paramse).forEach(key => formData.append(key, paramse[key]))
      formData.append('file', file)

      axios.defaults.headers.accept = '*'
      axios.defaults.withCredentials = false
      await axios.post(val.host, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }).then((response) => {
        if (response.status == 200) {
          const obj = {
            name: file.name,
            path: val.dir + file.name,

          }
          //const path = val.dir + file.name
          this.video = obj
        } else {
          if (this.uploadLoading) {
            this.uploadLoading.close()
          }
          this.$commonUtil.message.error('录播文件上传失败')
        }

      })

    },

    /**
     * 字典查询
     */
    async getDictList() {
      if (this.isEdit) {

        const expectStartTime = this.formData.expectStartTime.split(' ')
        let time = expectStartTime
        const weekArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']

        const dayStart = time[0]
        const index = dayjs(dayStart).day()
        this.dayWeek = weekArr[index]
        const timeStart = time[1].slice(0, 5)
        let videoPic = []
        if (this.formData.photoList && this.formData.photoList.length) { //封面
          videoPic = [{ url: this.formData.photoList[0] }]
        }
        let videotapeList = []
        if (this.formData.videotapeName) {
          videotapeList = [{ name: this.formData.videotapeName }]
        }

        this.form = { ...this.formData, videotapeList, videoPic, dayStart, timeStart }
        const taskItem = await getDetailPageTaskList(this.formData.uid)
        if (taskItem) {
          this.taskListData = [taskItem]
          this.form = { ...this.form, taskId: taskItem.id }
        }

      } else {
        this.form = this.getFormObject()
        if (this.$store.state.app.selectedTask) {
          this.form = { ...this.form, taskId: this.$store.state.app.selectedTask.id }
          this.taskListData = [{ id: this.$store.state.app.selectedTask.id, name: this.$store.state.app.selectedTask.name }]
        }
      }
    },

    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limitCountImg} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    getFormObject: function() {
      var formObject = {
        title: '',
        // summary: null,
        liveType: 0,
        openView: 0,
        joinCondition: 0,
        dayStart: '',
        timeStart: '',
        content: '',
        videotapeList: [],
        videoPic: [],
        videotapePath: '',
        videotapeName: '',
        expectStartTime: '',
        fileUid: '',
        taskId: '',


      }
      const obj = this.getNowTime()
      return { ...formObject, ...obj }
    },
    async remoteMethod(v) {
      if (v) {
        if (this.isEdit) {
          this.taskListData = await getTaskList(v, 9, this.formData.createTime)
        } else {
          this.taskListData = await getTaskList(v, 9)
        }
      }

    },
    VideoPicChange: function(file, fileList) {
      this.form = { ...this.form, videoPic: fileList }
      this.picIsEdited = true

    },
    VideoPicRemove: function() {
      this.form = { ...this.form, videoPic: [], fileUid: '' }
      this.picIsEdited = true

    },
    handleChange: function(file, fileList) {
      this.videoIsEdited = true
      this.form = { ...this.form, videotapeList: fileList }
      this.uploadBtnDisabled = fileList.length >= 1
      this.$nextTick(() => {
        this.$refs.form.validateField('videotapeList')
      })
    },
    handleRemove(file, fileList) {
      this.uploadBtnDisabled = fileList.length >= 1
      this.videoIsEdited = true
      this.form = { ...this.form, videotapeList: [], videotapePath: '', videotapeName: '' }

    },
    dateChange: function(val) {
      const weekArr = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      if (!val) return
      const getWeek = dayjs(val).day()
      this.dayWeek = weekArr[getWeek]

    },
    async uploadFiles(uploadFiles, data, action, type, { success, error }) {
      return new Promise((resolve, reject) => {
        let form = new FormData()
        // 文件对象
        if (type == 'picture') {
          uploadFiles.map(file => form.append('filedatas', file.raw))
        } else {
          uploadFiles.map(file => form.append('file', file.raw))
        }

        // 附件参数
        for (let key in data) {
          form.append(key, data[key])
        }
        let xhr = new XMLHttpRequest()
        // 异步请求
        xhr.open('post', action, true)
        // 设置请求头
        xhr.setRequestHeader('Authorization', getCookie('token'))
        xhr.onreadystatechange = () => {
          if (xhr.readyState == 4) {
            if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
              success && success(xhr.responseText)
              resolve('resolve')
            } else {
              error && error(xhr.status)
              reject()
            }
          }
        }
        xhr.send(form)


      })


    },
    submitForm: function() {
      this.$refs.form.validate(async(valid) => {


        if (!valid) {
          return
        }
        const params = JSON.parse(JSON.stringify(this.form))
        params.title = params.title.trim()
        params.content = params.content.trim()
        params.weekday = this.dayWeek
        params.expectStartTime = this.form.dayStart + ' ' + this.form.timeStart + ':00'
        if (!params.liveType) {
          params.videotapeList = []
        }
        this.uploadLoading = Loading.service({
          lock: true,
          text: '正在努力上传中……',
        })

        if (params.videoPic.length && this.picIsEdited) {
          let { uploadFiles, action, data } = this.$refs.uploadVideoPicUrl
          let type = 'picture'
          const flag = this.judgeTypeImage(uploadFiles)
          if (flag) {
            return
          }
          // this.uploadLoading = Loading.service({
          //   lock: true,
          //   text: '正在努力上传中……',
          // })
          await this.uploadFiles(
            uploadFiles,
            data,
            action,
            type,
            {
              success: (response) => {
                let res = JSON.parse(response)
                if (res.code == this.$ECode.SUCCESS) {
                  let pictureList = res.data
                  let list = []
                  for (let a = 0; a < pictureList.length; a++) {
                    let picture = {}
                    picture.uid = pictureList[a].uid
                    list.push(picture)
                  }

                  params.fileUid = list[0].uid

                  // }
                } else {
                  this.$commonUtil.message.error(res.data)
                }
                //this.uploadLoading.close()
              },
              error: () => {
                if (this.uploadLoading) {
                  this.uploadLoading.close()
                }
                this.$commonUtil.message.error('封面图片上传失败')
              },
            })

        }
        if (params.videotapeList.length && this.videoIsEdited) {

          let { uploadFiles } = this.$refs.uploadPicture
          const flag = this.judgeTypeVideo(uploadFiles)
          if (flag) {
            return
          }
          // this.uploadLoading = Loading.service({
          //   lock: true,
          //   text: '正在努力上传中……',
          // })
          const res = await addvideo()

          if (res.code == this.$ECode.SUCCESS) {
            const file = uploadFiles[0]
            await this.uploadSuccess(res.data, file.raw)
            //this.uploadLoading.close()
            params.videotapePath = this.video.path
            params.videotapeName = this.video.name
          } else {
            if (this.uploadLoading) {
              this.uploadLoading.close()
            }
            this.$commonUtil.message.error(res.message)
          }


        }

        if (params.taskId == '') {
          params.taskId = null
        }
        if (this.isEdit) {
          editLive(params).then(response => {
            if (response.code == this.$ECode.SUCCESS) {
              if (this.uploadLoading) {
                this.uploadLoading.close()
              }
              this.$commonUtil.message.success(response.message)
              this.$emit('beforeClose')
              setTimeout(() => {
                location.reload()
              }, 500)
            } else {
              if (this.uploadLoading) {
                this.uploadLoading.close()
              }
              this.$commonUtil.message.error(response.message)
            }
          })

        } else {
          addLive(params).then(response => {

            if (response.code == this.$ECode.SUCCESS) {
              if (this.uploadLoading) {
                this.uploadLoading.close()
              }
              this.$commonUtil.message.success(response.message)
              this.$emit('beforeClose')
              setTimeout(() => {
                location.reload()
              }, 500)
            } else {
              if (this.uploadLoading) {
                this.uploadLoading.close()
              }
              this.$commonUtil.message.error(response.message)
            }
          })
        }

      })
    },
    judgeTypeImage(uploadFiles) {
      for (let i = 0; i < uploadFiles.length; i++) {
        //获取最后一个.的位置
        let filePath = uploadFiles[i].name
        let index = filePath.lastIndexOf('.')
        //获取后缀
        let ext = filePath.substr(index + 1)
        let isCanUpload = this.isAssetTypeAnImage(ext)
        if (!isCanUpload) {
          if (this.uploadLoading) {
            this.uploadLoading.close()
          }
          this.$message.error('请上传png、 jpg、jpeg、 bmp、gif、jfif、webp、tiff格式的图片!')
          return true
        }

        const isLt20M = uploadFiles[i].size / 1024 / 1024 < 5
        if (!isLt20M) {
          if (this.uploadLoading) {
            this.uploadLoading.close()
          }
          this.$message.error('单个图片大小不能超过 5MB!')
          return true
        }
      }
    },
    isAssetTypeAnImage(ext) {
      return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'jfif', 'webp', 'tiff'].indexOf(ext.toLowerCase()) !== -1
    },
    judgeTypeVideo(uploadFiles) {
      for (let i = 0; i < uploadFiles.length; i++) {
        //获取最后一个.的位置
        let filePath = uploadFiles[i].name
        let index = filePath.lastIndexOf('.')
        //获取后缀
        let ext = filePath.substr(index + 1)
        let isCanUpload = this.isAssetTypeAnVideo(ext)
        if (!isCanUpload) {
          if (this.uploadLoading) {
            this.uploadLoading.close()
          }
          this.$message.error('请上传flv格式的文件!')
          return true
        }
        const isLt500M = uploadFiles[i].size / 1024 / 1024 < 500
        if (!isLt500M) {
          if (this.uploadLoading) {
            this.uploadLoading.close()
          }
          this.$message.error('录播文件不能超过 500MB!')
          return true
        }
      }
    },
    isAssetTypeAnVideo(ext) {
      return ['flv'].indexOf(ext.toLowerCase()) !== -1
    },


    beforeUnload: function(e) {
      if (this.isSubmitOp) {
        return
      }
      let event = e || window.event
      // 兼容IE8和Firefox 4之前的版本
      if (event) {
        event.returnValue = '关闭提示'
      }
      // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
      return '关闭提示'
    },
    cancel: function() {
      this.$emit('beforeClose', '')
    },
    // 关闭时的回调
    beforeClose(done) {
      //取消时，开始状态
      this.$emit('beforeClose', '')
      done()
    },

  },
}
</script>

<style lang="less" scoped>
.el-form {
  overflow: hidden;
}
.showBtnImg,.hideBtnImg{
  display: inline-block;
  vertical-align: middle;
}
.hideBtnImg{

  ::v-deep .el-upload--picture-card{
    display:none;   /* 上传按钮隐藏 */
  }
  ::v-deep .el-upload--text{
    display:none;   /* 上传按钮隐藏 */
  }
}
::v-deep .el-tooltip__popper{
  max-width: 200px;
}
.liveForm{
  ::v-deep .el-input {min-width: 148px; }
  ::v-deep .el-textarea {min-width: 148px; }
}
</style>
