import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import i18n from '@/components/TUIRoom/locales/'
import store from './store'
import eventBus from '@/utils/_eventBus'
import { isMobile } from '@/utils/utils'
import './icons'

// 引入公共JS
import '@/assets/iconfont/iconfont.css'
import '../static/css/ckeditor.css'
import '../static/css/index.css'
import '@/assets/js/commin.js'
import prototype from './utils/prototype'

import VueLazyload from 'vue-lazyload'
import VueFoldable from 'vue-foldable'
import 'vue-foldable/dist/vue-foldable.css'
// 管理html元信息
import VueMeta from 'vue-meta'
// 引入社会化分享插件
import Share from 'vue-social-share'
import '@/assets/css/vue-social-share.css'

import xss from 'xss'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

// 引入数学公式
import MathJax from './assets/js/MathJax.js'


import hljs from 'highlight.js'
import 'highlight.js/styles/github.css'

Vue.use(VueMeta)
Vue.use(Share)
// 定义全局XSS解决方法
Object.defineProperty(Vue.prototype, '$xss', {
  value: xss,
})

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/img/loading.gif'),
  loading: require('./assets/img/loading.gif'),
  attempt: 1,
})
Vue.component('Foldable', VueFoldable)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.use(ElementUI)
Vue.use(i18n)

// 引入全局工具类
Vue.use(prototype)
Vue.use(mavonEditor)

Vue.directive('highlight', (el) => {
  const blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    // @ts-ignore
    hljs.highlightBlock(block)
  })
})
Vue.prototype.MathJax = MathJax

Vue.prototype.$eventBus = eventBus

Vue.prototype.$isMobile = isMobile()

new Vue({
  pinia,
  store,
  // @ts-ignore
  router,
  render: h => h(App),
}).$mount('#app')
