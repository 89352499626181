import request from '@/utils/request'

/**
 * 第三方登录
 * @param params
 */
export function login(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/render',
    method: 'post',
    params,
  })
}

export function authVerify(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/verify/' + params,
    method: 'get',
  })
}

export function editUser(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/editUser',
    method: 'post',
    data: params,
  })
}
export function editMobile(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/auth/updateMobile',
    method: 'post',
    data: params,
  })
}
//修改密码
export function editPwd(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/auth/updatePwd',
    method: 'post',
    data: params,
  })
}


/**
 * 更新用户密码
 * @param params
 */
export function updateUserPwd(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/updateUserPwd',
    method: 'post',
    data: params,
  })
}

/**
 * 获取用户反馈
 * @param params
 */
export function getFeedbackList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/getFeedbackList',
    method: 'get',
    params,
  })
}

/**
 * 新增反馈
 * @param params
 */
export function addFeedback(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/addFeedback',
    method: 'post',
    data: params,
  })
}

export function replyBlogLink(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/replyBlogLink',
    method: 'post',
    data: params,
  })
}

export function deleteUserAccessToken(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/delete/' + params,
    method: 'post',
  })
}
//手机号密码登录接口
export function localLogin(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/auth/login/mobilePassword',
    method: 'post',
    data: params,
  })
}
//手机号验证码登录接口
export function mobileValidLogin(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/auth/login/mobileValidCode',
    method: 'post',
    data: params,
  })
}
//微信账号是否已绑定其他账号

export function wechatRepeatBind(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/user/wechatBindMobile',
    method: 'post',
    data: params,
  })
}
// 通过手机号查询用户信息判断手机号是否激活是否存在
export function getUserByMobile(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/user/detail',
    method: 'get',
    params,
  })
}

// 小程序
export function getCompleteLogin(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/user/completeInfo/userAccount',
    method: 'post',
    data: params,
  })
}
//微信换绑手机号

export function wechatChangeBindMobile(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/user/wechatChangeBindMobile',
    method: 'post',
    data: params,
  })
}
/**
 * PC端登录
 * @param params
 */
export function loginRegister(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/auth/login/user',
    method: 'post',
    data: params,
  })
}

/**
 * PC端完善信息
 * @param params
 */
export function bindMobile(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/user/completeInfo/wechat',
    method: 'post',
    data: params,
  })
}
/**
 * 短信验证码
 * @param params
 */
export function getMsgCode(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/msg/msgCode',
    method: 'get',
    params,
  })
}
/**
 * 短信验证码
 * @param params
 */
export function checkMobile(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/user/checkMobile',
    method: 'get',
    params,
  })
}
/**
 * 本地注册
 * @param params
 */
export function localRegister(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/auth/register',
    method: 'post',
    data: params,
  })
}

export function logout(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/user/logout',
    method: 'post',
    data: params,
  })
}

/**
 * 获取微信公众号登录二维码
 * @param params
 * @returns {*}
 */
export function getWechatOrCodeTicket(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/wechat/getWechatOrCodeTicket',
    method: 'get',
    data: params,
  })
}

export function getUserLoginStatus(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/wechat/getUserLoginStatus',
    method: 'get',
    params,
  })
}


export function checkValidCode(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/wechat/checkValidCode',
    method: 'get',
    params,
  })
}

export function getLoadingValid(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/wechat/getLoadingValid',
    method: 'post',
    data: params,
  })
}

/**
 * 获取第三方账号绑定信息
 * @param params
 * @returns {*}
 */
export function getAccountBindList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/oauth/getAccountBindList',
    method: 'post',
    data: params,
  })
}
export function getBindAccountList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/user/checkBindAccount',
    method: 'get',
    params,
  })
}

