/**
 * task任务下拉常用的一些工具类
 */
import dayjs from 'dayjs'
import { getSelectTaskList, getDetailTaskList } from '@/api/taskContent'
export async function getTaskList(keyword, taskTypeDetail, createTime) {
  let params = {
    taskType: 1, //发布任务
    taskTypeDetail: taskTypeDetail,
    currentPage: 1,
    pageSize: 100,
  }
  if (createTime) {
    params.taskTime = createTime//创建时间

  } else {
    params.taskTime = dayjs(`${new Date()}`).format('YYYY-MM-DD HH:mm:ss') //当前时间
  }
  if (keyword) {
    params.name = keyword //关键词
  }
  // if (name) {
  //   params.name = name //任务名称 唯一
  // }

  const res = await getSelectTaskList(params)
  const taskListData = res.data && res.data.records || []

  return taskListData
}


export async function getDetailPageTaskList(resourceId, taskId) {
  let params = {}
  if (resourceId) {
    params.resourceId = resourceId
  }
  if (taskId) {
    params.taskId = taskId
  }
  const res = await getDetailTaskList(params)
  const taskList = res.data || []
  let item = null
  if (taskList.length) {
    item = taskList.find(x => x.taskType == 1)
  }

  return item
}
