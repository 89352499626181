import request from '@/utils/request'

export function getBlogByLevel(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getBlogByLevel',
    method: 'get',
    params
  })
}

export function getNewBlog(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getNewBlog',
    method: 'post',
    data: params
  })
}

export function getBlogByTime(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getBlogByTime',
    method: 'get',
    params
  })
}

export function getHotBlog(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getHotBlog',
    method: 'get',
    params
  })
}

export function getHotTag(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getHotTag',
    method: 'get',
    params
  })
}

export function getHotBlogSort(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getHotBlogSort',
    method: 'get',
    params
  })
}

export function getLink(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getLink',
    method: 'get',
    params
  })
}

export function addLinkCount(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/addLinkCount',
    method: 'get',
    params
  })
}

export function getWebConfig(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getWebConfig',
    method: 'get',
    params
  })
}

export function getWebNavbar(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getWebNavbar',
    method: 'get',
    params
  })
}

export function recorderVisitPage(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/recorderVisitPage',
    method: 'get',
    params
  })
}

export function recorderBehavior(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/recorderBehavior',
    method: 'post',
    data: params
  })
}

export function getUserTopN(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/index/getUserTopN',
    method: 'post',
    data: params
  })
}

