import request from '@/utils/request'

export function getLiveList(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/live/list',
    method: 'post',
    data: params,
  })
}


export function addLive(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/live/add',
    method: 'post',
    data: params,
  })
}
export function addvideo(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/live/ossUpload',
    method: 'post',
    data: params,
  })
}

export function editLive(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/live/edit',
    method: 'post',
    data: params,
  })
}

export function deleteLiveApi(params) {
  return request({
    url: process.env.VUE_APP_WEB_API + '/live/delete?uid=' + params,
    method: 'post',

  })
}

