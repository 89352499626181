import Vue from 'vue'
import Router from 'vue-router'
import HomeIndex from '@/views/home'
import store from '../store'
import { getCookie } from '@/utils/cookieUtils'
Vue.use(Router)
const getUrllets = () => {
  let lets = {}
  let url = window.location.href
  url = decodeURI(url)
  url.replace(
    /[?&]+([^=&]+)=([^&#]*)/gi,
    (m, key, value) => {
      lets[key] = value
    },
  )
  return lets
}
export const constantRouterMap = [
  {
    path: '/',
    component: HomeIndex,
    children: [
      { path: '/', component: () => import('@/views/index') },
      { path: '/noData', component: () => import('@/views/noData') },
      { path: '/about', component: () => import('@/views/about') },
      { path: '/life', component: () => import('@/views/life') },
      { path: '/list', component: () => import('@/views/list') },
      { path: '/share', component: () => import('@/views/share') },
      { path: '/subject', component: () => import('@/views/subject') },
      { path: '/time', component: () => import('@/views/time') },
      { path: '/rank', component: () => import('@/views/rank') },
      {
        path: '/info/:blogOid',
        component: () => import('@/views/info'),
        name: 'Info',
      },
      {
        path: '/mInfo/:blogOid',
        component: () => import('@/views/MomentInfo'),
        name: 'MomentInfo',
      },
      { path: '/messageBoard', component: () => import('@/views/messageBoard') },
      { path: '/question', component: () => import('@/views/question') },
      {
        path: '/qInfo/:questionOid',
        component: () => import('@/views/questionInfo'),
        name: 'QInfo',
      },
      { path: '/code', component: () => import('@/views/code') },
      {
        path: '/cInfo/:problemOid',
        component: () => import('@/views/codeInfo'),
      },
      { path: '/userCenter', component: () => import('@/views/userCenter') },
      { path: '/notice', component: () => import('@/views/notice') },
      { path: '/moment', name: 'moment', component: () => import('@/views/moment') },
      // { path: '/chat2', component: () => import('@/views/chat') },
      { path: '/chat', component: () => import('@/views/chatRoom') },
      { path: '/media', component: () => import('@/views/mediaInfoList') },
      { path: '/liveList', component: () => import('@/views/liveList') },
      { path: '/live/:liveId', component: () => import('@/views/live'), name: 'Live' },
      { path: '/answerList', component: () => import('@/views/answerList') },
      { path: '/answer/:answerId', component: () => import('@/views/answer') },
      { path: '/taskList', component: () => import('@/views/taskList') },
      { path: '/task/:taskId', component: () => import('@/views/task') },
      { path: '/taskContent', component: () => import('@/views/TaskContent') },
      {
        path: '/workTable', component: () => import('@/views/workTable/index'),
        meta: { title: '工作台' },
        redirect: '/workTable/meetingList', // 该配置是若点击选择一级菜单时，默认选中并跳转到该一级菜单下的第二个二级菜单
        children: [
          {
            path: 'accountBind',
            component: () => import('@/views/workTable/accountBind.vue'),
            meta: { title: '账号绑定' }, //hideSideMenu: true 隐藏头: 真
          },
          {
            path: 'meetingList',
            component: () => import('@/views/workTable/meetingList.vue'),
            meta: { title: '我的会议' },
          },
          { path: 'meeting/:meetingId', component: () => import('@/views/workTable/meeting'), name: 'meeting', meta: { hideSideMenu: true } },
          { path: 'meetingDetail/:uid', component: () => import('@/views/workTable/meetingDetail'), name: 'meetingDetail', meta: { hideSideMenu: true } },
          { path: 'meetingPlayer', component: () => import('@/views/workTable/meetingPlayer'), name: 'meetingPlayer', meta: { hideSideMenu: true } },
        ],
        beforeEnter: (to, from, next) => {
          const token = getCookie('token') || ''
          const urlToken = getUrllets()['token']
          const tokenFlog = token || urlToken || store.state.user.isLogin || localStorage.getItem('isLogin')
          if (tokenFlog || to.name === 'meeting' || to.name === 'meetingDetail') {
            next()

          } else if (from.path == '/') {

            Vue.prototype.$notify.error({
              title: '警告',
              message: '登录后才可以进入工作台哦~',
              offset: 100,
            })
            next({ path: '/', query: { needLogin: true } })
          } else {
            Vue.prototype.$notify.error({
              title: '警告',
              message: '登录后才可以进入工作台哦~',
              offset: 100,
            })
            store.commit('setLoginMessage', Math.random())
            next(false)
          }
        },

      },

    ],
  },
  { path: '/authRedirect', component: () => import('@/views/authRedirect') },
  { path: '/404', component: () => import('@/views/404') },
  { path: '/500', component: () => import('@/views/500') },
  { path: '/502', component: () => import('@/views/502') },
  { path: '/*', component: () => import('@/views/404') },

]

const router = new Router({
  mode: 'history',
  routes: constantRouterMap,
})

export default router
