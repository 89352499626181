export default {
  // 设置sdkAppId
  'SET_SDK_APP_ID'(state, value) {
    state.sdkAppId = value
  },
  // 设置userSig
  'SET_USER_SIG'(state, value) {
    state.userSig = value
  },
  // 设置播放域名
  'SET_PLAYER_DOMAIN'(state, value) {
    state.playerDomain = value
  },
  // 更新直播间ID
  'SET_ROOM_ID'(state, value) {
    state.roomId = value
  },
  // 更新直播间昵称
  'SET_ROOM_NAME'(state, value) {
    state.roomName = value
  },
  // 更新用户信息
  'UPDATE_USER_INFO'(state, data) {
    state.userInfo = data
  },
  // 设置主播用户ID
  'SET_ANCHOR_USER_ID'(state, data) {
    state.anchorUserId = data
  },
  // 更新直播间阶段
  'UPDATE_LIVE_STAGE'(state, value) {
    state.liveStage = value
  },
  // 更新播放线路
  'UPDATE_LINE_TYPE'(state, value) {
    state.lineType = value
  },
  // 更新流播放状态
  'UPDATE_PLAY_STATE'(state, value) {
    state.playState = value
  },
  // 更新使用的摄像头设备
  'UPDATE_ACTIVE_CAMERA'(state, device) {
    state.activeCamera = device
  },
  // 更新使用的麦克风设备
  'UPDATE_ACTIVE_MICROPHONE'(state, device) {
    state.activeMicrophone = device
  },
  // 更新使用的扬声器设备
  'UPDATE_ACTIVE_SPEAKER'(state, device) {
    state.activeSpeaker = device
  },
  // 更新是否设置本地流镜像
  'UPDATE_SET_MIRROR'(state, value) {
    state.isSetMirror = value
  },
  // 更新是否设置本地流镜像
  'UPDATE_OPEN_BEAUTY'(state, value) {
    state.isOpenBeauty = value
  },
  // 更新视频参数
  'UPDATE_VIDEO_PROFILE'(state, data) {
    state.videoProfile = data
  },
  // 更新美颜参数
  'UPDATE_BEAUTY_PARAM'(state, data) {
    state.beautyParam = data
  },
  // 更新是否录制直播
  'UPDATE_RECORD_LIVE'(state, value) {
    state.isRecordLive = value
  },
  // 更新视频mute状态
  'UPDATE_VIDEO_STATE'(state, value) {
    state.isVideoMuted = value
  },
  // 更新音频mute状态
  'UPDATE_AUDIO_STATE'(state, value) {
    state.isAudioMuted = value
  },
  // 更新音量大小
  'UPDATE_AUDIO_LEVEL'(state, value) {
    state.audioLevel = value
  },
  // 更新上行网络等级
  'UPDATE_UPLINK_NETWORK_LEVEL'(state, value) {
    state.uplinkQualityLevel = value
  },
  // 更新横竖屏
  'UPDATE_LAYOUT'(state, value) {
    state.layout = value
  },
  // 设置当前浏览器是否支持 webRTC
  'SET_IS_SUPPORT_WEBRTC'(state, value) {
    state.isSupportWebRTC = value
  },
  // 设置当前浏览器是否支持 webRTC
  'SET_MEMBER_NUM'(state, value) {
    state.memberNum = value
  },
}
