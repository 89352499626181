<template>
  <div>
    <div v-if="showLogin == true" class="boxweixin loginBox">
      <div class="loginWrap">
        <div class="loginLeft" :style="{backgroundImage:'url('+loginLeftImg+')'}">
          <h2>七里香大健康云</h2>
          <P>专注于医疗健康知识信息分享的社区平台</P>
        </div>
        <div class="loginright">
          <div class="title">
            <span v-show="!isPhoneLogin&&!isLogin" class="back-btn" @click="goBackPage"><i class="el-icon-arrow-left">返回</i></span>
            <span class="title-text">
              <template v-if="isPhoneLogin">
                <span>
                  <span :class="`mr20 ${passwordFlag?'':'span-active'}`" @click="loginTypeChange()">动态码登录</span><span :class="`${passwordFlag?'span-active':''}`" @click="loginTypeChange('passwordFlag')">密码登录</span>
                </span>
              </template>
              <template v-else><span v-show="isLogin" class="icon iconfont icon-weixin" />{{ isLogin?'使用微信扫一扫授权':'微信扫码绑定' }}</template>
            </span>
            <div class="t2" @click="closeLogin()">
              <i class="el-icon-circle-close" />
            </div>
          </div>

          <!-- 登录表单内容 -->
          <el-form
            v-if="isPhoneLogin"
            ref="phoneForm"
            :label-position="labelPosition"
            :rules="phoneRules"
            :model="phoneForm"
            class="formClasses"
            label-width="0"
          >
            <div>
              <el-form-item prop="mobile" label="">
                <el-input v-model="phoneForm.mobile" placeholder="请输入手机号" @input="checkMobileActive" />
              </el-form-item>
              <!-- 密码输入框只在密码登录方式显示 -->
              <el-form-item v-if="passwordFlag" prop="password" label="">
                <el-input
                  v-model="phoneForm.password"
                  placeholder="请输入密码"
                  :type="eye ? 'password' : 'text'"
                  style="padding-right: 28px;"
                  @keyup.native="()=> {phoneForm.password= phoneForm.password.toString().replace(/(^\s*)|(\s*$)/g, '')}"
                >
                  <el-button slot="append" class="absoluteIcon" @click.prevent="eye=!eye">
                    <svg-icon class="icon" :icon-name="eye?'eye-close':'eye'" />
                  </el-button>
                </el-input>
              </el-form-item>
              <!-- 验证码输入框只在动态码登录方式显示 -->
              <el-form-item v-else prop="validCode" label="">
                <div class="validCodeWrap">
                  <el-input
                    v-model="phoneForm.validCode"
                    placeholder="请输入验证码"
                    class="validCodeInput"
                    :disabled="!phoneForm.mobile"
                  />
                  <el-button
                    type="primary"
                    class="CodeBtn"
                    :disabled="verifyCodeBtnDisabled"
                    @click.prevent="getVerify"
                  >
                    {{ getCodeBtnText }}
                  </el-button>
                </div>
              </el-form-item>

              <el-row class="btn">
                <el-button
                  class="loginBtn"
                  type="primary"
                  :disabled="submitBtnDisabled"
                  @click="phoneLogin"
                >
                  登录
                </el-button>
              </el-row>
            </div>
          </el-form>
          <!-- 登录表单内容结束 -->
          <div v-show="!isPhoneLogin">
            <div id="weixinQr" />
            <div v-show="isLogin" class="weixitip">
              <el-divider content-position="center">
                授权登录即注册
              </el-divider>
            </div>
          </div>

          <div v-show="isPhoneLogin||isLogin" class="toggleLogin" @click="changeLoginType">
            {{ isPhoneLogin?'微信扫码登录注册':'手机号登录' }}
          </div>
        </div>
      </div>
    </div>

    <div class="mask" />
  </div>
</template>

<script>
import { localLogin, getUserByMobile, getMsgCode, mobileValidLogin } from '@/api/user'
import loginLeftImg from '@/assets/img/login.png'
import encryption from '@/utils/encryption'
import { Loading } from 'element-ui'
import { deleteUrlParams } from '@/utils'
export default {
  name: 'Share',
  props: { isBindBack: Boolean, formData: Object },
  data() {
    return {
      loading: null,

      option: {
        fullscreen: true,
        lock: true,
      },
      webConfig: {},
      vueMoguWebUrl: process.env.VUE_APP_VUE_MOGU_WEB,
      showPasswordLogin: true, // 是否显示账号密码登录
      wechatOrCode: '', // 微信公众号登录二维码
      ticket: '', // 用户的票券
      wechatLoginKey: '', // 验证码
      interval: null,
      // 显示登录页面
      showLogin: true,
      isLogin: true, //扫码注册标记 区分扫码绑定
      table: false,
      dialog: false,
      labelPosition: 'right',

      // 登录类别
      verifyCodeBtnDisabled: false, //获取验证码按钮不可点击
      getVerifyCodeBtnText: '发送验证码',
      loginLeftImg: loginLeftImg,
      eye: true,
      isPhoneLogin: false,
      passwordFlag: true, //密码登录
      phoneForm: {
        mobile: '',
        password: '',
        validCode: '', //验证码
      },
      phoneRules: {
        mobile: [
          { required: true, message: '手机号码不能为空', trigger: ['blur', 'change'] },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请填写正确的手机号' },

        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: ['blur', 'change'] },
        ],
      },
      submitBtnDisabled: false, //密码登录按钮不可点击标记
      getCodeBtnText: '发送验证码',
      getVerifyTimer: null,
      url: '',
      preStatusIsPassword: true, //左上角出现返回按钮之前的页面是否是密码登录


    }
  },
  mounted() {


    this.isPhoneLogin = !this.isBindBack
    this.passwordFlag = false
    if (this.isBindBack) {
      this.isLogin = false
      this.isPhoneLogin = false
      this.preStatusIsPassword = !!this.formData.preStatusIsPassword == 'true'
      this.phoneForm.mobile = this.formData.mobile
    }
    this.$nextTick(() => {
      this.renderCode()
    })
    


  },


  methods: {
    // 生成微信登录二维码
    renderCode(mobileFlag) {
      const href = 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4fQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9Cg=='
      let fullPath = this.$route.fullPath
      let url = new URL(`https://www.qlxjk.com${fullPath}`)
      let urlSearchParams = url.searchParams
      deleteUrlParams(urlSearchParams)
      urlSearchParams.append('from', location.origin)
      urlSearchParams.append('preStatusIsPassword', this.passwordFlag)
      if (!this.isLogin && !mobileFlag) {
        urlSearchParams.append('mobile', this.phoneForm.mobile)
      }

      if (mobileFlag) {
        urlSearchParams.append('mobile', this.phoneForm.mobile)
      }
      this.url = url
      // eslint-disable-next-line no-undef
      return new WxLogin({
        id: 'weixinQr',
        appid: 'wxc2f6d000bf24abfb',
        scope: 'snsapi_login',
        // eslint-disable-next-line camelcase
        redirect_uri: encodeURIComponent(this.url.href),
        state: 'openPlatform', //开放平台传openPlatform  公众号传publicAccount
        style: 'black',
        href: href, // 这里修改微信二维码默认样式
      })
    },
    closeLogin: function() {
      this.$emit('closeLoginBox', '')
    },
    changeLoginType() {
      const isPhoneFlag=JSON.parse(JSON.stringify(this.isPhoneLogin))
      this.isPhoneLogin = !isPhoneFlag
      this.isLogin = true
      this.passwordFlag = false

      this.phoneForm = {
        mobile: '',
        password: '',
        validCode: '', //验证码
      }

      if (this.getVerifyTimer) { //清除定时器
        this.getCodeBtnText = '发送验证码'
        clearInterval(this.getVerifyTimer)
      }
      this.verifyCodeBtnDisabled = true//发送验证码置灰
      this.submitBtnDisabled = true
      if (isPhoneFlag) {
        this.renderCode()
      }

    },
    // 通过手机号查询用户信息判断手机号是否激活是否存在
    checkMobileActive() {
      this.$refs.phoneForm.validateField('mobile', (error) => {
        this.submitBtnDisabled = true
        this.verifyCodeBtnDisabled = true
        if (!error) {
          //当校验通过时，这里面写逻辑代码
          let param = {
            mobile: this.phoneForm.mobile,
          }
          this.loading = Loading.service({
            lock: true,
            text: '查询中……',
          })
          if (this.getVerifyTimer) {
            clearInterval(this.getVerifyTimer)
            this.getCodeBtnText = '发送验证码'
          }
          getUserByMobile(param).then(res => {
            if (res.code == this.$ECode.SUCCESS) {
              const data = res.data
              if (data) {
                if (this.passwordFlag) {
                  if (data.mobileActiveStatus) {
                    this.submitBtnDisabled = false
                    this.verifyCodeBtnDisabled = false
                  } else {
                    this.$message({
                      type: 'error',
                      message: '当前手机号未激活，请先使用动态码登录',
                    })

                  }
                } else {
                  this.verifyCodeBtnDisabled = false
                  this.submitBtnDisabled = false
                }

              } else {
                this.$message({
                  type: 'error',
                  message: '手机号不存在',
                })
              }
              if (this.loading) {
                this.loading.close()
              }


            } else {
              this.$message({
                type: 'error',
                message: res.message,
              })
              if (this.loading) {
                this.loading.close()
              }
            }
          })


        }

      })

    },

    //发送验证码
    getVerify: function() {
      this.$refs.phoneForm.validateField('mobile', (errorMessage) => {

        if (!errorMessage) {
          //当校验通过时，这里面写逻辑代码
          this.verifyCodeBtnDisabled = true
          let params = {
            mobile: this.phoneForm.mobile,
            sendMobileType: 'LOGIN',
          }
          this.loading = Loading.service({
            lock: true,
            text: '查询中……',
          })
          getMsgCode(params).then(({ code, message }) => {
            if (code == this.$ECode.SUCCESS) {
              this.submitBtnDisabled = false
              let time = 59
              if (this.getVerifyTimer) {
                clearInterval(this.getVerifyTimer)
                this.getCodeBtnText = '发送验证码'
              }
              this.getVerifyTimer = setInterval(() => {
                if (time > 0) {
                  this.getCodeBtnText = time + 's后获取'
                  time--
                } else {
                  clearInterval(this.getVerifyTimer)
                  this.verifyCodeBtnDisabled = false
                  this.getCodeBtnText = '发送验证码'

                }
              }, 1000)
              if (this.loading) {
                this.loading.close()
              }
            } else {
              this.verifyCodeBtnDisabled = false
              this.$message({
                type: 'error',
                message: message,
              })
              if (this.loading) {
                this.loading.close()
              }
            }
          })


        }

      })


    },
    //动态码登录 密码登录切换
    loginTypeChange(flg) {
      if (flg) {
        if (this.passwordFlag) {
          return
        }
      } else if (!this.passwordFlag) {
        return
      }
      this.passwordFlag = !this.passwordFlag
      this.phoneForm = {}
      //发送验证码和提交按钮置灰
      this.verifyCodeBtnDisabled = true
      this.submitBtnDisabled = true


    },
    //提交
    phoneLogin: function() {
      this.$refs.phoneForm.validate((valid) => {
        if (valid) {
          let params = {}
          params.mobile = this.phoneForm.mobile
          params.password = encryption(this.phoneForm.password)
          params.isRememberMe = 1
          params.validCode = this.phoneForm.validCode
          let url = localLogin//密码登录
          if (!this.passwordFlag) { //动态码登录
            url = mobileValidLogin
          }
          url(params).then(response => {
            if (response.code == this.$ECode.SUCCESS) {
              if (response.data && response.data.token) {
                let search = window.location.search
                let fullPath = this.$route.fullPath
                if (search.includes('?needLogin=true')) {
                  search = search.replace('?needLogin=true', '')
                  fullPath = fullPath.replace('?needLogin=true', '')
                }
                if (search) {
                  window.location.replace(fullPath + '&token=' + response.data.token)
                } else {
                  // 跳转到首页
                  window.location.replace(fullPath + '?token=' + response.data.token)
                }
              } else if (response.data && !response.data.wechatAuthFlag) { //没微信绑定跳转注册的微信扫码页面

                this.isPhoneLogin = false
                this.isLogin = false
                this.preStatusIsPassword = this.passwordFlag
                window.localStorage.setItem('isBind', true)//用户换绑返回
                window.localStorage.setItem('preStatusIsPassword', this.passwordFlag)//用户换绑返回
                window.localStorage.setItem('mobile', this.phoneForm.mobile)//用户换绑返回

                this.renderCode(1)
              }
            } else {
              this.$message({
                type: 'error',
                message: response.message,
              })
            }
          })
        }
      })
    },
    //点击返回按钮回到上一个页面状态
    goBackPage() {
      this.isPhoneLogin = true
      this.passwordFlag = !!this.preStatusIsPassword
      if (this.loading) {
        this.loading.close()
      }
      if (this.getVerifyTimer) { //清除定时器
        clearInterval(this.getVerifyTimer)
        this.verifyCodeBtnDisabled = false
        this.getCodeBtnText = '发送验证码'
      }
      this.submitBtnDisabled = true
      this.phoneForm.validCode = ''

    },


  },
}
</script>


<style>
.boxweixin {
  width: 760px;
  height: 420px;
  background: white;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 101; /* 要比遮罩层大 */
  border-radius: 6px
}

.registerBox {
  height: 660px;
}

.title .t2 {
  font-size: 16px;
  /* float: right; */
  margin-right: 6px;
  margin-top: -6px;
  cursor: pointer;
}
.formClasses{
  padding:0 20px;
  height:220px;
}

.boxweixin .el-divider--horizontal {
  margin: 12px 0;
}

.boxweixin .el-form-item__label {
  margin-left: 10px;
  font-size: 16px;
}


.boxweixin .btn {
  text-align: center;
  padding: 0;
}

.boxweixin .loginBtn {
  width: 100%;
}

.boxweixin .registerBtn {
  width: 40%;
}

.elRow {
  margin-top: 15px;
  text-align: center;
}

.loginTip {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #bababa;
}

.remarksBox {
  position: fixed;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -50px;
  border: 1px solid red;
  width: 200px;
  height: 100px;
  text-align: center;
  z-index: 101; /* 要比遮罩层大 */
}

/* 遮罩层 */
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
</style>
<style lang="less" scoped>
    .loginWrap{
      display:flex;
      height:100%;
      width:100%;
      .loginLeft {
        height:100%;
        width: 320px;
        border-radius: 6px 0 0 6px;
        background-size:100% 100%;
        background-repeat:no-repeat;
        h2{
          color: #fff;
          padding: 70px 0 7px 16px;
        }
        p{
          padding-left: 16px;
          font-size: 14px;
          color: #fff;
        }
      }
      .loginright {
        height:100%;
        flex: 1;
        .title{
          margin-top:10px ;
          .title-text{
            cursor: pointer;
          }
        }
        ::v-deep .formClasses{
          margin:47px 30px 0;
        }
      }
    }
  .boxweixin .title {
    position: relative;
      text-align: center;
      line-height: 48px;
      padding-top: 48px;
      padding-bottom: 5px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      font-size: 23px;
      color: rgba(0,0,0,0.85);
    .t2{
      position: absolute;
      right: -30px;
      top: -30px;
      font-size: 24px;
      color: #fff;

    }
    .back-btn{
      position: absolute;
      left: 10px;
      top: 0;
      cursor: pointer;
      font-size: 14px;
      font-weight: initial;
      color: #666;
    }
  }

  #weixinQr {
    width: 300px;
    margin: 0 auto;
    height: 220px;
    overflow: hidden;
  }
  .icon-weixin{
    color: #00C800;
    font-size: 24px;
    margin-right: 4px;
  }
  .weixitip {
    width: 200px;
    margin: 23px auto;
    ::v-deep .el-divider__text.is-center {
        left: 30%;
        -webkit-transform: translateX(-20%) translateY(-50%);
        transform: translateX(-20%) translateY(-50%);
    }
  }
  .skipAuthorize{
    display: block;
    margin-top: 24px;
    text-align: center;
  }
  .validCodeWrap{
    display: flex;
    justify-content: space-between;

  .validCodeInput{
    min-width:0;
    flex:1;
  }
  .CodeBtn{
    width:120px;
    margin-left: 5px;
  }


}
.warning{
    color: #e6a23c;
    font-size: 16px;
    margin-right: 10px;
  }
.boxmobile{
  width:84%;
  height:70px;
  background: white;
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 101; /* 要比遮罩层大 */
  border-radius: 6px;
  .loginLeft{
    display: none;
  }
  .loginright {
    height:100%;
    flex: 1;
    .title{
      margin-top:10px ;
    }
    ::v-deep .formClasses{
      margin:0 10px;
    }
  }
}
.boxmobile .title {
  position: relative;
    text-align: center;
    line-height: 48px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-family: PingFangSC-Semibold;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
  .t2{
    position: absolute;
    right: -30px;
    top: -30px;
    font-size: 24px;
    color: #fff;

  }
}
.toggleLogin{
  text-align: center;
  cursor: pointer;
  color: #0276ef;
  margin: 0 150px;
}
.absoluteIcon{
  ::v-deep.svg-icon{
    width: 1.2em!important;
    height: 1.2em!important;
  }

}
.validCodeWrap{
    display: flex;
    justify-content: space-between;

  .validCodeInput{
    min-width:0;
    flex:1;
  }
  .CodeBtn{
      width:120px;
      margin-left: 5px;
    }

  }
  .mr20{
    margin-right: 20px;
  }
  .span-active{
    color: #0276ef;
  }
@media only screen and (min-width: 300px) and (max-width: 767px) {
    .boxweixin {
      width:84%;
      .loginLeft{
        display: none;
      }
      .loginright {
        height:100%;
        flex: 1;
        .title{
          margin-top:10px ;
        }
        ::v-deep .formClasses{
          margin:47px 10px 0;

        }
      }
    }
    .toggleLogin{
      margin: 0 90px;
    }


  }
</style>


