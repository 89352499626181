import { Message } from 'element-ui';
// import {showdown} from 'showdown'
// import {TurndownService} from 'turndown'

/** **********************************************************/
/**
 *  全局状态码
 */
const ECode = {
  // 默认页大小
  SUCCESS: 'success',
  // 默认页码
  ERROR: 'error',
};

/**
 * 全局配置文件
 */
const SysConf = {
  defaultAvatar: 'https://oos.moguit.cn/image/favicon.png', // 默认头像
  defaultBackgroundImages: [
    'http://picture.moguit.cn//blog/admin/png/2021/12/4/1638581345820.png',
    'http://picture.moguit.cn//blog/admin/png/2021/12/4/1638581344560.png',
    'http://picture.moguit.cn//blog/admin/png/2021/12/4/1638581348162.png',
    'http://picture.moguit.cn//blog/admin/png/2021/12/4/1638581347581.png',
  ], // 默认背景图

};

/** **********************************************************/

/**
 * 通用工具类
 */
const FUNCTIONS = {

  /**
   * 标签转字符串
   * @param tags
   * @returns {string}
   */
  tagsToString: (tags) => {
    let str = '';
    for (let i = 0; i < tags.length; i++) {
      str += `${tags[i]},`;
    }
    return str.substr(0, str.length - 1);
  },
  // 设置meta信息
  setMetaInfo: (title, content, description) => {
    document.title = title;
    document.querySelector('meta[name="keywords"]').setAttribute('content', content);
    document.querySelector('meta[name="description"]').setAttribute('content',  description);
  },
  // 图片居中调整
  replaceImg: (str) => {
    str = str.replace(/(<img[^>]*>)|(<img[^>]*><\/img>)/g, (match, capture) =>  {
      if (match.indexOf('style') < 0) {
        // 没有style 就添加一个
        return match.replace(/<\s*img/, '<img style=""');
      }
      // 有style 就不做处理 直接返回
      return match;
    });
    str = str.replace(/<img[^>]*>/gi, (match, capture) => match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/ig, 'style="max-height:700px;margin:0 auto; display:block;"'), // 替换style
    );
    return str;
  },
  // 图片居中调整
  replaceMinImg: (str) => {
    str = str.replace(/(<img[^>]*>)|(<img[^>]*><\/img>)/g, (match, capture) =>  {
      if (match.indexOf('style') < 0) {
        // 没有style 就添加一个
        return match.replace(/<\s*img/, '<img style=""');
      }
      // 有style 就不做处理 直接返回
      return match;
    });
    str = str.replace(/<img[^>]*>/gi, (match, capture) => match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/ig, 'style="max-height: 300px; display:block;"'), // 替换style
    );
    return str;
  },
  // iframe居中调整
  replaceMinIframe: (str) => {
    str = str.replace(/(<iframe[^>]*>)|(<iframe[^>]*><\/iframe>)/g, (match, capture) =>  {
      if (match.indexOf('style') < 0) {
        // 没有style 就添加一个
        return match.replace(/<\s*iframe/, '<iframe style=""');
      }
      // 有style 就不做处理 直接返回
      return match;
    });
    str = str.replace(/<iframe[^>]*>/gi, (match, capture) => match.replace(/style\s*?=\s*?([‘"])[\s\S]*?\1/ig, 'style="width: 740px; height: 400px; display:block;"'), // 替换style
    );
    return str;
  },
  /**
   * 字符串转标签
   * @param str
   * @returns {Array}
   */
  stringToTags: (str) => {
    if (str !== null && str !== '') {
      return str.split(',');
    }
    return [];
  },
  // 切割字符串
  splitStr: (str, flagCount) => {
    if (str == null || str == '') {
      return '';
    } if (str.length > flagCount) {
      return `${str.substring(0, flagCount)}...`;
    }
    return str;
  },
  /**
   * 复制文字到剪切板
   * @param text
   */
  copyText: (text) => {
    const oInput = document.createElement('input');
    oInput.value = text;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy'); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
  },
  /**
   * 将Markdown转成Html
   * @param text
   */
  markdownToHtml: (text) => {
    const converter = new showdown.Converter();
    return converter.makeHtml(text);
  },
  /**
   * 将Html转成Markdown
   * @param text
   */
  htmlToMarkdown: (text) => {
    const turndownService = new TurndownService();

    // 用于提取代码语言
    turndownService.addRule('CodeBlock', {
      filter(node, options) {
        return (
          node.nodeName === 'PRE'
          && node.firstChild
          && node.firstChild.nodeName === 'CODE'
        );
      },
      replacement(content, node, options) {
        const className = node.firstChild.getAttribute('class') || '';
        const language = (className.match(/language-(\S+)/) || [null, ''])[1];
        return (
          `\n\n${options.fence}${language}\n${
            node.firstChild.textContent}${options.fence}`
        );
      },
    });

    // 提取数学公式进行转换
    turndownService.addRule('multiplemath', {
      filter(node, options) {
        return node.classList.contains('vditor-math');
      },
      replacement(content, node, options) {
        console.log('中间内容', node.firstChild.textContent);
        return `$$ \n${node.firstChild.textContent}\n $$`;
      },
    });

    const turndownPluginGfm = require('turndown-plugin-gfm');
    const { gfm } = turndownPluginGfm;
    const { tables } = turndownPluginGfm;
    const { strikethrough } = turndownPluginGfm;
    turndownService.use(gfm);
    turndownService.use([tables, strikethrough]);

    return turndownService.turndown(text);
  },
  /**
   * 将Html转成Markdown文件
   * @param title：标题
   * @param text：正文
   */
  htmlToMarkdownFile: (title, text) => {
    title = title || '默认标题';

    const turndownService = new TurndownService();

    const markdown = turndownService.turndown(text);

    // 创建一个blob对象,file的一种
    const blob = new Blob([markdown]);

    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);

    // 配置下载的文件名
    link.download = `${title}.md`;

    link.click();
  },
  /**
   * 通用提示信息
   * @type {{success: message.success, warning: message.warning, error: message.error, info: message.info}}
   */
  message: {
    success(message) {
      Message({
        showClose: true,
        message: message || '成功',
        type: 'success',
      });
    },
    warning(message) {
      Message({
        showClose: true,
        message: message || '警告',
        type: 'warning',
      });
    },
    info(message) {
      Message({
        showClose: true,
        message: message || '提示',
      });
    },
    error(message) {
      Message({
        showClose: true,
        message: message || '异常',
        type: 'error',
      });
    },
  },
};

export default {
  ECode,
  SysConf,
  FUNCTIONS,
};
