<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="agreementWrap">
    <el-dialog
      title="用户协议"
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div v-if="dialogVisible">
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您在开始使用七里香服务前，请您务必仔细阅读并充分理解本《七里香用户使用协议》。同时，您还应仔细阅读并充分理解七里香的隐私政策。在本服务条款中，请您务必特别注意及重点阅读与您的权利及义务密切相关的条款，包括但不限于免责条款等
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
					&#xa0;
          </span>
        </p>
        <p style="margin-bottom:10pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            一、使用协议的接受
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            为使您更好的使用七里香的相关服务，请您仔细阅读以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入七里香。当您注册成功，无论是进入七里香，还是在七里香上发布任何内容，均意味着您完全接受本协议项下的全部条款。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            如您未满
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            14周岁，请在法定监护人的陪同下阅读本用户使用协议，并特别注意未成年人使用的相关条款。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            “用户”指所有直接或间接获取和使用七里香及相关服务的
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            使用者，包括自然人、法人和其他组织等。在本协议中称为“用户”或
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            “您”。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            本协议是您与
          </span>
          <span style="font-family:微软雅黑; font-size:12pt" />
          <span style="font-family:微软雅黑; font-size:12pt">
            江苏人加信息科技有限公司
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            （以下简称“公司”或“我们”）之间就您注册、登录、使用“七里香”网页，并获得七里香提供
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            的相关服务所订立的协议。公司有权依七里香及相关服务或运营的需要单方决定，安排或指定其关联公司、控制公司、继承公司或公司认可的第三方公司继续运营七里香平台。并且，就本协议项
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            下涉及
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            的某些服务，可能会由公司的关联公司、控制公司、继承公司或公司认可的第三方公司向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。
          </span>
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            二、七里香提供的服务
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香不断创新以向其用户提供最优体验。您认知并同意七里香提供
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            的服务的形式和本质可不经事先通知您而不时变换。本软件及相关服务更新或部分服务内容更新后，在可能的情况下，公司将以包括但不限于系统提示、公告、站内信等方式提示用户，用户有权选择接受更新版本或服务，如用户不接受，部分功能将受到限制或不能继续使用。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香及相关服务中包含公司以各种合法方式获取的信息或信息内容链接，同时也包括公司及其关联公司合法运营的其他单项服务。这些服务在七里香平台可能以单独板块形式存在。公司有权不时地增加、减少或改动这些特别板块的设置及服务。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            作为这种持续创新的一部分，您认知并同意七里香可自行决定，无须事先通知您，即停止（永久或暂时）向您或全体用户提供服务。您可在任何时候停止使用服务。
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            您停止使用服务时无需特别通知七里香。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您认知并同意，如果七里香禁用对您的帐户的访问权，则您可能被阻止获得服务、您的帐户资料或包含在您帐户中的任何文件或其他内容。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您认知并同意，尽管七里香可能
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            目前没有设置您可通过服务发送或接收的传输数量或用于提供任何服务的存储空间的上限，但七里香可自行决定在任何时候设置上限。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            为保证七里香软件
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            及相关服务安全、提升用户服务，您使用七里香及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等装置），一旦您在其终端设备中访问七里香平台，即视为您使用七里香软件
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            及相关服务。为充分实现七里香平台的全部功能，您可能需要将其终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您理解并同意部分服务由广告收入支持，可展示广告和推销。这些广告可能是针对存储于服务中的信息、通过服务提出的询问或其他信息的内容提供的。七里香在服务上的广告的方式、模式和范围可不经向您特别通知而变更。作为七里香
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            授予您访问和使用服务的权利的对价，您同意七里香可以在服务上加载该等广告。
          </span>
        </p>
        <p style="margin:10pt 0;font-family:微软雅黑; font-size:12pt">
          <span style="font-family:微软雅黑; font-size:12pt">
            三、平台使用规则
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            用户注册成功后，七里香将给予每个用户一个用户帐号，该用户帐号由用户负责保管；用户应当对以其用户
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            帐号
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            进行的所有活动和事件负法律责任。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            用户须对在七里香的注册信息的真实性、合法性、有效性承担全部责任，用户不得冒充他人；不得利用他人的名义发布任何信息；不得恶意使用注册
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            帐号
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            导致其他用户误认；否则七里香有权立即停止提供服务，收回其
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            帐号
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            并由用户独自承担由此而产生的一切法律责任。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            用户直接或通过各类方式（如
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            RSS 源和站外
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            AdivI
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            引用等）间接使用七里香服务
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请停止使用七里香所提供的全部服务。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香是一个医疗健康相关信息提供、分享、传播及获取的平台，用户通过七里香发表
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            的信息为公开的信息，
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            其他第三方均可以通过七里香获取用户发表的信息，用户对任何信息的发表即认可该信息为公开的信息，并单独对此行为承担法律责任；任何用户不愿被其他第三人获知的信息都不应该在七里香上进行发表。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            用户承诺不得以任何方式利用七里香直接或间接从事违反中国法律以及社会公德的行为，七里香有权对违反上述承诺的内容予以删除。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            用户不得利用七里香服务
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            制作、复制、发布、传播或者转载如下内容：
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            反对宪法所确定的基本原则的；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            损害国家荣誉和利益的；
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            煽动民族仇恨、民族歧视，破坏民族团结的；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            破坏国家宗教政策，宣扬邪教和封建迷信的；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            散布谣言，扰乱社会秩序，破坏社会稳定的；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            侮辱或者诽谤他人，侵害他人合法权益的；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            含有法律、行政法规禁止的其他内容的信息。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香有权对用户使用七里香的情况进行审查和监督，如用户在使用七里香时违反任何上述规定，
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            发布
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            的内容、暂停或终止用户使用七里香的权利）以减轻用户不当行为造成的影响。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            保留从任何服务中筛选、审阅、标明、过滤、修订、拒绝或删除
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            任何或
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            所有内容的权利（但无义务这样做）。就某些服务而言，七里香可提供滤除明确色情内容、政治相关内容的工具。此外，还有可以通过商业渠道获得的服务和软件能够限制访问令您反感的材料
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            我们非常注重未成年人的保护：
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (1) 若您为未成年人，应在监护人监护、指导下仔细阅读本协议，并且使用我们的产品及/或服务已经得到监护人的同意；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt" />
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (2) 监护人应注意指导子女上网应注意的安全问题，提前做好相应的风险防范指
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            导工作。若监护人同意未成年人使用我们的产品及/或服务，必须以监护人的名义申请消费（如涉及），并对未成年人使用我们的产品及/或服务进行正确的引导、监督。未成年人使用我们的产品及/或服务，以及行使和履行本协议项下的权利义务视为已获得了监护人的认可；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt" />
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (3) 我们提醒未成年人在使用我们产品及/或服务时，要善于网上学习，避免沉迷网络，影响日常学习生活。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt" />
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            四、账户安全
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您同意并理解您有责任将您与用于获得服务的任何帐户相关的密码保密。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您同意将独自就在您帐户下的所有活动对七里香负责。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            如果您得知任何对
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            您的帐户的任何未经授权的使用，您同意立即通知七里香。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            关于七里香的数据保护惯例的信息，请查阅七里香的《七里香隐私政策》。该政策解释了七里香如何处理您的个人信息，并在您使用服务时保护您的隐私。您同意按照七里香的隐私政策使用您的数据。
          </span>
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            五、知识产权
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香是一个信息获取、分享及传播的平台，我们尊重和鼓励七里香用户创作发表分享的内容，七里香充分
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            认知到保护知识产权对七里香生存与发展的重要性，承诺将保护知识产权作为七里香运营的基本原则之一。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            用户在七里香上发表的全部原创内容（包括但不仅限于回答、文章和评论），著作权均归用户本人所有。用户可授权第三方以任何方式使用，不需要得到七里香的同意。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香提供
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            的网络服务中包含的标识、版面设计、排版方式、文本、图片、图形等均受著作权、商标权及其它法律保护，未经相关权利人（含七里香及其他原始权利人）同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行，或被用于其他任何商业目的。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            为了促进知识的分享和传播，用户将其在七里香上发表的全部内容，授予七里香免费的、不可撤销的、非独家使用和传播的许可，七里香有权将该内容用于七里香各种
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            形态的产品和服务上，包括但不限于平台以及发表的应用或其他互联网产品。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            第三方若出于非商业目的，将用户在七里香上发表的内容转载在七里香之外的地方，应当在作品的正文开头的显著位置注明原作者姓名（或原作者在七里香上使用的帐号名称），给出原始链接，注明「发表于七里香」，并不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            为用户提供「禁止转载」的选项。除非获得原作者的单独授权，任何第三方不得转载标注了「禁止转载」的内容，否则均视为侵权。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            在七里香上传或发表的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益。如果第三方提出关于著作权的异议，七里香有权根据实际情况删除相关的内容，且有权追究用户的法律责任。给七里香或任何第三方造成损失的，用户应负责全额赔偿。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            如果任何第三方侵犯了七里香用户
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            相关的权利，用户同意授权七里香或其指定的代理人代表七里香自身或用户对该第三方提出警告、投诉、发起行政执法、诉讼、进行上诉，或谈判和解，并且用户同意在七里香认为必要的情况下参与共同维权。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            有权但无义务对用户发布的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及七里香原则
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            对侵权信息进行处理。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您认知并同意，七里香（或七里香的许可方）对服务拥有一切法定权利、所有权和利益，包括存在于服务中的任何知识产权（无论该等权利是否已经登记，也不论该等权利在世界的何等地方存在）。您进一步认知，服务可能包括七里香指定为保密的信息，未经七里香事先书面同意，
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            您不得披露该等信息。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            除非您与七里香另有书面协议，否则本条款中的任何规定均未给予您使用七里香任何商号、商标、服务标记、标识、域名及其他显著品牌特征的权利。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            如果您在与七里香的单独书面协议中被给予一项使用上述品牌特征的明确的权利，则您同意您在使用该等品牌特征时遵守该协议、本条款的任何适用规定以及不时更新的七里香品牌
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            特征使用指南。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            除第
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            11条规定的限制许可外，七里香认知并同意，其不在本条款项下获得您（或您的许可方）对在服务上或通过服务提交、张贴、传输或展示的任何内容的任何权利、所有权或利益，包括该内容中存在的任何知识产权（无论该等权利是否已经登记，亦不论该等权利在世界的何等地方存在）。除非您与七里香另有书面协议，否则您同意您负责保护并强制执行这些权利，七里香没有义务
            代表您这样做。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您同意您不得删除、掩藏或改动服务所附的或包含的任何专有权利声明（包括著作权和商标声明）。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            除非您得到七里香书面明确授权这样做，否则您同意在使用服务时，您将不以可能或故意导致混淆该等商标、名称或标识的所有者或授权用户的方式使用任何公司或组织的商标、服务标识、商号、标识。
          </span>
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            六、侵权举报
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香高度重视自由表达和个人、企业正当权利的平衡。依照法律规定删除违法信息是七里香社区的法定义务，七里香社区亦未与任何中介机构合作开展此项业务。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            侵犯企业或个人合法权益的侵权举报，包括但不限于涉及个人隐私、造谣与诽谤、商业侵权。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            （
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            1）涉及个人隐私：发布内容中直
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            接涉及
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            身份信息，如个人姓名、家庭住址、身份证号码、工作单位、私人电话等详细个人隐私；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            （
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            2）造谣、诽谤：发布内容中指名道姓（包括自然人和企业）的直接谩骂、侮辱、虚构中伤、恶意诽谤等；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            （
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            3）商业侵权：泄露企业商业机密及其他根据保密协议不能公开讨论的内容。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            用户在七里香发表
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            的内容仅表明其个人的立场和观点，并不代表七里香的立场或观点。如果个人或企业发现七里香上存在侵犯自身合法权益的内容，可以先尝试与作者取得联系，通过沟通协商解决问题。
          </span>
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七、终止您与七里香的关系
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            如果您希望终止与使用七里香产品
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            及服务的，您可以通过【七里香隐私政策】中规定的方式注销账号及相关使用信息。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            发生下列情况时七里香可单方终止其与您的服务关系及相关协议约定：
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (A)您违反了本条款的任何规定（或您的行为方式明确显示您不打算或不能遵守本条款规定）；或
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (B)法律要求七里香这样做（例如：向您提供服务不合法或变得不合法）；
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            或
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (C)七里香与之一起向您提供服务的合作伙伴已终止与七里香的关系或停止向您
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            提供服务；或
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (D)七里香转变
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            为不再向您居住的或您从那里使用服务的国家内的用户提供服务；或
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (E)七里香认为，七里香向您
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            提供服务不再具有商业可行性。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            本条款终止时，您和七里香已经享受或承担的（或在本条款有效期间已经产生的）或明确规定为无限期有效的所有法定权利、义务和责任不受该终止的影响
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            、其子公司和关联公司及其许可人，不就以下各项向您
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            作出
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            陈述或保证：
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (A)您对服务的使用将符合您的需求；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (B)您对服务的使用将无中断、及时、安全或没有错误；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (C)由于您使用服务而获得的任何信息将是准确的或可靠的；及
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (D)作为服务的一部分向您提供的任何软件的运行或功能中的缺陷将被纠正。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            通过使用服务而下载或以其他方式获得的任何材料由您自行
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            作出
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            并承担风险，您将独自对由于下载任何该等材料而导致对电脑系统或其他装置的损害或数据的丢失负责。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您从七里香获得的或通过服务或从服务获得的任何建议或信息（无论口头还是书面的）均不创立本条款中未明确规定的任何保证。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香进一步明确否认任何种类的所有保证和条件（无论明示还是默示的），包括但不限于适销性、适合特定目的及
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            不
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            侵权的默示保证和条件。
          </span>
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            八、责任限制
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            在遵守本协议全部规定的前提下，您明示理解并同意，七里香、其子公司和关联公司及其许可人不就以下事项对
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            您承担
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            责任：
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (A)您无论由于何种原因和在任何责任理论项下发生的任何直接、间接、附带、特
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            殊、后果性或惩罚性的损害。这应包括但不限于任何利润损失（无论是直接还是间接发生）、任何商誉或业务声誉损失、任何数据丢失、替代物品或服务的购买费用或其他无形损失；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (B)您可能产生的任何损失或损害，包括但不限于由下列原因导致的损失或损害：
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (C)您对任何广告的完整性、准确性或其存在的信任，或作为您与其广告出现在服务中的任何广告商或赞助人之间的任何关系或交易的结果；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (D)七里香对服务可能做出的变更，或永久或暂时停止提供服务（或服务中的任何功能）；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (E)对通过您使用服务而维持或传输的任何内容及其他通信数据的删除、毁坏或未能将其储存；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (F)您未向七里香提供
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            准确的帐户信息；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            (G)您未对您的密码或帐户资料保持安全及保密；
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            无论七里香是否
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            接到通知或是否应已知晓引起任何该等损失的可能性，上文第1款中七里香对您的责任限制均应适用。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            对关于符合适用国际知识产权法（包括中国的《著作权法》）的指称的著作权侵权通知做出回应以及终止重复侵权者帐户是七里香的政策。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            香
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            不能
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            对用户发表的回答或评论的正确性进行保证。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            用户在七里香发表
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            的内容仅表明其个人的立场和观点，并不代表七里香的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。七里香不承担任何法律及连带责任。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            网络服务的及时性、安全性、准确性也都不作保证。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            对于因不可抗力或七里香不能
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            控制的原因造成的网络服务中断或其它缺陷，七里香不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
          </span>
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            九、其他内容
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            服务可包含对其他平台或内容或资源的超级链接。七里香可能并不控制由七里香以外的公司或个人提供的任何平台或资源。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您认知并同意，七里香不对该等外部平台或资源的可用性负责，亦不对该等网络或资源上的或从该等平台或资源获得的任何广告、产品或其他材料加以认可。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您认知并同意，七里香不对由于
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            您由于
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            那些外部的平台或资源的可用性或您对该等平台或资源上的或从该等平台或资源获得的任何广告、产品或其他材料的完整性、准确性或存在的信赖而发生的任何损失或损害承担责任。
          </span>
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            十、本协议的变更
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            七里香可不时对通用条款或附加条款
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            作出
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            变更，不再另行通知。
          </span>
        </p>
        <p style="margin:10pt 0; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            您理解并同意，如果您在通用条款或附加条款变更日期之后使用服务，则七里香将把您的使用视为接受更新后的通用条款或附加条款。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            十一、一般法律条款
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            本协议及本协议条款项下您与七里香的关系，受中国法律管辖，但排除其冲突法规定。您与七里香均同意
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            接受位于中国境内的【
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            江苏人加信息科技有限公司
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            】所在地【
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            苏
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            州】有管辖权的人民法院的管辖，以解决任何由本条款引起的法律事项。
          </span>
        </p>
        <p style="margin:0pt; orphans:0; text-align:justify; widows:0">
          <span style="font-family:微软雅黑; font-size:12pt">
            本协议内容中的条款，请您着重阅读。您注册/登录网站
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            即视为您完全接受本协议，在
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            您
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            注册
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            /登录网站
          </span>
          <span style="font-family:微软雅黑; font-size:12pt">
            之前请您再次确认已知悉并完全理解本协议的全部内容。
          </span>
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Agree',
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    open() {
      this.dialogVisible = true
    },
  },

}
</script>

<style scoped lang="scss">
.container{
  background:#fff;
  min-height: 600px;
  padding: 20px;
}
@media only screen and (max-width: 1134px) {
.agreementWrap{
  ::v-deep .el-dialog{
    width: 98% !important;
  }
}
}
</style>
